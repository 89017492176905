import React, { useMemo, useRef, useState, useEffect } from "react";
import MultiFileUpload from "./MultiFileUpload";
import { InputForFIle } from "../InputForFIle";
import { cn, convertToCamelCase } from "src/helpers";
import { Label } from "../../ui";
import { Link } from "react-router-dom";
import DocumentViwer from "../DocumentViwer";
import { ClassNameValue } from "tailwind-merge";

type Props = {
  formik: any;
  label: string;
  nameKey: string;
  placeholder?: string;
  lableClass?: string;
  defaultValue?: string;
  value?: any;
  required?: boolean;
  onchange?: (e: any) => void;
  description?: string,
  disabled?: boolean,
  labelStyle?: ClassNameValue,
  error_className?: ClassNameValue,
};

const FormikSingleFileUpload = ({
  formik,
  placeholder,
  nameKey,
  label,
  lableClass,
  defaultValue,
  value,
  required,
  onchange,
  description,
  disabled,
  labelStyle,
  error_className
}: Props) => {
  // console.log({ value });

  const inputRef = useRef<any>(null);

  // console.log(inputRef?.current?.files)

  useEffect(() => {
    if (value && typeof value === "string") {
      inputRef.current.value = ''
    }
    return () => { }
  }, [value])

  // console.log({ nameKey, value });



  return (
    <>
      {/* <Label className={cn("uppercase", `${required ? "required" : ""}`)}>
        {label}
      </Label> */}
      <div className="flex flex-col mt-2 space-y-1">
        {label && <Label className={cn(" text-xs mb-2", labelStyle, `${required ? "required" : ""}`)}>
          {label}
        </Label>
        }
        {description && <span className="text-xs">{description}</span>}
      </div>
      {value && typeof value === "string" && (
        <div className="flex text-xs justify-between border py-2 px-4 rounded w-100 text-wrap text-blue-600 underline underline-offset-2 ">
          <DocumentViwer url={value} title={convertToCamelCase(nameKey)} />
        </div>
      )}
      <div className="">
        <div
          className={cn(" py-2 border rounded flex items-center px-4 w-full text-sm hover:bg-slate-100 cursor-pointer bg-white", { "bg-gray-100 pointer-events-none": disabled })}
          onClick={() => {
            inputRef.current.click();
          }}
        >
          {value && value instanceof File ? (
            <span>{value.name}</span>
          ) : (
            <div className="">
              <span className="font-medium me-2 text-black ">Choose File</span>{" "} <span className="text-gray-700 ">No File Choose</span>
            </div>
          )}
        </div>
        <div className="hidden">
          <InputForFIle
            ref={inputRef}
            className="hidden text-ellipsis bg-white"
            qa-data={nameKey}
            type="file"
            placeholder={placeholder}
            value={undefined}
            accept={"image/jpeg,image/png,application/pdf,video/*"}
            onChange={(e: any) => {
              let file = e.target.files && e.target.files[0];
              console.log({ file });

              onchange && onchange(file);
            }}
          />
        </div>
      </div>
      {formik.touched[nameKey] && formik.errors[nameKey] && (
        <div className={cn("text-xs font-medium text-destructive", error_className)}>
          {formik.errors[nameKey]}
        </div>
      )}
    </>
  );
};

export default FormikSingleFileUpload;
