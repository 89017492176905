import React from 'react'
import WhyUs from './Component/WhyUs'
import { ANNOTATION_ICON } from 'src/helpers'

const WhyUsMain = () => {
  return (
    <div className='pt-16 pb-8 font-sans '>
      <div className='px-16 mb-16'>
        <div className='flex '> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>Why Us</span></div>
        <div className='ml-[25px]'>Expert solutions and reliable support for your solar journey.</div>
      </div>
      <WhyUs />
    </div>
  )
}

export default WhyUsMain