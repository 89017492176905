import { useFormik } from 'formik';
import React, { useState } from 'react'
import { catchAsync, FormField, IS_SUCCESS_RESPONSE, toAbsoluteUrl } from 'src/helpers';
import * as modules from './modules';
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents';
import { Button } from 'react-day-picker';
import RoundedButton from 'src/shadcn/components/custom/RoundedButton';
import { Lock } from 'lucide-react';

const { FORM_SEQUENCE, REFERRAL_FORM_FIELDS, REFERRAL_FORM_VALIDATION_SCHEMA, REFERRAL_FORM_INIT } = modules

type Props = {}

const ReferalForm = (props: Props) => {


    const [isLoading, setisLoading] = useState<boolean>(false)

    const formik: any = useFormik<any>({
        initialValues: REFERRAL_FORM_INIT,
        validationSchema: REFERRAL_FORM_VALIDATION_SCHEMA,
        onSubmit(values, formikHelpers) {

        },
    })

    const handleRequestSubscribe = catchAsync(async () => {
        return // 
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {

        }
    }, () => { })


    return (
        <div className='p-4 border rounded-xl relative w-full'>
            <div className='absolute -top-[50px] left-0 right-0 flex w-full justify-center '>
                <img src={toAbsoluteUrl("/media/png/refer_and_earn1.png")} style={{height:"50px"}} />
            </div>
            <div className='flex items-center space-x-2 mb-4'>
                <div><Lock size={16} className='text-primary' /></div>
                <div className='font-bold text-primary'>Solar Savings and Big Rewards!</div>
            </div>
            <form className='mb-8' onSubmit={formik.handleSubmit}>
                {
                    Object.entries(FORM_SEQUENCE).map(([key, value], indx: number) => {
                        return <div className='flex flex-col mt-4'>
                            <div className='text-xs uppercase text-primary'>{value}</div>
                            <div>{
                                Object.keys(modules[key as keyof object]).map((k: string) => {
                                    let field: FormField | undefined = REFERRAL_FORM_FIELDS.find((d: FormField) => d.dataKey == k)
                                    return field && <div><RenderFormComponents key={`referral-form-${field?.dataKey}`} {...field} formik={formik} value={formik?.values[field?.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(field?.dataKey, e)} /></div>
                                })
                            }</div>
                        </div>
                    })
                }

                <div className=' absolute w-full flex justify-center -bottom-5 '>
                    <RoundedButton>Refer</RoundedButton>
                </div>
            </form>
        </div>

    );
};


export default ReferalForm