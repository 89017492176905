import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/SellExistingProjects";

export function allSellexistingProjectRequestApi(query: string): Promise<AxiosResponse<any>> {
    query = query ? "?" + query.replace("page=1&items_per_page=30","") : ""
    return axiosInstance.get(`${API.ALL_SELL_EXISTING_PROJECT}${query}`).then((d: AxiosResponse) => d.data)
}

export async function getSellexistingProjectDetailByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.SELL_EXISTING_PROJECT_DETAILS_BY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}
