
import { EMAIL_REGEX, ENQUIRY_SERVICE_TYPES, FormField, NUMBER_REGEX } from 'src/helpers';
import * as Yup from 'yup';

export interface IEnquiryForm3 {
  whoYouAre: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  description: string;
  serviceType: string;
}


export const initialEnquiryForm3: IEnquiryForm3 = {
  whoYouAre: "Enquiry User",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  serviceType: "",
  description: "",
};

export const enquiryForm3Schema = Yup.object().shape({
  // whoYouAre: Yup.string().required('Who you are is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().required('Phone number is required').matches(/^[0-9]{10}$/, 'Phone number must be 10 digits'),
  email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  description: Yup.string().required('Description is required'),
  serviceType: Yup.string().required('Service type is required')
});


export const ENQUIRY_FORM_3_FIELDS: FormField[] = [
  // {
  //   label: 'Who Are You',
  //   dataKey: 'whoYouAre',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "text",
  //     placeholder: 'Who Are You',
  //     maxLength: 50,
  //     required: true,
  //     error_className: "text-white"
  //   },
  // },
  {
    label: 'First Name',
    dataKey: 'firstName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'First Name',
      maxLength: 50,
      required: true,
      error_className: "text-white"
    },
  },
  {
    label: 'Last Name',
    dataKey: 'lastName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Last Name',
      maxLength: 50,
      required: true,
      error_className: "text-white"
    },
  },
  {
    label: 'Phone',
    dataKey: 'phone',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Phone Number',
      maxLength: 10,
      regex: NUMBER_REGEX,
      required: true,
      error_className: "text-white"
    },
  },
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: "email",
      placeholder: 'Email',
      maxLength: 150,
      required: true,
      regex: EMAIL_REGEX,
      error_className: "text-white"
    },
  },

  {
    label: 'Service Type',
    dataKey: 'serviceType',
    componentType: 'options',
    componentProps: {
      options: ENQUIRY_SERVICE_TYPES,
      required: true,
      error_className: "text-white"
    },
  },
  {
    label: 'Description',
    dataKey: 'description',
    componentType: 'textarea',
    componentProps: {
      placeholder: 'Description',
      required: true,
      maxLength: 250,
      error_className: "text-white"
    },
  },
];



export const ENQUIRY_FORM_3_FIELDS_cp: FormField[] = [
  // {
  //   label: 'Who Are You',
  //   dataKey: 'whoYouAre',
  //   componentType: 'input',
  //   componentProps: {
  //     type: "text",
  //     placeholder: 'Who Are You',
  //     maxLength: 50,
  //     required: true,
  //     error_className: "text-white"
  //   },
  // },
  {
    label: 'First Name',
    dataKey: 'firstName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'First Name',
      maxLength: 50,
      required: true,
      
    },
  },
  {
    label: 'Last Name',
    dataKey: 'lastName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Last Name',
      maxLength: 50,
      required: true,
      
    },
  },
  {
    label: 'Phone',
    dataKey: 'phone',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Phone Number',
      maxLength: 10,
      regex: NUMBER_REGEX,
      required: true,
      
    },
  },
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: "email",
      placeholder: 'Email',
      maxLength: 150,
      required: true,
      regex: EMAIL_REGEX,
      
    },
  },

  {
    label: 'Service Type',
    dataKey: 'serviceType',
    componentType: 'options',
    componentProps: {
      options: ENQUIRY_SERVICE_TYPES,
      required: true,
      
    },
  },
  {
    label: 'Description',
    dataKey: 'description',
    componentType: 'textarea',
    componentProps: {
      placeholder: 'Description',
      required: true,
      maxLength: 250,
      
    },
  },
];