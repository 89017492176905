import { useFormik } from 'formik'
import React, { useState } from 'react'
import { MANUFACTURER_CONTACT_DETAILS_FORM_FIELDS, MANUFACTURER_CONTACT_DETAILS_FORM_INIT, MANUFACTURER_CONTACT_DETAILS_FORM_Schema, IEpsDetailContactForm } from './modules'
import { catchAsync, FormField } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import { Button } from 'src/shadcn/components/ui'
import { manufacturerCreateEnquiryApiRequest } from 'src/services/requests/Manufacturers'
import { toast } from 'sonner'

type Props = {
    manufacturer_id?:string
}

const DetailsContactForm = ({
    manufacturer_id
}: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const [showContactDiaglog, setshowContactDiaglog] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: MANUFACTURER_CONTACT_DETAILS_FORM_INIT,
        validationSchema: MANUFACTURER_CONTACT_DETAILS_FORM_Schema,
        onSubmit(values, formikHelpers) {
            // handleFileUploads()
            handleCreateEnquiryRequest()
        },
    })

    const handleCreateEnquiryRequest = catchAsync(async () => {
        return manufacturer_id && await manufacturerCreateEnquiryApiRequest({
            manufacturer_id:manufacturer_id,
            ...formik.values
        })
    }, (result:any) => {
        if(result?.status == 200 || result?.status == 201){
            toast.success("Enquiry request submited..")
            setshowContactDiaglog(false)
        }
     }, () => { })


    return (
        <>
            <Button size={'xs'} onClick={() => setshowContactDiaglog(true)} variant={'outlinePrimary'} >Contract us</Button>
            <CustomeDialog isOpen={showContactDiaglog} headerTitle="Contact Us" onSubmit={() => formik.handleSubmit()} disabledSubmitBtn={!manufacturer_id} onCancle={() => setshowContactDiaglog(false)}  >
                <div className='col-span-2 space-y-2 pb-4'>

                    <div className='grid grid-cols-2 gap-1'>
                        {MANUFACTURER_CONTACT_DETAILS_FORM_FIELDS.map((filed: FormField) => {
                            if (filed.dataKey == "description") {
                                return <div className='col-span-2'> <RenderFormComponents formik={formik} {...filed} value={formik.values[filed.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(filed.dataKey, e)} /></div>
                            }
                            return <RenderFormComponents formik={formik} {...filed} value={formik.values[filed.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(filed.dataKey, e)} />
                        })}
                    </div>
                    {/* <div className='flex justify-center'><RoundedButton className='w-[200px] rounded-full ' isLoading={isLoading} >Submit</RoundedButton></div> */}
                </div>
            </CustomeDialog>
        </>
    )
}

export default DetailsContactForm