import React from 'react' 
import ProjectRightsDetails from 'src/modules/SubServices/ProjectRights/ProjectRightsDetails' 
 
type Props = {} 
 
const ProjectRightsPage = (props: Props) => { 
    return ( 
        <div> 
            <ProjectRightsDetails /> 
        </div> 
    ) 
} 
 
export default ProjectRightsPage 
