import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import OrderDetail from 'src/modules/Orders/OrderDetail'
import { fetchOrderDetailsByOrderIDApiRequest } from 'src/services/requests/Order'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const OrderDetailsPage = (props: Props) => {

    const { id } = useParams()

    const [orderDetails, setorderDetails] = useState<any>({})
    const [isLoading, setisLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            handleFetchOrderDeatilsByOrderID()
        }
        else navigate(-1)
        return () => { }
    }, [id])


    const handleFetchOrderDeatilsByOrderID = catchAsync(async () => {
        setisLoading(true)
        return id && await fetchOrderDetailsByOrderIDApiRequest(id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setorderDetails(result.data)
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

  return (
    <div>
        <OrderDetail {...orderDetails} />
        <div className='flex justify-center py-4'><RoundedButton onClick={()=>navigate(-1)} variant={'outline'}>Back</RoundedButton></div>
        <LoadingDialog isOpen={isLoading} message='Fetching Order Details...'  /> 
    </div>
  )
}

export default OrderDetailsPage