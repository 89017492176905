import { useEffect, useState } from 'react'


import { CircleUserRound } from 'lucide-react'
import { useAuth } from 'src/modules/Auth/Core'
import Login from 'src/modules/Auth/Login'
import Registration from 'src/modules/Auth/Registration'
import Modal from 'src/shadcn/components/custom/Modal'
import { Button, Separator } from 'src/shadcn/components/ui'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { cn, ICON_WALLET, toAbsoluteUrl } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { getTempDataRootState, setShowLoginModule } from 'src/redux/reducers/tempDataSlice'
import { RootState } from 'src/redux/store'
import BrandComponent from '../../components/BrandComponent'
import NavigationMenu_R from './NavigationMenu_R'
import UserComponent from '../../Default/Header/UserComponent'
// import Refer_Now from '../../../../../public/media/svg/icons/Refer_Now.svg'
type Props = {}

const HeaderMain_R = (props: Props) => {

  const [openAuthDialog, setopenAuthDialog] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false);

  // Track scroll position and update the state when user scrolls
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { showLoginModal } = useSelector((state: RootState) => getTempDataRootState(state))
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  // const currentUser =  true
  const Refer_Now = toAbsoluteUrl('/media/svg/icons/Refer_Now.svg')
  return (
    <div className='w-full bg-white'>
      <div className={cn('w-full  shadow-md fixed top-0 left-0 bg-transparent z-[80] px-4 font-sans bg-white', { "bg-white": isScrolled })}>
        {/* <BrandComponentReStructure /> */}
        <div className=' max-w-[90vw] h-[80px]  mx-auto flex justify-between items-center '>
          <BrandComponent />
          <div className='flex space-x-4'>
            <NavigationMenu_R />
            {currentUser ?
              <div className='flex space-x-2 items-center px-2'>
                <button className='' onClick={() => navigate("/" + MAIN_ROUTES_CONST.wallet)}>
                  <div className='flex items-center space-x-2'>
                    <img src={ICON_WALLET} style={{ width: "40px", height: "40px" }} />
                    <span>₹ 0</span>
                  </div>
                </button>
                <Separator orientation='vertical' />
                <UserComponent />
              </div> :
              <div className='space-x-1 flex items-center justify-center'>
                <Button size={"sm"} className=' uppercase space-x-2 align-middle' onClick={() => setopenAuthDialog(true)} ><CircleUserRound className='w-5 h-5' /><span>Login</span> </Button>
                <Button size={"sm"} className=' uppercase space-x-2 align-middle bg-theamblack hover:bg-theamblack-foreground ' onClick={() => navigate("/"+MAIN_ROUTES_CONST.refer_now)} ><img src={Refer_Now} alt='Refer_Now' className='w-4 h-4' /><span>Refer Now</span></Button>
              </div>
            }

            <Modal isOpen={openAuthDialog || showLoginModal} className={"max-w-4xl"} onChangeIsOpen={(e: any) => {
              setopenAuthDialog(e)
              dispatch(setShowLoginModule(e))
            }}  >
              <div className='grid grid-cols-2 gap-4'>
                <div><Login isOpenDialog={setopenAuthDialog} /></div>
                <div><Registration isOpenDialog={setopenAuthDialog} /></div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderMain_R