import React from 'react'
import EnquiryForm3 from 'src/modules/EnquiryForms/EnquiryForm3'
import EnquiryForm2 from 'src/modules/EnquiryForms/EnquiryForm3'

type Props = {}

const ServicesEnquiryRequestPage = (props: Props) => {
    return (
        <div className='p-4 space-y-4'>
            <div className='text-primary uppercase text-xl font-semibold'>Service Enquiry Request</div>
            <EnquiryForm3 />
        </div>
    )
}

export default ServicesEnquiryRequestPage