import { Phone } from 'lucide-react'
import React from 'react'
// import Rating from 'react-rating'
import { convertToCamelCase, ICON_OCT_VERIFIED, IMG_EPC_COMPANY_LOGO, IMG_EPC_COMPANY_PLACEHOLDER_LOGO } from 'src/helpers'
import { Button, Separator } from 'src/shadcn/components/ui'
import { FaPhoneAlt } from "react-icons/fa";
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Rating } from '@smastrom/react-rating'


export interface EpcItemData {
  contractorName: string;
  noOfReview: number;
  location: string;
  rating: number;
  companyLogo: string;
  services: any[];
  capacityInstalled: number;
  noOfYearsExperiance: number;
  epc_contractor_id?:string
}


type Props = {
  data: EpcItemData
}

const EpcItem = (data: EpcItemData) => {

  const navigation = useNavigate()

  return (
    <div className='flex flex-col p-2 border  rounded-xl border-black w-100 '>
      {/* header */}
      <div className='flex flex-col py-2'>
        <div className='flex justify-between items-baseline' >
          <div className='font-bold'>{data?.contractorName || ""}</div>
          <div className='text-xs'>{data?.noOfReview || ""} reviews</div>
        </div>
        <div className='flex justify-between' >
          <div className='text-xs'><span>Location :</span><span>{convertToCamelCase(data?.location) || ""}</span></div>
          <span className='text-xs'>
            <Rating value={Number(data?.noOfReview) || 0} style={{height:15,width:"80px"}} readOnly /> 
          </span>
        </div>
      </div>
      <Separator className='border-gray-400' />
      <div className='grid grid-cols-4 gap-1' >
        <div className='flex justify-center items-center'>
          {/* {data?.companyLogo ? <img src={"data?.companyLogo"} style={{ width: "100%", }} /> : <img src={IMG_EPC_COMPANY_PLACEHOLDER_LOGO} style={{ width: "100%" }} />} */}
          <img src={data?.companyLogo || IMG_EPC_COMPANY_PLACEHOLDER_LOGO} className=' w-100'  />
        </div>
        <div className='col-span-3 p-2 space-y-4' >
          <div className='flex'>
            <div className='flex-1 py-4 text-xs flex flex-col items-center'>
              <div className='font-bold'>{data?.services?.length || 0}+</div>
              <div>Projects</div>
            </div>
            <div className=' flex-1 py-4  text-xs flex flex-col items-center px-1 border border-t-0 border-b-0 '>
              <div className='font-bold'>{data?.capacityInstalled} kW</div>
              <div className='text-center'>Capacity Installed</div>
            </div>
            <div className='flex-1 py-4  text-xs flex flex-col items-center'>
              <div className='font-bold'>Since {data?.noOfYearsExperiance ? dayjs().subtract(data?.noOfYearsExperiance as any, "year").format("YYYY") as any : 0}</div>
              <div className='text-center'>Years Experience</div>
            </div>
          </div>
          <div className='flex justify-end space-x-2 my-4'>
            <span className='bg-gray-200 w-[110px] p-1 rounded-full flex items-center space-x-2'>
              <img src={ICON_OCT_VERIFIED} style={{ width: "28px", height: "24px" }} />
              <span className='text-sm'>Verified</span>
            </span>
            <Button onClick={() => navigation("/" + MAIN_ROUTES_CONST.epcContractorDetails+"/"+ data?.epc_contractor_id)} className='bg-gray-500 hover:bg-primary flex space-x-2 rounded-full' size={"sm"} >
              <FaPhoneAlt size={16} />
              <span>Enquiry Now</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EpcItem