import { FormField, NUMBER_REGEX, QUERY_TYPE_OPTIONS } from "src/helpers";
import * as Yup from 'yup';


export interface IContactUdForm {
    name: string,
    phoneNumber: string,
    email: string,
    category: string,
    queryType: string,
    description: string,
    attachments: File | undefined
}

export const CONTACT_US_FORM_FIELDS: FormField[] = [
    {
        label: 'Name',
        dataKey: 'name',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter your name',
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phoneNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex:NUMBER_REGEX,
            placeholder: 'Enter your phone number',
            maxLength:10
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: 'email',
            placeholder: 'Enter your email',
        },
    },
   
    {
        label: 'Query Type',
        dataKey: 'queryType',
        componentType: 'options',
        componentProps: {
            options:QUERY_TYPE_OPTIONS
        },
    },
    {
        label: 'Attachments',
        dataKey: 'attachments',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Select files to attach',
        },
    },
    {
        label: 'Description',
        dataKey: 'description',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Enter your query description',
        },
    },
];


export const CONTACT_US_INIT: IContactUdForm = {
    name: '',
    phoneNumber: '',
    email: '',
    category: '',
    queryType: '',
    description: '',
    attachments: undefined,
  };


export const contactUsFormSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    // category: Yup.string().required('Category is required'),
    queryType: Yup.string().required('Query type is required'),
    description: Yup.string().required('Description is required'),
    attachments: Yup.mixed().optional()
    .test('fileType', 'Only jpg, png files are allowed', (value: any) => {
      if (!value) return true; // allow empty values
      if (value instanceof File) {
        const fileType = value.type;
        return (
          fileType === 'image/jpeg' ||
          fileType === 'image/png' ||
          fileType === 'application/pdf' ||
          fileType === 'text/csv'
        );
      }
      else if (typeof value == "string") {
        return true
      }
    })
    .test('fileSize', 'File size must be less than 5MB', (value: any) => {
      if (!value) return true; // allow empty values
      if (value instanceof File) {
        const fileSize = value.size;
        return fileSize <= 2 * 1024 * 1024; // 5MB
      }
      else if (typeof value == "string") {
        return true
      }
    }),
});