import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { toast } from 'src/shadcn/components/ui/use-toast';
import { RootState } from '../store';
import { fetchCartItemsByUserIDApiRequest, updateCartItemsQuantityApiRequest } from 'src/services/requests/carts';
import { getAuth } from 'src/modules/Auth/Core';

// Define the initial state
interface ICartReducerState {
    cartItems: [];
    loading: boolean;
    error: string | null;
}

const initialState: ICartReducerState = {
    cartItems: [],
    loading: false,
    error: null,
};

// Define the thunk to fetch cart items
export const fetchCartItemsAction: any = createAsyncThunk(
    'cart/fetchCartItems',
    async () => {
        try {
            const auth = getAuth()
            if (auth?.user_id) {
                const response: any =  await fetchCartItemsByUserIDApiRequest(auth?.user_id)
                return response
            }
        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                if (error) {
                    const status = error.response?.status;
                    toast({
                        variant: "destructive",
                        title: "Uh oh! Something went wrong.",
                        description: error.response?.data?.message,
                    })
                }
            } else {
                toast({
                    variant: "destructive",
                    title: "Unexpected error",
                    description: error?.message,
                })
            }
        }
    }
);



// Define the reducer
const cartReducer = createSlice({
    name: 'cartReducer',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(fetchCartItemsAction.pending, (state: ICartReducerState) => {
            state.loading = true;
        });
        builder.addCase(fetchCartItemsAction.fulfilled, (state: ICartReducerState, action: any) => {
            state.loading = false;
            state.cartItems = action.payload?.data || null
        });
        builder.addCase(fetchCartItemsAction.rejected, (state: ICartReducerState, action: any) => {
            state.loading = false;
            state.error = action.error.message;
        });
       
    },
});

// Export the reducer and actions
export const getCartReducerRootState = (state: RootState) => state.cart;
export default cartReducer.reducer;