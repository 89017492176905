import { useState } from 'react'
import FilterFormComponent from 'src/modules/FilterModule/FilterFormComponent'
import { Button } from 'src/shadcn/components/ui'
import { FILTER_STATE, FILTERS_DATA, IFILTER_STATE } from '../modules'
import { useQueryRequest } from './core/QueryRequestProvider'
import { useQueryResponse } from './core/QueryResponseProvider'
import { stringifyObjectToRequestQuery } from 'src/helpers'

type Props = {
    onChange: (e: any) => void
}

const FilterMain = ({ onChange }: Props) => {

    const { state, updateState } = useQueryRequest()
    const { refetch } = useQueryResponse()

    return (
        <div>
            <FilterFormComponent filterData={FILTERS_DATA} selectedFilters={FILTER_STATE} onChange={(e: any) => {
                if (JSON.stringify(e) != JSON.stringify(state.filter)) {
                    updateState({
                        filter: e
                    })
                }
                else refetch()
            }} />
        </div>
    )
}


export default FilterMain