import React from 'react'
import { Skeleton } from 'src/shadcn/components/ui'

type Props = {}

const OrderListLoader = (props: Props) => {
    return (
        <div className='p-4 border border-gray-400 rounded-xl grid grid-cols-3 gap-4  '>
            <div className='col-span-2 space-y-2 border border-l-0 border-b-0 border-t-0 '>
                <div className='flex items-center space-x-2'>
                    <Skeleton className='w-[40%] h-5' />
                </div>

                <div className='space-y-2'>
                    <div className='text-sm text-gray-400'>Delivery Address</div>
                    <div className='text-xs space-y-2'>
                        <Skeleton className='w-[80%] h-4' />
                        <Skeleton className='w-[50%] h-4' />
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-2'>
                <div className='grid grid-rows-2 gap-2'>
                    <div className=''>
                        <div className='text-sm text-gray-400'>Total Products</div>
                        <Skeleton className='w-[50%] h-4' />
                    </div>
                    <div className=''>
                        <div className='text-sm text-gray-400'>Total Products</div>
                        <Skeleton className='w-[50%] h-4' />
                    </div>
                </div>
                <div className='space-y-2 '>
                    <div className='flex justify-end space-x-2 text-sm  text-gray-400 w-full'>
                        <Skeleton className='w-[50%] h-2' />
                    </div>
                    <Skeleton className='w-full h-8' />
                    <Skeleton className='w-full h-8' />
                </div>
            </div>
        </div>
    )
}

export default OrderListLoader