import React, { useState, createContext, FC, useContext, ReactNode } from 'react';

import { ContextProps, initialContext } from './modules';
import { optionTypes } from 'src/helpers';

const MainContext = createContext<ContextProps>(initialContext)

const MainContextProvider: FC<{
    children: ReactNode
}> = ({ children }) => {

    const [selectedValues, setSelectedValues] = useState<optionTypes | undefined>(undefined)

    

    const toggleValue = (option: optionTypes) => {
        setSelectedValues(option)
    }

    return (
        <MainContext.Provider value={{ selectedValues, toggleValue }}>
            {children}
        </MainContext.Provider>
    )
}

const useMainContext = () => useContext(MainContext)
export { MainContextProvider, useMainContext }