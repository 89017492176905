import { ANNOTATION_ICON, HAND_SHAK_IMG, IMG_COMMERCIAL_SERVICES_REFERENCES_BG, SECTION_BG, toAbsoluteUrl, WELCOME_SERVICE_BG } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

import { useNavigate } from 'react-router-dom'
import HtmlCodeRender from 'src/shadcn/components/custom/HtmlCodeRender'
import CommercialFaqSection from './CommercialFaq'
import { COMMERCIAL_SERVICES, COMMERCIAL_SERVICES_DATA_NEW, REFERENCES_ANALYSIS, REFERENCES_PRODUCTS, REFERENCES_TOP_PRODUCTS } from './Component/modules'
import ServiceTile from './Component/ServiceTile'
import { Button } from 'src/shadcn/components/ui'
import { Tags } from 'lucide-react'
import EnquiryFormDialog from 'src/modules/EnquiryForms/EnquiryFormDialog'
import { useState } from 'react'

type Props = {}

const BannerImg = toAbsoluteUrl("media/svg/image/ServiceSolarImg.svg")

const CommercialServices = (props: Props) => {

  const navigate = useNavigate()
  const [openEnquiryDialog, setOpenEnquiryDialog] = useState<boolean>(false)

  return (
    <div className='mb-[100px] '>
      <div
        className='py-8'
        style={{
          background: `url(${SECTION_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}>
        <div className='space-y-2 max-w-[80vw] mx-auto  '>
          <div className=' text-center font-bold text-3xl my-4'>
            Get the RE project of your Choice on a Click !!!
          </div>
          <div className='grid grid-cols-2 space-x-2 '>
            <div className=' h-full flex items-center'>
              <ul className=" text-md list-disc ml-6 p-8 max-w-[600px]">
                <li className="mb-6">India’s first Project Marketplace.</li>
                <li className="mb-6">We take care of end-to-end sale process.</li>
                <li className="mb-6">There is also no commission for the buyer.</li>
                <li className="mb-6">Only the seller of the Solar projects pays a mutually agreed fee to the platform on success.</li>
                <li className="mb-6">Projects above 100 kWp can be sold or buy on the marketplace.</li>
                <li className="mb-6">Free land above 500 Sq meter, rooftop above 200 Sq meter can be listed.</li>
              </ul>
            </div>
            <div className='flex justify-center h-full '>
              <img src={HAND_SHAK_IMG} className='mt-8 rounded-xl' style={{ height: "500px" }} />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{
          background: "linear-gradient(to bottom, rgb(238, 237, 236), 50%, white 50%);"
        }}>
          <div className='space-y-8 pt-8 pb-8'>
            <div className='px-16'>
              <div className='flex items-center space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>Our Top 5 Projects</span></div>
              <div className='px-[30px]'>Highlighting the top projects that drive real change.</div>
            </div>
            <div className='px-16 mx-auto flex flex-col items-center space-y-8'>
              <div className='grid grid-cols-6 gap-4  mx-auto' >
                {
                  REFERENCES_TOP_PRODUCTS.map(({ img, name, data, units }: any) => {
                    return <div className='flex flex-col items-center space-y-2 bg-white rounded-xl overflow-hidden border border-primary '>
                      <img src={img} />
                      <div className='flex flex-col items-center font-bold py-2 '>
                        <span>{data}{units}</span>
                        <span>{name}</span>
                      </div>
                    </div>
                  })
                }
                <div className='cursor-pointer h-full w-full rounded-xl flex justify-center items-center p-0 hover:border-2 hover:border-primary hover:p-2 transition-all duration-300 ease-in-out'>
                  <div className='bg-theamblack rounded-xl  h-full w-full flex justify-center items-center space-y-4 '>
                    <div className='flex flex-col items-center p-2 space-y-2'>
                      <Tags className='text-theamblack-foreground' size={64} />
                      <div className='text-white text-center text-xs'>
                        Post You Solar Project here
                      </div>
                      <div className='text-primary'> Click to know more</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-[#EEEDEC]' style={{ margin: 0 }} >
        <div style={{
          background: `url(${WELCOME_SERVICE_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          margin: "0 "
        }}>
          <div className='space-y-8 pt-16 pb-8'>
            <div className='px-16'>
              <div className='flex items-center space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>Commercial & Industrial</span></div>
              <div className='px-[30px]'>Powering businesses with reliable and efficient solar solutions.</div>
            </div>
            <div className='px-16 mx-auto w-[80vw] flex flex-wrap justify-center'>
              {COMMERCIAL_SERVICES_DATA_NEW.map((d: any) => {
                return <ServiceTile {...d} />
              })}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='space-y-8 pt-8 pb-8'>
          <div className='px-16'>
            <div className='flex items-center space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>How it works?</span></div>
            <div className='px-[30px]'>A collaborative approach for the best outcomes.</div>
          </div>
          <div className='px-[95px] mx-auto  flex flex-wrap justify-center space-y-8'>
            <div>If you’d like to sell your solar project or offer land on lease, click on <span className='font-bold'>"Contact Us"</span> and <span className='font-bold'>Fill out the contact form</span>. After confirming your email address, we’ll send you an email with a dedicated contact person and a list of required documents. We look forward to assisting you!</div>
            <Button onClick={()=>setOpenEnquiryDialog(true)} className='w-[150px]'>Send Request</Button>
          </div>
        </div>
      </div>
      <div>
        <div style={{
          background: `url(${IMG_COMMERCIAL_SERVICES_REFERENCES_BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"

        }}>
          <div className='space-y-8 pt-8 pb-8'>
            <div className='px-16'>
              <div className='flex items-center space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>References</span></div>
              <div className='px-[30px]'>A showcase of finished projects with outcomes.</div>
            </div>
            <div className='px-16 mx-auto flex flex-col items-center space-y-8'>
              <div className='grid grid-cols-3 gap-4 max-w-[60vw] mx-auto' >
                {
                  REFERENCES_ANALYSIS.map(({ count, name, annotation }: any) => {
                    return <div className='flex flex-col items-center space-y-1 p-4  '>
                      <div className='font-bold text-3xl text-primary'>{count}{annotation}</div>
                      <div>{name}</div>
                    </div>
                  })
                }
              </div>
              <div className='grid grid-cols-4 gap-4  mx-auto' >
                {
                  REFERENCES_PRODUCTS.map(({ img, name, data, units }: any) => {
                    return <div className='flex flex-col items-center space-y-2 bg-white rounded-xl overflow-hidden '>
                      <img src={img} />
                      <div className='flex flex-col items-center font-bold py-2 '>
                        <span>{data}{units}</span>
                        <span>{name}</span>
                      </div>
                    </div>
                  })
                }
              </div>
              {/* <Button className='w-[150px]'>Learn More</Button> */}
            </div>
          </div>
        </div>
      </div>
      <div className=''  >
        <div className='space-y-8 py-8'>
          <div className='px-16'>
            <div className='flex items-center  space-x-2'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>Need Help? Check Our FAQs</span></div>
            <div className='px-[30px]'>Frequently Asked Questions</div>
          </div>
          <div className='px-16 '>
            <CommercialFaqSection />
          </div>
        </div>
      </div>
      <EnquiryFormDialog isOpenState={setOpenEnquiryDialog} openState={openEnquiryDialog} />
      {/* <SolarServicesEnquiryNow /> */}
    </div>
  )
}

export default CommercialServices