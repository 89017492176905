import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IMG_GREEN_ENERGY_BANNER } from 'src/helpers'
import { getDetailsManufacturerProfileByIDApiRequest } from 'src/services/requests/Manufacturers'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import DetailsContactForm from './DetailsContactForm'
import ManufacturerServicesNew from './ManufacturerServicesNew'
import { INIT_MANUFACTURER_PROFILE } from './modules'
import ManufacturerServices from './ManufacturerServices'
import { Button } from 'src/shadcn/components/ui'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'

type Props = {}

const ManufacturerDetails = (props: Props) => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [profileDetail, setprofileDetail] = useState(INIT_MANUFACTURER_PROFILE)

    useEffect(() => {
        if (id) {
            handleFetchProfileDetails()
        }
        return () => { }
    }, [id])

    const handleFetchProfileDetails = catchAsync(async () => {
        setisLoading(true)
        return id && await getDetailsManufacturerProfileByIDApiRequest(id)
    }, (result: any) => {
        if (result.status == 200) {
            setisLoading(false)
            setprofileDetail(result?.data)
        }
    }, () => { setisLoading(false) })

    return (
        <div>
            <div className='w-full ' >
                <div className=' w-full h-[300px] overflow-hidden ' style={{ backgroundImage: "url(" + IMG_GREEN_ENERGY_BANNER + ")", backgroundSize: "cover" }} >
                    <div className='grid grid-cols-4 gap-1 max-w-[85vw] h-full   mx-auto'>
                        <div className='h-100 flex justify-center items-center bg-gray-50'>
                            <img src={profileDetail?.companyLogo} className='bg-gray-50' style={{ width: "100%" }} />
                        </div>
                        <div className='col-span-3 text-white flex justify-center pt-[48px] '>
                            <div className='w-[80%] mx-auto space-y-4'>
                                <div className='flex justify-center '>
                                    <div>
                                        <div className='text-xl uppercase font-bold'>About Novus Green</div>
                                        <div className='h-0.5 bg-primary w-[100px]'></div>
                                    </div>
                                </div>
                                <div className='text-sm'>
                                    {profileDetail?.companyAbouteUs}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='max-w-[85vw] mx-auto grid grid-cols-4 gap-1'>
                    <div className='h-[70px] bg-yellow-500 flex justify-center items-center rounded-bl-3xl'>
                        <span className='font-bold text-lg uppercase text-white'>{profileDetail?.authorization_partner}</span>
                    </div>
                    <div className='h-[70px] bg-orange-50 flex justify-center items-center '>
                        <div className=''>
                            <div className='font-bold text-center' >{profileDetail?.products && Array.isArray(profileDetail?.products) && profileDetail?.products?.length}+</div>
                            <div className='text-center text-xs' >Products Manufactured</div>
                        </div>
                    </div>
                    <div className='h-[70px] bg-orange-50 flex justify-center items-center '>
                        <div className=''>
                            <div className='font-bold text-center' >{profileDetail?.product_efficiency} %</div>
                            <div className='text-center text-xs' >Panel Efficiency</div>
                        </div>
                    </div>
                    <div className='h-[70px] bg-orange-50 flex justify-center items-center '>
                        <div className=''>
                            <div className='font-bold text-center' >{profileDetail?.noOfYearsExperiance ? dayjs().subtract(profileDetail?.noOfYearsExperiance as any, "year").format("YYYY") as any : 0}</div>
                            <div className='text-center text-xs' >Years Experiance</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-[85vw] mx-auto grid grid-cols-1 gap-4 py-4'>
                <div className='col-span-1 space-y-8 py-4 '>
                    <div className='flex justify-between'>
                        <div>
                            <div className='text-sm font-bold uppercase'>Products</div>
                            <div className='h-0.5 bg-primary w-[100px]'></div>
                        </div>
                        <DetailsContactForm manufacturer_id={id} />
                    </div>
                    <div className=''>
                        <ManufacturerServicesNew data={profileDetail?.products} />
                    </div>
                </div>
                {/* <div className='pace-y-8 p-4 border border-r-0 border-t-0 border-b-0'>
                    <div>
                        <div className='text-sm font-bold uppercase'>GET CONTACT DETAILS</div>
                        <div className='h-0.5 bg-primary w-[100px]'></div>
                    </div>
                    <DetailsContactForm />
                </div> */}
            </div>



            <LoadingDialog isOpen={isLoading} />
        </div>
    )
}

export default ManufacturerDetails