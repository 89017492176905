import React, { useEffect, useState } from 'react'
import { OFFTACKER_ANALYSIS_DATA, ORDER_ANALYSIS_DATA } from './modules'
import AnalysisTiles from 'src/modules/Dashboard/AnalysisTiles'
import { ChartDisplay } from 'src/shadcn/components/custom/ChartDispaly'
import InvestmentAnalysis from 'src/modules/Dashboard/InvestmentAnalysis'
import OffTakerAnalysis from 'src/modules/Dashboard/Offtacker/OffTakerAnalysis'
import { DataTable } from 'src/modules/Dashboard/OffTackerHistory/Table/components/data-table'
import { columns } from 'src/modules/Dashboard/OffTackerHistory/Table/components/columns'
import { catchAsync, IS_SUCCESS_RESPONSE, stringifyObjectToRequestQuery, USER_ROLES } from 'src/helpers'
import { fetchOrdersByUserIDApiRequest, fetchOrderSummaryByUserIDApiRequest } from 'src/services/requests/Order'
import { useAuth } from 'src/modules/Auth/Core'

type Props = {}



const OffTakerDashboard = (props: Props) => {

    const [isFetchingOrderSummary, setisFetchingOrderSummary] = useState<boolean>(false)
    const [orderSummary, setorderSummary] = useState<any>({})
    const { currentUser } = useAuth()

    useEffect(() => {
        handleFetchOrderSummary()
        return () => { }
    }, [])



    const handleFetchOrderSummary = catchAsync(async () => {
        setisFetchingOrderSummary(true)
        return currentUser?.id && currentUser.role == USER_ROLES.OFF_TAKER && await fetchOrderSummaryByUserIDApiRequest(stringifyObjectToRequestQuery({ user_id: currentUser?.id }))
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            // console.log({result[0]});
            
            setorderSummary(result.data[0])
        }
        setisFetchingOrderSummary(false)
    }, () => { setisFetchingOrderSummary(false) })

    return (
        <div className='space-y-2 py-4'>
            <div className='font-semibold text-primary uppercase'>Dashboard</div>
            <div>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                    {
                        OFFTACKER_ANALYSIS_DATA.map((d: any) => {
                            return <AnalysisTiles {...d} />
                        })
                    }
                    {
                        ORDER_ANALYSIS_DATA.map((d: any) => {

                            const getValue = (dataKey: string) => {
                                if(dataKey.includes(".")){
                                    const [key, subKey] = dataKey.split('.');
                                    return orderSummary[key] ? orderSummary[key][subKey] : 0;
                                }
                                else return orderSummary[dataKey as keyof object] || 0;
                              };

                            return <AnalysisTiles {...d} value={getValue(d?.dataKey) || 0} isLoading={isFetchingOrderSummary} />
                        })
                    }
                </div>
            </div>
            <div className='font-semibold text-primary uppercase'>Electricity consumption and generation</div>
            <div className='grid grid-cols-1'>
                <ChartDisplay >
                    <OffTakerAnalysis />
                </ChartDisplay>
            </div>
            <div className='font-semibold text-primary uppercase'>EMI HISTORY</div>
            <DataTable data={[]} columns={columns} />
        </div>
    )
}

export default OffTakerDashboard