import { ADDRESS_REGEX, AVAILABLE_SPACE_UNITS_OPTIONS, CONNECTION_TYPE_OPTIONS, EMAIL_REGEX, FormField, NAME_REGEX, NUMBER_REGEX, PROPERTY_TYPE_OPTIONS, ROOF_MATERIAL_OPTIONS, ROOF_ORIENTATION, ROOF_TYPE_OPTIONS, SHADING_OPTIONS, SOLAR_SYSTEM_TYPE_OPTIONS } from "src/helpers";

export interface IRooftop {
    firstName: string;
    email: string;
    phone: string;
    alternatePhone?: string; // Optional field
    address: string;
    city: string;
    state: string;
    pinCode: string;
    propertyType: string;
    roofType: string;
    roofMaterial: string;
    availableRoofArea: string; // in sq.ft.
    roofOrientation: string;
    shadingOnRoof: string;
    connectionType: string;
    averageLoad: string; // in kW
    solarSystemType: string;
    imgUrl:string
  }
  

  // Step 2: Initialize a constant
export const rooftop_init: IRooftop = {
    firstName: "",
    email: "",
    phone: "",
    alternatePhone: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
    propertyType: "",
    roofType: "",
    roofMaterial: "",
    availableRoofArea: "",
    roofOrientation: "",
    shadingOnRoof: "",
    connectionType: "",
    averageLoad: "",
    solarSystemType: "",
    imgUrl:""
  };

  export const ROOFTOP_FORM_FIELDS: FormField[] = [
    {
      label: 'Full Name',
      dataKey: 'firstName',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'Full Name',
        maxLength: 150,
        required: true,
      },
    },
    {
      label: 'Email Address',
      dataKey: 'email',
      componentType: 'input',
      componentProps: {
        type: "email",
        placeholder: 'Email Address',
        maxLength: 150,
        required: true,
        regex: EMAIL_REGEX, // Assuming EMAIL_REGEX is defined elsewhere
      },
    },
    {
      label: 'Phone Number',
      dataKey: 'phone',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'Phone Number',
        maxLength: 15,
        required: true,
        regex: NUMBER_REGEX, // Assuming NUMBER_REGEX is defined elsewhere
      },
    },
    {
      label: 'Alternate Phone Number',
      dataKey: 'alternatePhone',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'Alternate Phone Number',
        maxLength: 15,
        required: false,
        regex: NUMBER_REGEX, // Assuming NUMBER_REGEX is defined elsewhere
      },
    },
    {
      label: 'Address',
      dataKey: 'address',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'Address',
        maxLength: 250,
        required: true,
        regex: ADDRESS_REGEX, // Assuming ADDRESS_REGEX is defined elsewhere
      },
    },
    {
      label: 'City',
      dataKey: 'city',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'City',
        maxLength: 150,
        required: true,
        regex: NAME_REGEX, // Assuming NAME_REGEX is defined elsewhere
      },
    },
    {
      label: 'State',
      dataKey: 'state',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'State',
        maxLength: 150,
        required: true,
        regex: NAME_REGEX, // Assuming NAME_REGEX is defined elsewhere
      },
    },
    {
      label: 'Pin Code',
      dataKey: 'pinCode',
      componentType: 'input',
      componentProps: {
        type: "text",
        placeholder: 'Pin Code',
        maxLength: 6,
        required: true,
        regex: NUMBER_REGEX, // Assuming NUMBER_REGEX is defined elsewhere
      },
    },
    {
      label: 'Property Type',
      dataKey: 'propertyType',
      componentType: 'options',
      componentProps: {
        options: [],
        required: true,
      },
    },
    {
      label: 'Roof Type',
      dataKey: 'roofType',
      componentType: 'options',
      componentProps: {
        options: [],
        required: true,
      },
    },
    {
      label: 'Roof Material',
      dataKey: 'roofMaterial',
      componentType: 'options',
      componentProps: {
        options: [],
        required: true,
      },
    },
    {
      label: 'Available Roof Area (sq.ft.)',
      dataKey: 'availableRoofArea',
      componentType: 'input',
      componentProps: {
        type: "number",
        placeholder: 'Available Roof Area',
        numberFormatted: true,
        options: [],
        required: true,
      },
    },
    {
      label: 'Roof Orientation',
      dataKey: 'roofOrientation',
      componentType: 'options',
      componentProps: {
        options: [],
        required: true,
      },
    },
    {
      label: 'Shading on Roof',
      dataKey: 'shadingOnRoof',
      componentType: 'options',
      componentProps: {
        options: [],
        required: true,
      },
    },
    {
      label: 'Connection Type',
      dataKey: 'connectionType',
      componentType: 'options',
      componentProps: {
        options: [],
        required: true,
      },
    },
    {
      label: 'Average Load (kW)',
      dataKey: 'averageLoad',
      componentType: 'input',
      componentProps: {
        type: "number",
        placeholder: 'Average Load (kW)',
        required: true,
      },
    },
    {
      label: 'Solar System Type',
      dataKey: 'solarSystemType',
      componentType: 'options',
      componentProps: {
        options: [],
        required: true,
      },
    },
  ];


  export const FILTERS_DATA = [
    {
        name: 'Property Type',
        dataKey: "propertyType",
        type: 'dropdown',
        options: PROPERTY_TYPE_OPTIONS
    },
    {
        name: 'Roof Type',
        dataKey: "roofType",
        type: 'dropdown',
        options: ROOF_TYPE_OPTIONS,
    },
    {
        name: 'Roof Material',
        dataKey: "roofMaterial",
        type: 'dropdown',
        options: ROOF_MATERIAL_OPTIONS,
    },
    {
        name: 'Available Roof Area',
        dataKey: "availableRoofArea",
        type: 'number', // Assuming this is a string for input
        numberFormatted:true,
        options:AVAILABLE_SPACE_UNITS_OPTIONS
    },
    {
        name: 'Roof Orientation',
        dataKey: "roofOrientation",
        type: 'dropdown',
        options: ROOF_ORIENTATION,
    },
    {
        name: 'Shading on Roof',
        dataKey: "shadingOnRoof",
        type: 'dropdown',
        options: SHADING_OPTIONS,
    },
    {
        name: 'Connection Type',
        dataKey: "connectionType",
        type: 'dropdown',
        options: CONNECTION_TYPE_OPTIONS,
    },
    {
        name: 'Average Load',
        dataKey: "averageLoad",
        type: 'number',
    },
    {
        name: 'Solar System Type',
        dataKey: "solarSystemType",
        type: 'dropdown',
        options: SOLAR_SYSTEM_TYPE_OPTIONS,
    },
];

// Step 2: Create TypeScript Interface
export interface IFILTER_STATE {
    propertyType: string;
    roofType: string;
    roofMaterial: string;
    availableRoofArea: string; // in sq.ft.
    roofOrientation: string;
    shadingOnRoof: string;
    connectionType: string;
    averageLoad: string; // in kW
    solarSystemType: string;
}

// Step 3: Initialize an Empty Object
export const FILTER_STATE: IFILTER_STATE = {
    propertyType: '',
    roofType: '',
    roofMaterial: '',
    availableRoofArea: '',
    roofOrientation: '',
    shadingOnRoof: '',
    connectionType: '',
    averageLoad: '',
    solarSystemType: '',
};