import { useFormik } from 'formik';
import React, { useState } from 'react'
import { INIT_P2P_ENERGY_ENQUIRY_FORM, P2P_ENERGY_ENQUIRY_FORM_FIELDS, P2P_ENERGY_ENQUIRY_FORM_VALIDATION_SCHEMA } from './modules';
import { catchAsync, FormField, IS_SUCCESS_RESPONSE } from 'src/helpers';
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents';
import { Button } from 'src/shadcn/components/ui';
import { MoveUpRight } from 'lucide-react';

type Props = {}

const P2pEnquiryForm = (props: Props) => {


    const [isLoading, setisLoading] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: INIT_P2P_ENERGY_ENQUIRY_FORM,
        validationSchema: P2P_ENERGY_ENQUIRY_FORM_VALIDATION_SCHEMA,
        onSubmit(values, formikHelpers) {

        },
    })

    //     const handleRequestSubscribe = catchAsync(async () => {
    //         return await 
    //   }, (result: any) => {
    //         if (IS_SUCCESS_RESPONSE(result)) {

    //         }
    //     }, () => { })


    return (
        <form onSubmit={formik.handleSubmit}>
            <div className='flex flex-col space-y-4'>
                {
                    P2P_ENERGY_ENQUIRY_FORM_FIELDS.map((field: FormField) => {
                        return <div><RenderFormComponents key={`new-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} /></div>
                    })
                }
                <div className='pt-4 flex justify-center'>
                    <Button >
                        <span>Submit Your Request</span>
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default P2pEnquiryForm