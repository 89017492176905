import React, { useMemo } from 'react'
import { FaPlay } from 'react-icons/fa'

type Props = {
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl"
}

const Playbtn = ({ size = "sm" }: Props) => {

    const [btnSize, iconSize] = useMemo(() => {
        if (size == "sm") {
            return [32, 12]
        }
        else if (size == "xs") {
            return [24, 8]
        }
        return [43, 12]
    }, [size])
    return (
        <div className={`h-[${btnSize}px] w-[${btnSize}px] rounded-full bg-primary flex justify-center items-center`}><FaPlay className='text-white' size={iconSize} /></div>
    )
}

export default Playbtn