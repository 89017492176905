import React from 'react'
import Forgot from 'src/modules/Auth/Forgot'

type Props = {
}

const ForgotMain = ({}: Props) => {
    return (
        <div className='min-h-[65vh] flex justify-center items-center'>
            <div className='w-[500px] mx-auto p-[48px] shadow-xl my-[50px]'>
                <Forgot />
            </div>
        </div>
    )
}

export default ForgotMain