import { ADDRESS_REGEX, EMAIL_REGEX, FormField, NAME_REGEX, NUMBER_REGEX, PHONE_NUMBER_REGEX, QUERY_TYPE_OPTIONS } from "src/helpers";
import CustomTextIcon from "src/shadcn/components/custom/CustomeSymbols/CustomTextIcon";
import * as Yup from "yup";


export interface IEpsDetailContactForm {
    name: string,
    phoneNumber: string,
    email: string,
    queryType: string,
    description: string,
}

export const MANUFACTURER_CONTACT_DETAILS_FORM_FIELDS: FormField[] = [
    {
        label: 'Name',
        dataKey: 'name',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter your name',
            regex: NAME_REGEX
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phoneNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: NUMBER_REGEX,
            placeholder: 'Enter your phone number',
            maxLength: 10
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: 'email',
            placeholder: 'Enter your email',
        },
    },

    {
        label: 'Query Type',
        dataKey: 'queryType',
        componentType: 'options',
        componentProps: {
            options: QUERY_TYPE_OPTIONS
        },
    },
    {
        label: 'Description',
        dataKey: 'description',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Enter your query description',
        },
    },
];


export const MANUFACTURER_CONTACT_DETAILS_FORM_INIT: IEpsDetailContactForm = {
    name: '',
    phoneNumber: '',
    email: '',
    queryType: '',
    description: '',
};


export const INIT_MANUFACTURER_PROFILE = {
    manufacturer_name: "",
    phoneNumber: "",
    manufacturerEmail: "",
    location: "",
    address: "",
    city: "",
    pincode: "",
    authorization_partner: "",
    specialization: "",
    is_otc_verified: "",
    noOfYearsExperiance: "",
    review: "",
    product_efficiency: "",
    companyAbouteUs: "",
    companyLogo: "",
    products: [],
}




export const MANUFACTURER_CONTACT_DETAILS_FORM_Schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phoneNumber: Yup.string().length(10, "Phone number must be 10 digits").required('Phone number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    queryType: Yup.string().required('Query type is required'),
    description: Yup.string().required('Description is required'),

});

export const INITIAL_LAND_ONBOARD_FORM_DATA = {
    whoYouAre: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    description: "",
    locationName: '',
    address: '',
    nearestCity: '',
    distanceFromNearestCity: "",
    directionFromNearestCity: '',
    nearestAirport: '',
    nearestBusStand: '',
    nearestRailwayStation: '',
    latitude: "",
    longitude: "",
    ownershipStatus: '',
    acquisitionStatus: '',
    expectedCompletionDate: "",
    substationName: '',
    distanceFromSubstation: "",
    currentStatus: '',
    distanceFromProjectSite: "",
    completionTimeline: '',
    voltageLevel: "",
    totalRequiredLand: "",
    acquiredLand: "",
    balanceLand: "",
    _712Report: "",
    saleDeedLeaseDeed: "",
    landUseConversionCertificate: "",
    remark: "",
    approvalReject: "",
};

export interface IPersonalDetails {
    whoYouAre: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    description: string;
}

export interface ILandLocation {
    locationName: string;
    address: string;
    nearestCity: string;
    distanceFromNearestCity: string;
    directionFromNearestCity: string;
    nearestAirport: string;
    nearestBusStand: string;
    nearestRailwayStation: string;
}

export interface ICoordinates {
    latitude: string;
    longitude: string;
}

export interface ILandOwnershipStatus {
    ownershipStatus: string;
    acquisitionStatus: string;
    expectedCompletionDate: string;
}

export interface ISubstationInformation {
    substationName: string;
    distanceFromSubstation: string;
    currentStatus: string;
}

export interface ITransmissionLine {
    distanceFromProjectSite: string;
    completionTimeline: string;
    voltageLevel: string;
}

export interface ILandInformation {
    totalRequiredLand: string;
    acquiredLand: string;
    balanceLand: string;
}

export interface ILandDocuments {
    _712Report: string;
    saleDeedLeaseDeed: string;
    landUseConversionCertificate: string;
}

export interface IRemark {
    remark: string;
}

export interface IApprovalStatus {
    approvalReject: string;
}

export type ILandData = IPersonalDetails
    & ILandLocation
    & ICoordinates
    & ILandOwnershipStatus
    & ISubstationInformation
    & ITransmissionLine
    & ILandInformation
    & ILandDocuments
    & IRemark
    & IApprovalStatus

export const LAND_ONBOARD_FORM_DATA: ILandData = {
    whoYouAre: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    description: "",
    locationName: '',
    address: '',
    nearestCity: '',
    distanceFromNearestCity: "",
    directionFromNearestCity: '',
    nearestAirport: '',
    nearestBusStand: '',
    nearestRailwayStation: '',
    latitude: "",
    longitude: "",
    ownershipStatus: '',
    acquisitionStatus: '',
    expectedCompletionDate: "",
    substationName: '',
    distanceFromSubstation: "",
    currentStatus: '',
    distanceFromProjectSite: "",
    completionTimeline: '',
    voltageLevel: "",
    totalRequiredLand: "",
    acquiredLand: "",
    balanceLand: "",
    _712Report: "",
    saleDeedLeaseDeed: "",
    landUseConversionCertificate: "",
    remark: "",
    approvalReject: "",
};




export const personalDetailsInit: IPersonalDetails = {
    whoYouAre: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    description: '',
};

export const landLocationInit: ILandLocation = {
    locationName: '',
    address: '',
    nearestCity: '',
    distanceFromNearestCity: '',
    directionFromNearestCity: '',
    nearestAirport: '',
    nearestBusStand: '',
    nearestRailwayStation: '',
};

export const coordinatesInit: ICoordinates = {
    latitude: '',
    longitude: '',
};

export const landOwnershipStatusInit: ILandOwnershipStatus = {
    ownershipStatus: '',
    acquisitionStatus: '',
    expectedCompletionDate: '',
};

export const substationInformationInit: ISubstationInformation = {
    substationName: '',
    distanceFromSubstation: '',
    currentStatus: '',
};

export const transmissionLineInit: ITransmissionLine = {
    distanceFromProjectSite: '',
    completionTimeline: '',
    voltageLevel: '',
};

export const landInformationInit: ILandInformation = {
    totalRequiredLand: '',
    acquiredLand: '',
    balanceLand: '',
};

export const landDocumentsInit: ILandDocuments = {
    _712Report: '',
    saleDeedLeaseDeed: '',
    landUseConversionCertificate: '',
};

export const remarkInit: IRemark = {
    remark: '',
};

export const approvalStatusInit: IApprovalStatus = {
    approvalReject: '',
};


export const landOnboardFormStructure = {
    "Personal Details": personalDetailsInit,
    "Land Location": landLocationInit,
    "Coordinates": coordinatesInit,
    "Land Ownership Status": landOwnershipStatusInit,
    "Substation Information": substationInformationInit,
    "Transmission Line": transmissionLineInit,
    "Land Information": landInformationInit,
    "Land Documents": landDocumentsInit,
    "Remark": remarkInit,
    "Approval Status": approvalStatusInit,
};

export const LAND_ONBOARD_FORM_FIELDS: FormField[] = [
    {
        label: 'First Name',
        dataKey: 'firstName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'First Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Last Name',
        dataKey: 'lastName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Last Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Phone',
        dataKey: 'phone',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Phone',
            maxLength: 10,
            required: true,
            regex: NUMBER_REGEX
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: "email",
            placeholder: 'Email',
            maxLength: 150,
            required: true,
            regex: EMAIL_REGEX
        },
    },
    {
        label: 'Description',
        dataKey: 'description',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Description',
            required: true
        },
    },
    {
        label: 'Location Name',
        dataKey: 'locationName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Location Name',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Address',
        dataKey: 'address',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Address',
            maxLength: 250,
            required: true,
            regex: ADDRESS_REGEX
        },
    },
    {
        label: 'Nearest City',
        dataKey: 'nearestCity',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest City',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Distance From Nearest City',
        dataKey: 'distanceFromNearestCity',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: 'Distance',
            required: true,
            inputSuffixIcon: <CustomTextIcon value="Km" />
        },
    },
    {
        label: 'Direction From Nearest City',
        dataKey: 'directionFromNearestCity',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: '',
            required: true,
            inputSuffixIcon: <CustomTextIcon value="Km" />
        },
    },
    {
        label: 'Nearest Airport',
        dataKey: 'nearestAirport',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest Airport',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Nearest Bus Stand',
        dataKey: 'nearestBusStand',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest Bus Stand',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Nearest Railway Station',
        dataKey: 'nearestRailwayStation',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest Railway Station',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Latitude',
        dataKey: 'latitude',
        componentType: 'input',
        componentProps: {
            type: "number",
            regex: NUMBER_REGEX,
            placeholder: 'Latitude',
            required: true
        },
    },
    {
        label: 'Longitude',
        dataKey: 'longitude',
        componentType: 'input',
        componentProps: {
            type: "number",
            regex: NUMBER_REGEX,
            placeholder: 'Longitude',
            required: true
        },
    },
    {
        label: 'Ownership Status',
        dataKey: 'ownershipStatus',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Acquisition Status',
        dataKey: 'acquisitionStatus',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Expected Completion Date',
        dataKey: 'expectedCompletionDate',
        componentType: 'date_picker',
        componentProps: {
            placeholder: 'Expected Completion Date',
            required: true
        },
    },
    {
        label: 'Substation Name',
        dataKey: 'substationName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Substation Name',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Distance From Substation',
        dataKey: 'distanceFromSubstation',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: 'Distance',
            required: true,
            inputSuffixIcon: <CustomTextIcon value="Km" />
        },
    },
    {
        label: 'Current Status',
        dataKey: 'currentStatus',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Distance of transmission line from Project Site',
        dataKey: 'distanceFromProjectSite',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: 'Distance',
            required: true,
            inputSuffixIcon: <CustomTextIcon value="Km" />
        },
    },
    {
        label: 'Completion Timeline',
        dataKey: 'completionTimeline',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: 'Days/Months/Years',
            options: [],
            required: true,
        },
    },
    {
        label: 'Voltage Level',
        dataKey: 'voltageLevel',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: '0',
            required: true,
            inputSuffixIcon: <CustomTextIcon value="kW" />
        },
    },
    {
        label: 'Total Required Land',
        dataKey: 'totalRequiredLand',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: '0',
            required: true,
            options: [],
        },
    },
    {
        label: 'Acquired Land',
        dataKey: 'acquiredLand',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: '0',
            required: true,
            options: [],
        },
    },
    {
        label: 'Balance Land',
        dataKey: 'balanceLand',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            placeholder: '0',
            required: true,
            options: [],
        },
    },
    {
        label: '7/12 Report',
        dataKey: '_712Report',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Sale Deed/Lease Deed',
        dataKey: 'saleDeedLeaseDeed',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Land Use Conversion Certificate',
        dataKey: 'landUseConversionCertificate',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Remark',
        dataKey: 'remark',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Remark',
            required: true
        },
    },
    {
        label: 'Approval/Reject',
        dataKey: 'approvalReject',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Remark',
        dataKey: 'remark',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Remark',
            maxLength: 150,
            required: true
        },
    },
];


const FILTERS = [
    {
        name: 'Manufacturer Name',
        dataKey: "manufacturer_name",
        type: 'text',
    },
    {
        name: 'Phone Number',
        dataKey: "phoneNumber",
        type: 'text',
    },
    {
        name: 'Manufacturer Email',
        dataKey: "manufacturerEmail",
        type: 'text',
    },
    {
        name: 'Location',
        dataKey: "location",
        type: 'text',
    },
    {
        name: 'Address',
        dataKey: "address",
        type: 'text',
    },
    {
        name: 'City',
        dataKey: "city",
        type: 'dropdown',
        options: [],
    },
    {
        name: 'Pincode',
        dataKey: "pincode",
        type: 'text',
        regex: PHONE_NUMBER_REGEX,
    },
    {
        name: 'Authorization Partner',
        dataKey: "authorization_partner",
        type: 'text',
    },
    {
        name: 'Specialization',
        dataKey: "specialization",
        type: 'dropdown',
        options: [],
    },
    {
        name: 'OTC Verified',
        dataKey: "is_otc_verified",
        type: 'list',
        optionsType: "multiselect",
        options: [],
    },
    {
        name: 'No. of Years Experience',
        dataKey: "noOfYearsExperiance",
        type: 'text',
    },
    {
        name: 'Product Efficiency',
        dataKey: "product_efficiency",
        type: 'text',
    },
];