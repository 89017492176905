import { AtSign, Calendar, CalendarHeart, Clock, FilePlus2, MapPin, Phone, Timer, User, Watch } from 'lucide-react'
import React from 'react'
import { IMG_CONTACT_US_BANNER, toAbsoluteUrl } from 'src/helpers'
import Dropdown from 'src/shadcn/components/custom/Dropdown'
import InputWithIcon from 'src/shadcn/components/custom/InputWithIcon'
import { Button, Label, Separator } from 'src/shadcn/components/ui'
import { Textarea } from 'src/shadcn/components/ui/textarea'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { DivIcon, LatLngExpression } from 'leaflet';
import ContactusForm from 'src/modules/ContactUs'
import { Link } from 'react-router-dom'
import { SlEnvolope } from "react-icons/sl";

type Props = {}

const position: LatLngExpression = [19.14000000, 72.93000000];

// const createCustomIcon = (): DivIcon => {
//     return new DivIcon({
//       html: `<div class="custom-marker-icon"><img src="${mySvgIcon}" alt="Custom Icon" /></div>`,
//       className: 'custom-marker',
//       iconSize: [38, 95], // Adjust size as needed
//       iconAnchor: [22, 94], // Adjust anchor as needed
//       popupAnchor: [-3, -76], // Adjust popup anchor as needed
//     });
//   };

const ContactusMain = (props: Props) => {
    return (
        <div>
            <div className='w-full h-[300px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_CONTACT_US_BANNER + ")", backgroundSize: "cover" }} >
                <div className='flex flex-col justify-center items-center h-[100%]'>
                    <div className='text-primary text-4xl ' >Contact Us</div>
                    <div className='text-white text-xs ' >We look forward to hearing from you</div>
                </div>
            </div>
            <div className='max-w-[80vw] my-[50px] space-y-[50px] mx-auto'>
                <div className='text-center text-sm'>
                    <p>If you would like to get in touch with us, please use the contact form below. We appreciate your inquiry and will respond to it promptly. By providing your interests, we can automatically send you initial information about your concern via email.</p>

                    <p>At the same time, we will forward your request to the appropriate department and assign a personal contact person. We look forward to hearing from you.</p>
                </div>
                <div className='grid grid-cols-3 gap-4'>
                    <div className='flex flex-col space-y-4'>
                        <div className='font-bold uppercase'>Contact Us</div>
                        <div className='flex space-x-2 items-start'>
                            <MapPin className='text-primary' size={24} />
                            <div>
                                <div className='font-bold'>Address</div>
                                <div className='text-sm'>{process.env.REACT_APP_CONTACT_ADDRESS}</div>
                            </div>
                        </div>
                        <div className='flex space-x-2 items-start'>
                            <Phone className='text-primary' size={18} />
                            <div>
                                <div className='font-bold'>Call Us</div>
                                <div className='text-sm'>{process.env.REACT_APP_CONTACT_PHONE}</div>
                            </div>
                        </div>
                        <div className='flex space-x-2 items-start'>
                            <SlEnvolope className='text-primary' size={20} />
                            {/* <img src={toAbsoluteUrl("/media/svg/msg.svg")} style={{height:"25px"}} /> */}
                            <div>
                                <div className='font-bold'>Email</div>
                                <div className='text-sm text-blue-600 underline underline-offset-2'><Link target="_blank" to={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>{process.env.REACT_APP_CONTACT_EMAIL}</Link></div>
                            </div>
                        </div>
                        <div className='flex space-x-2 items-start'>
                            <Clock className='text-primary' size={18} />
                            <div>
                                <div className='font-bold'>Office Hours</div>
                                <div className='flex space-x-1 text-sm items-center '><span><Calendar size={14} className='text-gray-400' /></span><span>Mon.- Fri.</span> <Separator orientation='vertical' /><span> <Timer size={14} className='text-gray-400' /></span><span> 08:00 - 18:00</span> </div>
                            </div>
                        </div>
                    </div>
                    <ContactusForm />
                </div>
                <div>
                    <MapContainer center={position} zoom={13} style={{ height: '400px', width: '100%' }}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={position}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
        </div>
    )
}

export default ContactusMain