import { Button, Dialog, DialogContent, DialogDescription, DialogHeader } from "src/shadcn/components/ui";
import { Loader2 } from "lucide-react";
import { CSSProperties, ReactNode } from "react";
import { cn } from "src/helpers";
import RoundedButton from "../RoundedButton";
import { ClassValue } from "clsx";


interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isOpen: boolean,
    onChange?: (e: any) => void
    onCancle?: () => void,
    onSubmit?: () => void,
    isLoading?: boolean,
    children: ReactNode,
    headerTitle: string | boolean,
    headerTitleClassName?: ClassValue
    hideDefaultSubmit?: boolean,
    hideDefaultCancle?: boolean,
    disabledSubmitBtn?: boolean,
    disabledExtraBtn?: boolean,
    disabledCancelBtn?: boolean,
    submitBtnTitle?: string,
    showExtraBtn?: boolean,
    extrabtnTitle?: string,
    extraBtnVarient?: "link" | "default" | "destructive" | "outline" | "secondary" | "ghost",
    extraBtnClick?: () => void

}

const CustomeDialog = ({
    isOpen,
    onChange,
    onCancle,
    onSubmit,
    hideDefaultSubmit = false,
    hideDefaultCancle = false,
    isLoading,
    children,
    headerTitle,
    headerTitleClassName,
    submitBtnTitle,
    showExtraBtn = false,
    extrabtnTitle,
    extraBtnVarient,
    disabledSubmitBtn,
    disabledExtraBtn,
    disabledCancelBtn,
    extraBtnClick,
    style,
    className,
}: Props) => {
    return (
        <Dialog
            open={isOpen}
            // onOpenChange={onOpenChange}

            modal
        >
            <DialogContent className={cn("sm:max-w-[525px] md:max-w-3xl", className)} hideClose style={style} >
                {
                    headerTitle && <DialogHeader>
                       <div className={cn(headerTitleClassName)}>{headerTitle}</div>
                    </DialogHeader>
                }
                <DialogDescription>
                    <div>
                        {children}
                    </div>
                    <div className="w-full flex justify-center space-x-2">
                        {!hideDefaultCancle && <RoundedButton type="button" disabled={isLoading || disabledCancelBtn} size={'sm'} onClick={onCancle} variant={"outline"} className=" inline-block px-16">
                            Cancel
                        </RoundedButton>}
                        {showExtraBtn && <RoundedButton type="button" size={'sm'} disabled={isLoading || disabledSubmitBtn} onClick={extraBtnClick} variant={extraBtnVarient || "default"} className=" inline-block px-16">
                            {extrabtnTitle}
                        </RoundedButton>}
                        {!hideDefaultSubmit && <RoundedButton
                            disabled={isLoading || disabledSubmitBtn}
                            onClick={onSubmit}
                            size={'sm'}
                            type="submit"
                            className=" inline-block px-16"
                        >
                            <div className="flex space-x-2">
                                {isLoading && isLoading && (
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                )}
                                {submitBtnTitle || 'Submit'}
                            </div>
                        </RoundedButton>}
                    </div>
                </DialogDescription>
            </DialogContent>
        </Dialog>

    )
}

export default CustomeDialog