import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { toast } from 'src/shadcn/components/ui/use-toast';
import { RootState } from '../store';
import { fetchCartItemsByUserIDApiRequest, updateCartItemsQuantityApiRequest } from 'src/services/requests/carts';
import { getAuth } from 'src/modules/Auth/Core';
import { cancelOrderApiRequest, createOrderApiRequest, fetchOrdersByUserIDApiRequest } from 'src/services/requests/Order';

// Define the initial state
interface IOrderReducerState {
    orders: {
        orders: any[],
        isLoading: boolean,
        isError: boolean,
        message: string
    },
    createOrder: {
        orderItems: any[],
        orderDetails: any,
        isLoading: boolean,
        isError: boolean,
        message: string
    }
}

const initialState: IOrderReducerState = {
    orders: {
        orders: [],
        isLoading: false,
        isError: false,
        message: ""
    },
    createOrder: {
        orderItems: [ ],
        orderDetails: {},
        isLoading: false,
        isError: false,
        message: ""
    }
};

// Define the thunk to fetch cart items
export const fetchOrdersByUserIdAction: any = createAsyncThunk(
    'order/fetchOrders',
    async () => {
        try {
            const auth = getAuth()
            if (auth?.user_id) {
                const response: any = await fetchOrdersByUserIDApiRequest(auth?.user_id)
                return response
            }
        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                if (error) {
                    const status = error.response?.status;
                    toast({
                        variant: "destructive",
                        title: "Uh oh! Something went wrong.",
                        description: error.response?.data?.message,
                    })
                }
            } else {
                toast({
                    variant: "destructive",
                    title: "Unexpected error",
                    description: error?.message,
                })
            }
        }
    }
);

// // Define the thunk to update cart quantity
// export const cancelOrderAction: any = createAsyncThunk(
//     'order/cancelOrder',
//     async (orderID: string) => {
//         try {
//             const response = await cancelOrderApiRequest(orderID)
//             return response.data
//         } catch (err) {
//             const error = err as Error | AxiosError;
//             if (axios.isAxiosError(error)) {
//                 if (error) {
//                     const status = error.response?.status;
//                     toast({
//                         variant: "destructive",
//                         title: "Uh oh! Something went wrong.",
//                         description: error.response?.data?.message,
//                     })
//                 }
//             } else {
//                 toast({
//                     variant: "destructive",
//                     title: "Unexpected error",
//                     description: error?.message,
//                 })
//             }
//         }
//     }
// );

// export const createOrderAction: any = createAsyncThunk(
//     'order/createOrder',
//     async (data: any) => {
//         try {
//             const response = await createOrderApiRequest(data)
//             return response.data
//         } catch (err) {
//             const error = err as Error | AxiosError;
//             if (axios.isAxiosError(error)) {
//                 if (error) {
//                     const status = error.response?.status;
//                     toast({
//                         variant: "destructive",
//                         title: "Uh oh! Something went wrong.",
//                         description: error.response?.data?.message,
//                     })
//                 }
//             } else {
//                 toast({
//                     variant: "destructive",
//                     title: "Unexpected error",
//                     description: error?.message,
//                 })
//             }
//         }
//     }
// );


// Define the reducer
const orderReducer = createSlice({
    name: 'orderReducer',
    initialState,
    reducers: {
        addOrderItem: (state, action: PayloadAction<any>) => {
            let items = [...state.createOrder.orderItems]
            if(items.find((d:any)=> d._id ==  action.payload?._id)){
                state.createOrder.orderItems = items.map((product: any) => {
                    if (product._id === action.payload._id) {
                        return { ...product, quantity: parseInt(product.quantity) + 1 };
                    }
                    return product;
                });
            }
            else state.createOrder.orderItems = [...state.createOrder.orderItems, action.payload]
        },
        updateQuantityOfItem: (state, action: PayloadAction<{ product_id: string, quantity: string }>) => {
            let items = [...state.createOrder.orderItems]
            state.createOrder.orderItems = items.map((product: any) => {
                if (product._id === action.payload.product_id) {
                    return { ...product, quantity: action.payload.quantity };
                }
                return product;
            });
        },
        setOrderItems: (state, action: PayloadAction<any>) => {
            state.createOrder.orderItems = action.payload
        },
        setOrderDetails: (state, action: PayloadAction<any>) => {
            state.createOrder.orderDetails = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrdersByUserIdAction.pending, (state: IOrderReducerState) => {
            state.orders.isLoading = true;
            state.orders.isError = false;
            state.orders.message = "";
        });
        builder.addCase(fetchOrdersByUserIdAction.fulfilled, (state: IOrderReducerState, action: any) => {
            state.orders.isLoading = false;
            state.orders.orders = action.payload
        });
        builder.addCase(fetchOrdersByUserIdAction.rejected, (state: IOrderReducerState, action: any) => {
            state.orders.isLoading = false;
            state.orders.isError = true;
            state.orders.message = action.error.message;
        });

    },
});

// Export the reducer and actions
export default orderReducer.reducer;
export const { addOrderItem, setOrderItems, setOrderDetails,updateQuantityOfItem } = orderReducer.actions;
export const getOrderReducerRootState = (state: RootState) => state.order;