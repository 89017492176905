import { MoveUpRight, ShoppingCart } from 'lucide-react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { USER_ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import ListMain from 'src/modules/Products/ListMain'
import ProductInformation from 'src/modules/Products/ProductInformation'
import { APP_ROUTES_CONST, MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { fetchCartItemsAction, getCartReducerRootState } from 'src/redux/reducers/cartReducer'
import { RootState } from 'src/redux/store'
import { Button, Separator } from 'src/shadcn/components/ui'

type Props = {}

const ProductsPageMain = (props: Props) => {

    const { cartItems } = useSelector((state: RootState) => getCartReducerRootState(state))
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const { currentUser } = useAuth()

    useEffect(() => {
        currentUser?.role == USER_ROLES.OFF_TAKER && dispatch(fetchCartItemsAction())
        return () => { }
    }, [])


    return (
        <div className=''>
            <div className='space-y-2 py-2'>
                <ProductInformation />
                
                {/* <ListMain /> */}
            </div>
        </div>
    )
}

export default ProductsPageMain