export interface ISolarPlanArr {
  name: string;
  projectPrice: number;
  overAllPrice: {
    totalPrice: number;
    discountPrice: number;
  };
  emiPrice: {
    totalPrice: number;
    discountPrice: number;
  };
  benefits: string[];
  unlockPrice: number;
}

export const SolarPlanArr: ISolarPlanArr[] = [
  {
    name: "Economy",
    projectPrice: 228000,
    overAllPrice: {
      totalPrice: 236448,
      discountPrice: 228000,
    },
    emiPrice: {
      totalPrice: 14954,
      discountPrice: 14250,
    },
    benefits: [
      "Less than <b>5 Years</b>",
      "Efficiency <b>Below 18 %</b>",
      "<b>Indian</b> Brands",
      "<b>Eligible</b> for subsidy",
      "Typical Rate: <b>57,000 kW</b>",
    ],
    unlockPrice: 8448,
  },
  {
    name: "STANDARD",
    projectPrice: 250000,
    overAllPrice: {
      totalPrice: 259264,
      discountPrice: 250000,
    },
    emiPrice: {
      totalPrice: 16397,
      discountPrice: 15625,
    },
    benefits: [
      "<b>5 -10 Years</b>",
      "Efficiency <b>18% - 21 %</b>",
      "<b>Indian</b> Brands",
      "<b>Eligible</b> for subsidy",
      "Typical Rate: <b>60,000 kW</b>",
    ],
    unlockPrice: 9264,
  },
  {
    name: "premium",
    projectPrice: 250000,
    overAllPrice: {
      totalPrice: 259264,
      discountPrice: 250000,
    },
    emiPrice: {
      totalPrice: 16397,
      discountPrice: 15625,
    },
    benefits: [
      "Above <b>10 Years</b>",
      "Above <b> 21%</b>",
      "<b>Indian</b> Brands",
      "<b>Eligible</b> for subsidy",
      "Typical Rate: <b>66,000 kW</b>",
    ],
    unlockPrice: 9264,
  },
];
