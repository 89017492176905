import React from 'react'
import { IMG_LAND_LEASING_BENEFITS } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const LandLeasingBenifitsSection = (props: Props) => {
    return (
        <div className='space-y-4'>
            <div className='text-center text-xl font-bold'>Benefits of Rooftop Solar Systems</div>
            <div>
                <img src={IMG_LAND_LEASING_BENEFITS} className=' mx-auto w-[90%] rounded-xl h-[528px]' />
            </div>

            <div className='flex items-center w-[80%] mx-auto space-y-2 '>
                <div className='flex flex-col w-3/4' >
                    <div className='text-lg font-bold'>Get in Touch</div>
                    <div className=' text-sm'>Ready to turn your rooftop into a source of clean energy? Contact us today to learn more about our rooftop solar solutions and how we can help you save money while supporting a sustainable future.</div>
                </div>
                <div className='flex justify-center w-1/4'>
                    <RoundedButton>I am interested</RoundedButton>
                </div>
            </div>

        </div>
    )
}

export default LandLeasingBenifitsSection