import { Calendar, IndianRupee, Percent } from "lucide-react";
import { FormField } from "src/helpers";
import { ICreatePaymentRequestBody } from "src/services/requests/Payments/modules";
import * as Yup from "yup"

export interface IInvestmentCalculatorForm {
    investmentAmount: string,
    finalReturn: string,
    yearTillReturn: string
}

export interface IDetailChargesForm {
    price: string,
    salesPrice: string,
    totalProjectPrice: string,
    pricePerKwh: string,
    charges: string
}


export interface IInvestmentDetailsForm {
    investmentAmount: string,
}

export const InvestmentDetailsFormInit = {
    investmentAmount: null,
}


export interface IInvestmentDetailsFormResult {
    totalInterestReturn: string,
    investmentGain: string,
    expectedReturnPerYear: string
    finalReturn: string,
    yearTillReturn: string
}

export const InvestmentDetailsFormResult = {
    totalInterestReturn: null,
    investmentGain: null,
    expectedReturnPerYear: null,
    finalReturn: 10,
    yearTillReturn: null,

}

export interface IInvestmentConfirmFormResult {
    totalInterestReturn: string,
    investmentGain: string,
    expectedReturnPerYear: string
    totalInvestment: string,
    yearsTillReturn: string,
    charges: string,
    projectCost: string,
    totalProjectCost: string
    pricePerKwh: string
}

export const InvestmentConfirmFormResultInit = {
    totalInterestReturn: "",
    investmentGain: "",
    expectedReturnPerYear: "",
    totalInvestment: "",
    yearsTillReturn: "",
    charges: "",
    projectCost: "",
    totalProjectCost: "",
    pricePerKwh: ""
}

export interface IPaymentCreditCardForm {
    cardNumber: number,
    month: number,
    year: number,
    cvv: number,
}


export const investmentCalculatorFormValidationSchema = Yup.object().shape({
    investmentAmount: Yup.number()
        .positive('Investment amount must be a positive number')
        .required('Investment amount is required'),
});


export const INVESTMENT_CALCULATOR_FORM_FIELDS: FormField[] = [
    {
        label: 'Investment Amount',
        dataKey: 'investmentAmount',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            numberFormatted: true
        }
    },

];

export const INVESTMENT_RESULT_FORM_FIELDS: FormField[] = [
    {
        label: 'Expected Return Per Year',
        dataKey: 'expectedReturnPerYear',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            disabled: true,
            numberFormatted: true
        }
    },
    {
        label: 'Year Till Return',
        dataKey: 'yearTillReturn',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            // inputPrefixIcon: <Percent className='text-gray-500' size={16} />,
            inputSuffixIcon: "Years",
            disabled: true,
            numberFormatted: true
        }
    },
    // {
    //     label: 'Investment Gain Per Year (₹)  ',
    //     dataKey: 'investmentGain',
    //     componentType: 'input',
    //     componentProps: {
    //         type: 'text',
    //         regex: /[^0-9]+/g,
    //         inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
    //         disabled:true,
    //     }
    // },

    {
        label: 'Annualized ROI',
        dataKey: 'totalInterestReturn',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputSuffixIcon: <Percent className='text-gray-500' size={16} />,
            disabled: true,
            inputWidth: 100,
            numberFormatted: true
        }
    },
    {
        label: 'Total Return',
        dataKey: 'finalReturn',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            disabled: true,
            numberFormatted: true
        }
    }
];


export const INVESTMENT_DETAILED_CHARGES_FORM_FIELDS: FormField[] = [

    {
        label: 'Project Price',
        dataKey: 'projectCost',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            readonly: true,
            numberFormatted: true
        }
    },
    {
        label: 'Total Project Price',
        dataKey: 'totalProjectCost',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            readonly: true,
            numberFormatted: true
        }
    },
    {
        label: 'Price Per kWp',
        dataKey: 'pricePerKwh',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
            inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
            readonly: true,
            numberFormatted: true
        }
    },
    // {
    //     label: 'Charges',
    //     dataKey: 'charges',
    //     componentType: 'input',
    //     componentProps: {
    //         type: 'text',
    //         regex: /[^0-9]+/g,
    //         inputPrefixIcon: <IndianRupee className='text-gray-500' size={16} />,
    //         readonly: true,
    //         numberFormatted:true
    //     }
    // }
];

export const PAYMENT_CREDIT_CARD_FORM_FIELDS: FormField[] = [
    {
        label: 'Card Number',
        dataKey: 'cardNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
        }
    },
    {
        label: 'Month',
        dataKey: 'month',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
        }
    },
    {
        label: 'Year',
        dataKey: 'year',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
        }
    },
    {
        label: 'CVV',
        dataKey: 'cvv',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: /[^0-9]+/g,
        }
    },


];

const creditCardValidationSchema = Yup.object({
    cardNumber: Yup.string().length(16, "should be 16 digits only")
        .required('Card number is required'),
    month: Yup.string()
        .matches(/^(0[1-9]|1[0-2])$/, 'Invalid month')
        .required('Expiration month is required'),
    year: Yup.string()
        .matches(/^\d{2}$/, 'Invalid year')
        .required('Expiration year is required'),
    cvv: Yup.string()
        .matches(/^\d{3}$/, 'CVV must be exactly 3 digits')
        .required('CVV is required'),
});

export const PAYMENT_CONFIRMATION_FORM_FIELDS = [
    {
        label: 'Investment - ₹  Gain',
        dataKey: 'investmentGain',
    },
    {
        label: 'ROI',
        dataKey: 'roi',
    },
    {
        label: 'Annualized ROI',
        dataKey: 'annualizedRoi'
    },
    {
        label: 'Total Amount',
        dataKey: 'totalAmount'
    }
]

export const INIT_CREATE_PAYMENT_REQUEST: ICreatePaymentRequestBody = {
    user_id: "483168",
    project_id: "456256",
    vendor_id: "ghdsghdsvhd",
    amount: "123",
    currency: "inr",
    payment_method: {
        type: "creditcard",
        card_number: "0987654",
        expiration_date: "12/25",
        cvv: "455"
    }
};


// project details
export interface TechnicalData {
    unitRequiredPerMonth: string;
    monthlyBillAmount: string;
    availableSpaceSQ: string;
    unitConsumptionPerDay: string;
    tariffRate: string;
    projectOwnershipType: string;
    dcCommissioning: string;
    pricePerKwh: string;
}


export interface ProjectData {
    contactPersonName: string;
    contactPersonNumber: string;
    projectName: string;
    projectType: string;
    systemType: string;
    propertyType: string;
    buildingType: string;
    address: string;
    city: string,
    state: string;
    country: string;
    pinCode: string;
    projectDescription: string;
    projectOwnershipType: string;
    projectSize: string;
}

export interface ProjectFinanceData {
    projectCost: string;
    otherCost: string;
    structureCost: string,
    electicalCost: string
    interestRate: string;
    interestAmount: string;
    totalProjectCost: string;
    returnPerYearRate: string,
    returnPerYearAmount: string;
    payBack: string;
    returnPerYear: string;
    termPlan: string;
    downpaymentByOfftaker: string;
    requiredInvestment: string;
    offtakerOwnershipPercentage: string;
}

export interface Attachments {
    imagesAttachments: File[] | string[] | undefined,
    videoAttachments: File[] | string[] | undefined,
    headerImage: File | string | undefined,
}

export const initialTechnicalData: TechnicalData = {
    unitRequiredPerMonth: '',
    monthlyBillAmount: '',
    availableSpaceSQ: '',
    unitConsumptionPerDay: '',
    tariffRate: '',
    projectOwnershipType: '',
    dcCommissioning: '',
    pricePerKwh: '',
};

export const initialProjectData: ProjectData = {
    contactPersonName: '',
    contactPersonNumber: '',
    projectName: '',
    projectType: '',
    systemType: '',
    propertyType: "",
    buildingType: '',
    address: '',
    city: "",
    state: '',
    country: 'INDIA',
    pinCode: '',
    projectDescription: '',
    projectOwnershipType: '',
    projectSize: '',
};

export const initialProjectFinanceData: ProjectFinanceData = {
    projectCost: '',
    structureCost: "",
    electicalCost: "",
    otherCost: '',
    interestRate: '',
    interestAmount: '',
    totalProjectCost: '',
    returnPerYearRate: "",
    returnPerYearAmount: '',
    payBack: '',
    returnPerYear: '',
    termPlan: '',
    downpaymentByOfftaker: '',
    requiredInvestment: '',
    offtakerOwnershipPercentage: '',
};

export const initialAttachments: Attachments = {
    imagesAttachments: undefined,
    videoAttachments: undefined,
    headerImage: undefined,
}


export const PROJECT_FORM_FIELDS = [
    {
        label: 'Project Name',
        dataKey: 'projectName',

    },
    {
        label: 'Project Type',
        dataKey: 'projectType',

    },
    {
        label: 'System Type',
        dataKey: 'systemType',

    },
    {
        label: 'Property Type',
        dataKey: 'propertyType',

    },
    {
        label: 'Building Type',
        dataKey: 'buildingType',

    },
    {
        label: 'Address',
        dataKey: 'address',

    },
    {
        label: 'City',
        dataKey: 'city',

    },
    {
        label: 'State',
        dataKey: 'state',

    },
    {
        label: 'Country',
        dataKey: 'country',

    },
    {
        label: 'Pin Code',
        dataKey: 'pinCode',

    },
    {
        label: 'Project Size',
        dataKey: 'projectSize',

    },
    {
        label: 'Project Description',
        dataKey: 'projectDescription',

    },
    {
        label: 'Project Cost',
        dataKey: 'projectCost',

    },
    {
        label: 'Other Cost',
        dataKey: 'otherCost',

    },
    {
        label: 'Interest (%)',
        dataKey: 'interestRate',

    },
    {
        label: 'Interest Amount',
        dataKey: 'interestAmount',

    },
    {
        label: 'Total Project Cost',
        dataKey: 'totalProjectCost',

    },
    {
        label: 'Return Per Year (%)',
        dataKey: 'returnPerYearRate',

    },
    {
        label: 'Return Per Year Amount',
        dataKey: 'returnPerYearAmount',

    },
    {
        label: 'Downpayment By Offtaker',
        dataKey: 'downpaymentByOfftaker',

    },
    {
        label: 'Required Investment',
        dataKey: 'requiredInvestment',

    },
    {
        label: 'Offtaker Ownership (%)',
        dataKey: 'offtakerOwnershipPercentage',

    },
    {
        label: 'Unit Required Per Month',
        dataKey: 'unitRequiredPerMonth',

    },
    {
        label: 'Unit Consumption Per Day',
        dataKey: 'unitConsumptionPerDay',

    },
    {
        label: 'Tariff Rate',
        dataKey: 'tariffRate',

    },
    {
        label: 'Monthly Bill Amount',
        dataKey: 'monthlyBillAmount',

    },
    {
        label: 'Pay Back (Months)',
        dataKey: 'payBack',

    },
    {
        label: 'Term Plan (Months)',
        dataKey: 'termPlan',

    },
    {
        label: 'Project Ownership Type',
        dataKey: 'projectOwnershipType',

    },
    {
        label: 'Price Per KWh',
        dataKey: 'pricePerKwh',

    },
    {
        label: 'Available Space SQ',
        dataKey: 'availableSpaceSQ',

    },
    {
        label: 'Number Of Roofs',
        dataKey: 'numberOfRoofs',

    },
    {
        label: 'DC Commissioning',
        dataKey: 'dcCommissioning',

    },
    {
        label: 'Contact Person Name',
        dataKey: 'contactPersonName',

    },
    {
        label: 'Contact Person Number',
        dataKey: 'contactPersonNumber',

    },
    {
        label: 'Select Title Images',
        dataKey: 'headerImage',

    },
    {
        label: 'Select Images',
        dataKey: 'imagesAttachments',

    },
    {
        label: 'Enter video URl',
        dataKey: 'videoAttachments',
    },
    {
        label: 'Electical Cost',
        dataKey: 'electicalCost',
    },
    {
        label: 'Structure Cost',
        dataKey: 'structureCost',
    },
];



