import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const OVERVIEW = {
    title: "New Constructed Project Solar Services Overview",
    description: "Transform your newly constructed projects into sustainable energy solutions with our specialized solar services. We provide comprehensive support for integrating solar energy systems into new constructions, ensuring your project is both environmentally friendly and cost-effective from the ground up.",
    video: "https://www.youtube.com/watch?v=Z4-Z2Z7-1_k",
    img: "",
    targetPage: MAIN_ROUTES_CONST.marketplace+ "?commercialAndIndustrial=Buy New Construction Projects"
  };
  
  export const KEY_FETURES =[
    { icon: "🧩", title: "Seamless Integration", description: "Our team works closely with architects and builders to ensure that solar systems are seamlessly integrated into the design of new constructions, maximizing efficiency and aesthetic appeal." },
    { icon: "🎨", title: "Custom Design Solutions", description: "We offer tailored solar designs that meet the specific energy needs of your project while considering factors like roof orientation, space, and local regulations." },
    { icon: "🏡", title: "Increased Property Value", description: "Installing solar systems in new constructions enhances property value, attracting environmentally conscious buyers and tenants looking for sustainable features." },
    { icon: "📑", title: "Compliance with Regulations", description: "We ensure that all solar installations comply with local building codes and regulations, minimizing potential legal issues during and after construction." }
  ];

  export const FAQ = [
    {
      "question": "What is a new construction solar project?",
      "answer": "A new construction solar project integrates solar power systems into buildings or developments during the construction phase, maximizing energy efficiency and sustainability from the start."
    },
    {
      "question": "Why should I consider solar for my new construction project?",
      "answer": ""
    },
    {
      "question": "How does the solar design process work for new construction?",
      "answer": ""
    },
    {
      "question": "How long does installation take in a new construction project?",
      "answer": ""
    },
    {
      "question": "What permits and approvals are needed?",
      "answer": ""
    },
    {
      "question": "Can the solar system be customized for specific energy needs?",
      "answer": ""
    }
  ]