import { EMAIL_REGEX, FormField, NAME_REGEX, NUMBER_REGEX } from 'src/helpers';
import * as Yup from 'yup';


export interface ILandDetails {
    whoYouAre: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    description: string;
}

export const INITIAL_LAND_ONBOARD_FORM_DATA: ILandDetails = {
    whoYouAre: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    description: "",
};

export const LAND_ONBOARD_FORM_FIELDS: FormField[] = [
    {
        label: 'Who Are you',
        dataKey: 'whoYouAre',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Who Are you',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'First Name',
        dataKey: 'firstName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'First Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Last Name',
        dataKey: 'lastName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Last Name',
            maxLength: 150,
            required: true,
            regex: NAME_REGEX
        },
    },
    {
        label: 'Phone',
        dataKey: 'phone',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Phone',
            maxLength: 10,
            required: true,
            regex: NUMBER_REGEX
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: "email",
            placeholder: 'Email',
            maxLength: 150,
            required: true,
            regex: EMAIL_REGEX
        },
    },
    {
        label: 'Description',
        dataKey: 'description',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Description',
            required: true
        },
    },
    
];


export const landOnboardDataValidationSchema = Yup.object().shape({
    whoYouAre: Yup.string().required('Who you are is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phone: Yup.string().required('Phone number is required'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    description: Yup.string().required('Description is required'),
  });
