import React from 'react'
import { SolarServicesDetails } from './modules'
import HtmlCodeRender from 'src/shadcn/components/custom/HtmlCodeRender'
import { Button } from 'src/shadcn/components/ui'
import { cn } from 'src/helpers'
import { useNavigate } from 'react-router-dom'

const SolarServices = () => {

  const navigate = useNavigate()

  return (
    <div className='mt-[3%]'>
      <div className='text-[#454545] font-sans text-3xl'>
        <div className='font-normal text-center'>Transforming Sunshine into Savings: </div>
        <div className='text-center font-bold'>Explore Our Solar Services</div>
      </div>
      <div className="grid grid-cols-3 gap-y-10 mt-10">
        {SolarServicesDetails?.map(
          ({ btnName, description, image, link, title }, index) => (
            <div key={`${title}-${index}`} className={cn("w-[414px] relative ", { "grayscale opacity-70 pointer-events-none": !link })}>
              <div className="  h-[400px] z-[50]">
                <img src={image} alt={title} />
              </div>
              <div className="w-full h-[211px] bg-[#717171E5] rounded-[30px]  space-y-4 flex flex-col justify-center items-start absolute z-0 -mt-[45%]">
                <p className="flex text-sm font-normal text-white  ml-5">
                  <HtmlCodeRender htmlString={description} />
                </p>
                <div className="">
                  <Button onClick={() => navigate("/" + link)} className="rounded-full w-[177px] h-[42px] p-[10px] ml-5 border border-white">
                    {btnName}
                  </Button>
                </div>
              </div>
              <div className="w-[245px] h-[58px] clip-right-50 border bg-primary absolute z-[10] -mt-[52%]">
                <div className="w-[245px] h-[58px] clip-right-45 border bg-white font-bold text-[16px] flex items-center px-5">
                  {title}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default SolarServices