import {
  DISCOMS_OPTIONS,
  FormField,
  NUMBER_REGEX,
  QUERY_TYPE_OPTIONS,
} from "src/helpers";
import * as Yup from "yup";
export interface IServicesEnquiryForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  category: string;
  queryType: string;
  description: string;
  attachments: File | undefined;
}
export const SERVICES_ENQUIRY_FORM_FIELDS: FormField[] = [
  {
    label: "First Name",
    dataKey: "firstName",
    componentType: "input",
    componentProps: {
      type: "text",
      placeholder: "First Name",
    },
  },
  {
    label: "Last Name",
    dataKey: "lastName",
    componentType: "input",
    componentProps: {
      type: "text",
      placeholder: "Last Name",
    },
  },
  {
    label: "Phone Number",
    dataKey: "phoneNumber",
    componentType: "input",
    componentProps: {
      type: "text",
      regex: NUMBER_REGEX,
      placeholder: "Phone Number",
      maxLength: 10,
    },
  },
  {
    label: "Email",
    dataKey: "email",
    componentType: "input",
    componentProps: {
      type: "email",
      placeholder: "Enter your email",
    },
  },
  {
    label: "Category",
    dataKey: "category",
    componentType: "options",
    componentProps: {
      options: QUERY_TYPE_OPTIONS,
    },
  },
  {
    label: "Query Type",
    dataKey: "queryType",
    componentType: "options",
    componentProps: {
      options: QUERY_TYPE_OPTIONS,
    },
  },
  {
    label: "Description",
    dataKey: "description",
    componentType: "textarea",
    componentProps: {
      placeholder: "Enter your query description",
    },
  },
  {
    label: "Attachments",
    dataKey: "attachments",
    componentType: "file_picker",
    componentProps: {
      placeholder: "Select files to attach",
    },
  },
];

export const SERVICES_ENQUIRY_INIT: IServicesEnquiryForm = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  category: "",
  queryType: "",
  description: "",
  attachments: undefined,
};

export const Services_EnquiryFormSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    // category: Yup.string().required('Category is required'),
    queryType: Yup.string().required('Query type is required'),
    description: Yup.string().required('Description is required'),
    attachments: Yup.mixed().optional()
    .test('fileType', 'Only jpg, png files are allowed', (value: any) => {
      if (!value) return true; // allow empty values
      if (value instanceof File) {
        const fileType = value.type;
        return (
          fileType === 'image/jpeg' ||
          fileType === 'image/png' ||
          fileType === 'application/pdf' ||
          fileType === 'text/csv'
        );
      }
      else if (typeof value == "string") {
        return true
      }
    })
    .test('fileSize', 'File size must be less than 5MB', (value: any) => {
      if (!value) return true; // allow empty values
      if (value instanceof File) {
        const fileSize = value.size;
        return fileSize <= 5 * 1024 * 1024; // 5MB
      }
      else if (typeof value == "string") {
        return true
      }
    }),
});