import React from 'react' 
import FinanceDetails from 'src/modules/SubServices/Finance/FinanceDetails' 
 
type Props = {} 
 
const FinancePage = (props: Props) => { 
    return ( 
        <div> 
            <FinanceDetails /> 
        </div> 
    ) 
} 
 
export default FinancePage 
