import React from 'react'
import { toAbsoluteUrl } from 'src/helpers'

type Props = {}

const Vision = (props: Props) => {
    return (
        <div>
            <div className=' grid grid-cols-4 ' >
                <div className='h-[120px] w-[120px] mx-auto bg-gray-200 flex justify-center items-center rounded-full'>
                    <img src={toAbsoluteUrl("/media/png/vision_icon.png")} style={{  height: "70px" }} />
                </div>
                <div className='col-span-3 flex flex-col space-y-1 '>
                    <div className='font-bold text-xl' >Vision</div>
                    <div>To spearhead India's energy transition as the foremost platform for renewable energy projects and solutions, empowering individuals and communities to actively engage in and benefit from clean energy, while advancing global sustainability through innovation, financial empowerment, and a steadfast commitment to the environment</div>
                </div>
            </div>
        </div>
    )
}

export default Vision