import React from 'react'
import ListItem from './ListItem'

type Props = {
  data: any
}


const data = [
  {
    "manufacturerName": "NovaGreen EPC",
    "reviews": 50,
    "location": "Noida",
    "rating": 4.5,
    "companyLogo": "https://pngtree.com/freepng/corporate-image-logo-free-logo-design-template_1026060.html",
    "productsCount": 98,
    "panelEfficiency": 90,
    "yearsOfExperience": 17
  },
  {
    "manufacturerName": "SolarMax Energy",
    "reviews": 25,
    "location": "Delhi",
    "rating": 4.2,
    "companyLogo": "https://pngtree.com/freepng/color-company-logo-design_3564239.html",
    "productsCount": 50,
    "panelEfficiency": 50,
    "yearsOfExperience": 10
  },
  {
    "manufacturerName": "GreenTech Solutions",
    "reviews": 100,
    "location": "Mumbai",
    "rating": 4.8,
    "companyLogo": "https://pngtree.com/freepng/creative-company-logo_1187566.html",
    "productsCount": 150,
    "panelEfficiency": 120,
    "yearsOfExperience": 20
  },
  {
    "manufacturerName": "EcoFriendly Power",
    "reviews": 30,
    "location": "Bangalore",
    "rating": 4.1,
    "companyLogo": "https://pngtree.com/freepng/vector-creative-red-logo_701575.html",
    "productsCount": 70,
    "panelEfficiency": 60,
    "yearsOfExperience": 12
  },
  {
    "manufacturerName": "Renewable Energy Corp",
    "reviews": 80,
    "location": "Chennai",
    "rating": 4.6,
    "companyLogo": "https://pngtree.com/freepng/personalized-color-hands-vector-logo_638866.html",
    "productsCount": 120,
    "panelEfficiency": 100,
    "yearsOfExperience": 18
  }
]


const ListItems = ({ data }: Props) => {
  return (
    <div className='grid grid-cols-2 gap-2'>
      {data && Array.isArray(data) && data.length > 0 ? data.map((d: any) => {
        return <ListItem {...d} />
      }) : <div className='flex h-[400px] w-100 justify-center items-center' >
        No Content
      </div>}
    </div>

  )
}

export default ListItems