import React, { ChangeEvent } from 'react'
import { Label, Textarea } from '../ui'
import { cn } from 'src/helpers'
import { ClassNameValue } from 'tailwind-merge'

type Props = {
    label?: string,
    formik: any,
    required?: boolean,
    onChange?: (e: any) => void,
    nameKey: string
    readOnly?: boolean,
    regex?: RegExp,
    value: string,
    placeholder?: string,
    disabled?: boolean,
    data_qa?: string,
    maxLength?: number,
    labelStyle?: ClassNameValue,
    error_className?: ClassNameValue,
}

const TextareaFormFiled = ({ formik, labelStyle, label, required, onChange, nameKey, readOnly = false, regex, value, placeholder, disabled, data_qa, maxLength = 250,error_className }: Props) => {
    return (
        <div className='flex flex-col'>
            <div className='flex justify-between mb-2'>
                <Label className={cn(" text-xs", labelStyle, `${required ? "required" : ""}`)}>
                    {label}
                </Label>
                <div>
                    {maxLength && <div className="text-xs text-end text-gray-500 mt-1">{value?.length || 0}/{maxLength}</div>}
                </div>
            </div>
            <Textarea data-qa={data_qa} maxLength={maxLength} disabled={disabled} placeholder={placeholder} readOnly={readOnly} value={value} className='w-full bg-white text-black' rows={2} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                if (regex && regex) {
                    let cleanedValue = e.target.value.replace(regex, "");
                    onChange && onChange(cleanedValue);
                }
                else onChange && onChange(e.target.value)
            }} />
            {formik.touched[nameKey] && formik.errors[nameKey] && (
             <div className={cn("text-xs font-medium text-destructive", error_className)}>
                    {formik.errors[nameKey]}
                </div>
            )}
        </div>
    )
}

export default TextareaFormFiled