import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/LandRegistration";


export function createLandRegistrationApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.post(`${API.CREATE_LAND_REGISTRATION_REQUEST}`, { ...data }).then((d: AxiosResponse) => d.data)
}


export function getLandRegistrationDetailApiRequest(id: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.LAND_REGISTRATION_DETAIL_BY_ID}/${id}`).then((d: AxiosResponse) => d.data)
}


export function allLandRegistrationRequestsApiRequest(query: string): Promise<AxiosResponse<any>> {
    query = query ? "?" + query.replace("page=1&items_per_page=30","") : ""
    return axiosInstance.get(`${API.ALL_LAND_REGISTRATION_REQUESTS}${query}`).then((d: AxiosResponse) => d.data)
}
