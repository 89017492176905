import React from 'react'
import ListItem from './ListItem'

type Props = {
  data: any
}



const ListItems = ({ data }: Props) => {
  return (
    <div className='grid grid-cols-4 gap-2'>
      {data && Array.isArray(data) && data.length > 0 ? data.map((d: any) => {
        return <ListItem {...d} />
      }) :
        <div className='col-span-4 bg-gray-50 flex h-[400px] w-full justify-center items-center' >
          <div>
            <div>No Products</div>
          </div>
        </div>}
    </div>

  )
}

export default ListItems