
import React, { useEffect, useState } from "react";
import BannerCurousal from "./BannerCurousal";
import BannerTiles from "./BannerTiles";
type Props = {}

const HeroSection3 = (props: Props) => {
    return <div>
        <BannerCurousal />
        <BannerTiles />
    </div>
}

export default HeroSection3