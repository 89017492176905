import React from 'react'

type Props = {}

const LandOverviewSection = (props: Props) => {

  return (
    <div className="bg-white text-gray-800">
      {/* Header Section */}
      <section className="container mx-auto flex flex-col md:flex-row  px-4 py-8">
        {/* Text Content */}
        <div className="md:w-1/2">
          <h1 className="text-4xl font-bold mb-4">Land Lease For Solar Overview</h1>
          <p className="mb-6 text-sm">
            Maximize the value of your land by leasing it for solar energy projects. Our land lease services allow landowners to generate passive income while contributing to renewable energy initiatives, making unused or underutilized land a profitable and sustainable resource.
          </p>
          <button className="bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded-lg font-bold">
            Enquire Now
          </button>
        </div>
        {/* Image/Video Content */}
        <div className="md:w-1/2 flex justify-end mt-6 md:mt-0">
          {/* <video src='src="https://videos.pexels.com/video-files/4034091/4034091-uhd_2560_1440_30fps.mp4"' className='h-[400px] w-[300px] rounded-lg' ></video> */}
          <video src='https://videos.pexels.com/video-files/4034091/4034091-uhd_2560_1440_30fps.mp4' className='rounded-xl' width="550" height="600" controls >
            <source src="https://videos.pexels.com/video-files/4034091/4034091-uhd_2560_1440_30fps.mp4" type="video/mp4" />
          </video>
        </div>
      </section>
      {/* Key Features Section */}
      <section className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-10">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
            {/* Feature 1 */}
            <div className="p-4">
              <i className="fas fa-hand-holding-usd text-4xl text-orange-500 mb-4"></i>
              <h3 className="text-xl font-bold mb-2">Passive Income Opportunity</h3>
              <p>Leasing your land for solar provides steady income without active management, making it a great long-term opportunity for landowners.</p>
            </div>

            {/* Feature 2 */}
            <div className="p-4">
              <i className="fas fa-leaf text-4xl text-green-500 mb-4"></i>
              <h3 className="text-xl font-bold mb-2">Environmentally Friendly</h3>
              <p>Leasing your land for solar supports clean energy generation, reduces carbon footprints, and promotes sustainability while maximizing property use.</p>
            </div>

            {/* Feature 3 */}
            <div className="p-4">
              <i className="fas fa-wallet text-4xl text-blue-500 mb-4"></i>
              <h3 className="text-xl font-bold mb-2">No Upfront Costs</h3>
              <p>Land lease agreements require no investment from the landowner, as we handle installation and management for a hassle-free experience.</p>
            </div>

            {/* Feature 4 */}
            <div className="p-4">
              <i className="fas fa-file-contract text-4xl text-yellow-500 mb-4"></i>
              <h3 className="text-xl font-bold mb-2">Long-Term Contracts</h3>
              <p>We offer long-term lease agreements, typically ranging from 20 to 30 years, providing financial stability and a secure income for the duration of the lease.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LandOverviewSection