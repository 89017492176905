import React from 'react'
import Accordiation from './Accordiation'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/shadcn/components/ui'
import { IMG_FAQ } from 'src/helpers'
import { FAQ_Data } from '../Component/modules'
import Playbtn from 'src/shadcn/components/custom/Playbtn'

type Props = {}


const CommercialFaqSection = (props: Props) => {
    return (
        <div className='mx-auto  '>
            <div className="  p-4">
                {FAQ_Data.map((d: any) => {
                    return <Accordion type="single" collapsible>
                        <AccordionItem className='border-black border-b-2 border-dotted border-t-0 border-r-0 border-l-0 ' value="item-1">
                            <AccordionTrigger>
                                <div className='flex space-x-2 items-center' >
                                    <Playbtn size='xs' />
                                    <div className='font-bold '>{d.question}</div>
                                </div></AccordionTrigger>
                            <AccordionContent>
                                <div>
                                    {d.answer}
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                })}
            </div>
        </div>
    )
}

export default CommercialFaqSection