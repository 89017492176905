import { AxiosResponse } from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, FormField, successTheme } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { ILandDetails, INITIAL_LAND_ONBOARD_FORM_DATA, LAND_ONBOARD_FORM_FIELDS, landOnboardDataValidationSchema } from './modules'


type Props = {
    initValues: any
    onSubmit: (e: any) => void,
    submittingForm: boolean
}

const LandRegistrationForm = ({ initValues, onSubmit, submittingForm }: Props) => {

    const navigate = useNavigate()

    const formik = useFormik<ILandDetails>({
        initialValues: INITIAL_LAND_ONBOARD_FORM_DATA,
        validationSchema: landOnboardDataValidationSchema,
        onSubmit: (values, { setSubmitting }) => {
            onSubmit(formik.values)
        },
    });

    useEffect(() => {
        if (initValues) {
            formik.resetForm()
            Object.entries(INITIAL_LAND_ONBOARD_FORM_DATA).map(([key, value]) => {
                formik.setFieldValue(key, value)
            })
        }
        return () => { }
    }, [initValues])

    console.log({ error: formik.errors, values: formik.values })

    return (

        <div className='space-y-2 py-4'>
            <form className='space-y-2' onSubmit={formik.handleSubmit}>
                <div className='grid grid-cols-3 gap-4 '>
                    {
                        LAND_ONBOARD_FORM_FIELDS.map((field: FormField) => {
                            return <RenderFormComponents key={`new-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                        })
                    }
                </div>
                <div className='flex justify-center space-x-2 py-4'>
                    <RoundedButton disabled={submittingForm} onClick={() => navigate(-1)} variant={'outline'} type='button' className='w-[150px]'>Cancel</RoundedButton>
                    <RoundedButton disabled={submittingForm} type='submit' className='w-[150px]'>Submit</RoundedButton>
                </div>
            </form>
        </div>

    )
}

export default LandRegistrationForm