import React, { useState } from 'react'
import { cn, IMG_ProductsBanner } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import ListMain from './ListMain'

type Props = {}

const CATEGORIES = [
    "RESIDENTIAL SOLAR SYSTEM",
    "COMMERCIAL SOLAR SYSTEM",
    "INDUSTRIAL OFF-GRID SOLAR SYSTEM",
    "BATTERIES & SOLAR + STORAGE",
    "PHOTOVOLTAIC SOLAR MODULES", "SOLAR COMPONENTS",
    "SOLAR RESOURCES & DESIGN SERVICES",
]

const ProductInformation = (props: Props) => {

    const [category, setcategory] = useState<any>("RESIDENTIAL SOLAR SYSTEM")

    return (
        <div>
            <div className='h-[650px] flex justify-center items-center' style={{ backgroundImage: "url(" + IMG_ProductsBanner + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} >
                <div className='flex flex-col justify-center space-y-4 '>
                    <div className='text-2xl font-bold text-white text-center'>Top Solar Solutions for Every Need</div>
                    <div className='text-sm  text-white text-center max-w-[400px]'>Explore our handpicked selection of high-performance solar equipment. </div>
                    <div className='flex justify-center'> <RoundedButton>Send Request</RoundedButton></div>
                </div>
            </div>
            <div className='grid grid-cols-7 max-w-[80vw] mx-auto '>
                {
                    CATEGORIES.map((d: any, indx: any) => {
                        return <button onClick={() => setcategory(d)}>
                            <div className={cn('flex justify-center items-center p-4 h-full ', { " border-r-2 border-r-black border-dotted": (indx + 1) < CATEGORIES.length })}>
                                <div className='flex flex-col'>
                                    <div className={cn( "text-xs", { ' text-xs font-bold text-primary': category == d })}>{d}</div>
                                </div>
                            </div>
                        </button>
                    })
                }
            </div>
            <div className='text-center text-2xl font-bold my-8 '>{category}</div>
            <ListMain category={category} />
        </div>
    )
}

export default ProductInformation