import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { INITIAL_PRODUCT_VALUES } from 'src/modules/Products/modules'
import ViewProductDetails from 'src/modules/Products/ViewProductDetails'
import { getProductDetailByIDApiRequest } from 'src/services/requests/Products'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const ProductDetailPage = (props: Props) => {
    const [productData, setproductData] = useState(INITIAL_PRODUCT_VALUES)
    const navigate = useNavigate()

    const { id } = useParams()
    const [isLoading, setisLoading] = useState<boolean>(false)

    useEffect(() => {
        if (id) {
            handleFetchPoductDeatilsById()
        }
        return () => { }
    }, [id])


    const handleFetchPoductDeatilsById = catchAsync(async () => {
        setisLoading(true)
        return id && await getProductDetailByIDApiRequest(id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setproductData(result.data)
        }
        setisLoading(false)
    }, () => { setisLoading(false) })


    return (
        <div className='p-4 space-y-4'>
            <div>
                <div className='text-lg uppercase text-primary font-semibold'>Product Details</div>
                <div className='text-xs uppercase text-gray-400 font-semibold'># {id}</div>
            </div>
            <ViewProductDetails data={productData} />
            <div className='flex justify-center p-8 space-x-2'>
                <RoundedButton onClick={() => navigate(-1)} variant={'secondary'}>Back</RoundedButton>
            </div>
            <LoadingDialog isOpen={isLoading} message='Fetching Product details' />
        </div>
    )
}

export default ProductDetailPage