import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/Manufacturer";


export function allManufacturersRequest(query: string): Promise<AxiosResponse<any>> {
    query = query ? "?" + query.replace("page=1&items_per_page=30","") : ""
    return axiosInstance.get(`${API.ALL_MANUFACTURERS}${query}`).then((d: AxiosResponse) => d.data)
}

export function allManufacturerProductsRequest(query: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_MANUFACTURER_PRODUCTS}`).then((d: AxiosResponse) => d.data)
}

export async function createManufacturerProfileApiRequest(formData: any): Promise<AxiosResponse> {
    return axiosInstance.post(`${API.CREATE_MANUFACTURERS}`, formData).then((d: AxiosResponse) => d.data);
}

export async function updateManufacturerProfileByIDApiRequest(formData: any): Promise<AxiosResponse> {
    return axiosInstance.put(`${API.UPDATE_MANUFACTURERS}`, formData).then((d: AxiosResponse) => d.data);
}


export async function getDetailsManufacturerProfileByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.GET_DETAILS_MANUFACTURERS_BUY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}


export async function manufacturerAddServicesApiRequest(manufacturer_id: string, formData: any): Promise<AxiosResponse> {
    return axiosInstance.put(`${API.MANUFACTURER_BASE_URL}/${manufacturer_id}/products/add`, formData).then((d: AxiosResponse) => d.data);
}


export async function manufacturerRemoveServicesApiRequest(manufacturer_id: string, product_id: any): Promise<AxiosResponse> {
    return axiosInstance.put(`${API.MANUFACTURER_BASE_URL}/${manufacturer_id}/products/remove/${product_id}`, {}).then((d: AxiosResponse) => d.data);

}

export async function manufacturerCreateEnquiryApiRequest(formdata: any): Promise<AxiosResponse> {
    return axiosInstance.post(`${API.ENQUIRY_REQUEST}`, { ...formdata }).then((d: AxiosResponse) => d.data);

}


