import React from 'react'
import { cn, IMG_SERVICES_BANNER_2, toAbsoluteUrl } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

import HtmlCodeRender from 'src/shadcn/components/custom/HtmlCodeRender'
import { Button } from 'src/shadcn/components/ui'
import { useNavigate } from 'react-router-dom'
import { COMMERCIAL_SERVICES, RESIDENTIAL_SERVICES } from './Component/modules'
import SolarServicesEnquiryNow from './Component/SolarServicesEnquiryNow/SolarServicesEnquiryNow'

type Props = {}

const RecidentialServices = (props: Props) => {

  const navigate = useNavigate()

  return (
    <div>
      {/* <div className='h-[500px] flex justify-center items-center' style={{ backgroundImage: 'url(' + IMG_SERVICES_BANNER_2 + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }} >
        <div className='flex flex-col justify-center space-y-2'>
          <div className='font-bold text-3xl text-white '>Residential Innovative solar solutions</div>
          <div className='text-center text-white'>Transforming the future of energy</div>
          <div className='flex justify-center'> <RoundedButton>Send Request</RoundedButton></div>
        </div>
      </div>
      <div className='max-w-[80vw] mx-auto mt-[32px] mb-[300px]'>
        <div className='text-[#454545] font-sans text-3xl'>
          <div className='font-normal text-center'>Transforming Sunshine into Savings: </div>
          <div className='text-center font-bold'>Explore Our Solar Services</div>
        </div>
        <div className="grid grid-cols-3 gap-8 my-8 ">
          {RESIDENTIAL_SERVICES?.map(
            ({ btnName, description, image, link, title }, index) => (
              <button onClick={() => navigate("/" + link)}>
                <div className='h-[400px] flex items-end relative rounded-3xl transition duration-300 ease-in-out hover:scale-[1.05]  ' style={{ backgroundImage: 'url(' + image + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }} >
                  <div className='absolute bottom-0 right-0 left-0 bg-gray-700  rounded-3xl'>
                    <div className=' relative'>
                      <div className='flex justify-between -mt-[30px]'>
                        <div className="w-[245px] h-[58px] clip-right-50 border bg-primary">
                          <div className="w-[245px] h-[58px] clip-right-45 border bg-white font-bold text-[16px] flex items-center px-5">
                            {title}
                          </div>
                        </div>
                      
                      </div>
                    </div>
                    <div className=" py-4 px-0 w-full rounded-[30px] space-y-4 ">
                      <p className="text-left flex text-sm font-normal text-white  ml-5">
                        <HtmlCodeRender htmlString={description} />
                      </p>
                      <div className="">
                     
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            )
          )}
        </div>
      </div>
      <SolarServicesEnquiryNow /> */}
      <div className='h-[500px] w-full flex justify-center items-center bg-primary-foreground '>
          <span className='text-4xl font-bold text-gray-400'>Coming Soon</span>
      </div>
    </div>
  )
}
export default RecidentialServices