import dayjs from 'dayjs'
import { FaPhoneAlt } from "react-icons/fa"
import { Rating } from '@smastrom/react-rating'
import { useNavigate } from 'react-router-dom'
import { checkDecimal, ICON_OCT_VERIFIED, IMG_EPC_COMPANY_PLACEHOLDER_LOGO, PROJECT_IMAGE_URL } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Button, Separator } from 'src/shadcn/components/ui'
import { ImageOff } from 'lucide-react'


export interface EpcItemData {

}


type Props = {
  data: any
}

const ListItem = (data: any) => {

  const navigate = useNavigate()

  return (
    <div className='grid gap-2 rounded-xl overflow-hidden border p-4 '>
      <div className='bg-gray-200 h-full w-full row-span-2 relative'>
        {
          data?.imgUrl ? <img src={data?.imgUrl} style={{ height: "250px", width: "100%" }} /> : <div className='h-[250px] w-100 flex justify-center items-center '>
            <div className='flex flex-col items-center space-y-2'>
              <ImageOff className='text-gray-400' size={24} />
              <span className='text-xs'>No Content</span>
            </div>
          </div>
        }
        <div className='flex justify-between items-end mb-2 absolute bottom-0 w-full px-2'>
          <div className='uppercase text-xs '># dsdasd2131</div>
          <div>
            <div className='flex items-center  space-x-1 bg-green-50 p-1 border border-green-500 rounded-full '>
              <img src={ICON_OCT_VERIFIED} style={{ height: "20px" }} />
              <span className='text-xs'>OCT Verified</span>
            </div>
          </div>
        </div>
      </div>

      <div className='h-full w-full py-4 px-2 '>
        <div className='flex flex-col mb-2 '>
          <div className='flex justify-between  mb-2'>
            <div>
              <div className='text-gray-400 text-sm'>Investment</div>
              <div className='font-bold text-sm'>₹ {checkDecimal(data?.totalInvestmentInProject, true)}</div>
            </div>
            <div>
              <div className='text-gray-400 text-sm text-end'>Price</div>
              <div className='font-bold text-sm text-end'>₹ {checkDecimal(data?.expectedSellingPriceForProjectRights, true)}</div>
            </div>
          </div>
          <div className='flex justify-between  mb-2'>
            <div>
              <div className='text-gray-400 text-sm'>Energy Production</div>
              <div className='font-bold text-sm'>{checkDecimal(data?.annualEnergyProduction, true)} mW/Year</div>
            </div>
            <div>
              <div className='text-gray-400 text-sm text-end'>Installed Capacity</div>
              <div className='font-bold text-sm text-end'>{checkDecimal(data?.totalInstalledCapacity, true)} kW</div>
            </div>
          </div>
          <div className='flex justify-between  mb-2'>
            <div>
              <div className='text-gray-400 text-xs'>city</div>
              <div className='font-bold text-sm text-end'>{data?.projectCity} </div>
            </div>
          </div>
        </div>
        <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.project_rights_detail + "/" + data?._id)} className='w-full'>See more</Button>
      </div>
      {/* <div className='bg-gray-400 h-full w-full'></div> */}
    </div>
  )
}

export default ListItem