import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ENQUIRY_FORM_3_FIELDS, enquiryForm3Schema, IEnquiryForm3, initialEnquiryForm3 } from './modules'
import { catchAsync, successTheme } from 'src/helpers'
import { createExistingProjectEnquiryRequestRequestApi } from 'src/services/requests/Projects'
import { AxiosResponse } from 'axios'
import { toast } from 'sonner'
import { ENQUIRY_FORM_1_FIELDS } from '../EnquiryForm1/modules'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { createServiceEnquiryRequestRequestApi } from 'src/services/requests/ServiceEnquiryRequests'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'

type Props = {
    initValues?: {}
}

const EnquiryForm3 = ({ initValues }: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const navigate = useNavigate()

    const formik = useFormik<IEnquiryForm3>({
        initialValues: initialEnquiryForm3,
        validationSchema: enquiryForm3Schema,
        onSubmit: (values, { setSubmitting }) => {
            // Handle form submission here
            handleEnquiryForm()
        },
    });


    useEffect(() => {
        if (initValues) {
            Object.keys(initialEnquiryForm3).map((d: any) => {
                formik.setFieldValue(d, initValues[d as keyof Object])
            })
        }
        return () => { }
    }, [initValues])



    const handleEnquiryForm = catchAsync(
        async () => {
            setisLoading(true)
            return await createServiceEnquiryRequestRequestApi(formik.values);
        },
        (result: AxiosResponse) => {
            if (result.status == 201) {
                toast.success("Request sent successful", { style: successTheme })
                formik.resetForm()
                // navigate(-1)
                setShowDialog(true)
            }
            setisLoading(false)

        },
        () => {
            setisLoading(false)
        }
    );

    console.log({ error: formik.errors, values: formik.values })

    return (
        <div className='space-y-2 '>
            <form className='space-y-2' onSubmit={formik.handleSubmit}>
                <div className='grid grid-cols-2 gap-4 '>
                    {
                        ENQUIRY_FORM_3_FIELDS.map((field: any) => {
                            return <RenderFormComponents key={`existing-enquiry-form-${field.dataKey}`} formik={formik} value={formik.values[field.dataKey as keyof object]} {...field} onChange={(e: any) => formik.setFieldValue(field.dataKey, e)} />
                        })
                    }
                </div>
                <div className="w-full flex justify-center items-center">
                    <RoundedButton
                        isLoading={isLoading}
                        disabled={false}
                        type="submit"
                        className="w-[177px] h-[45px] bg-black text-white hover:bg-white hover:text-black rounded-full mt-4"
                    >
                        Enquiry Now {">>"}
                    </RoundedButton>
                </div>
            </form>
            <CustomeDialog isOpen={showDialog} headerTitle={"Request Submited"} hideDefaultCancle onSubmit={() => { setShowDialog(false) }} >
                <p className='pb-4'>Thank You for showing your interest, our team will contact you soon.</p>
            </CustomeDialog>
        </div>
    )
}

export default EnquiryForm3