import React, { useState } from 'react'
import { CONTACT_US_FORM_FIELDS, CONTACT_US_INIT, contactUsFormSchema } from './modukes'
import { catchAsync, checkAnyFileToBeUpload, FormField } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { useFormik } from 'formik'
import { submitContactUsFormsRequestApi } from 'src/services/requests/contactUs'
import { IContractUsFromRequestBody } from 'src/services/requests/contactUs/modules'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { toast } from 'sonner'

type Props = {}

const ContactusForm = (props: Props) => {

    const [isLoading, setisLoading] = useState(false)

    const formik = useFormik({
        initialValues: CONTACT_US_INIT,
        validationSchema: contactUsFormSchema,
        onSubmit(values, formikHelpers) {
            handleFileUploads()
        },
    })

    const handleSubmitForm = catchAsync(async (data: IContractUsFromRequestBody) => {
        setisLoading(true)
        return await submitContactUsFormsRequestApi(data)
    }, (result: any) => {
        if (result.status == 201) {
            setisLoading(false)
            toast.success("Request submited..")
            formik.resetForm()
        }
    }, () => { setisLoading(false) })

    const handleFileUploads = catchAsync(async () => {
        setisLoading(true)
        return await checkAnyFileToBeUpload(formik.values)
    }, (result: any) => {
        console.log({ result });
        if (result[1]) {
            handleSubmitForm({ ...result[0] })
            console.log({ result: { ...result[0] } });
        }
    }, () => { })


    return (
        <div className='col-span-2 space-y-2'>
            <form onSubmit={formik.handleSubmit} className='space-y-4' >
                <div className='grid grid-cols-2 gap-4'>
                    {CONTACT_US_FORM_FIELDS.map((filed: FormField) => {
                        return <RenderFormComponents formik={formik} {...filed} value={formik.values[filed.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(filed.dataKey, e)} />
                    })}
                </div>
                <div className='flex justify-center'><RoundedButton className='w-[200px]  ' isLoading={isLoading} >Submit</RoundedButton></div>
            </form>
        </div>
    )
}

export default ContactusForm