import { Check } from 'lucide-react'
import React from 'react'
import { IMG_CROWDFUNDING_BANNER } from 'src/helpers'
import Playbtn from 'src/shadcn/components/custom/Playbtn'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/shadcn/components/ui'

type Props = {}

const CareerPage = (props: Props) => {
  return (
    <div>
      <div className='w-full h-[500px] overflow-hidden' style={{ backgroundImage: "url(" + IMG_CROWDFUNDING_BANNER + ")", backgroundSize: "cover" }} >
        <div className=' flex flex-col justify-center h-[100%] space-y-8'>
          <div className='flex justify-start ml-24 '>
            <div className='p-4 bg-primary text-white text-2xl font-bold' >We create sustainable value.. with your help! </div>
          </div>
          <div className='flex justify-end'>
            <div className=' space-y-2 bg-white p-4 w-[70vw]'>
              <div className='font-bold'>What does it mean to work at Orange Current?</div>
              <div>
                <div className='flex items-center space-x-2 '>
                  <span><Check size={16} /></span><span>A meaningful job with real added value</span>
                </div>
                <div className='flex items-center space-x-2 '>
                  <span><Check size={16} /></span><span>Friendly, yet dynamic working atmosphere</span>
                </div>
                <div className='flex items-center space-x-2 '>
                  <span><Check size={16} /></span><span>Clear responsibilities and individual development paths</span>
                </div>
                <div className='flex items-center space-x-2 '>
                  <span><Check size={16} /></span><span>Plenty of room for your own ideas with an open feedback culture</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='my-[50px]'>
        <div className='text-4xl text-center'>
          A <span className='font-bold'>team</span> that works <span className='font-bold'>together</span> out of <br /><span className='font-bold'>passion and with joy</span>
        </div>
      </div>
      <div className='w-[50vw] mx-auto'>
        Our dedicated team is passionate about contributing to the global energy transition. If you share same vision and are excited to join a dynamic startup environment, you’ve found the right place! We are hiring immediately in Mumbai, Delhi, Bangalore/ Hyderabad, and Kolkata.
      </div>
      <div className=' w-[80vw] mx-auto my-[50px]'>
        <Accordion value='item-1'  type="single" collapsible>
          <AccordionItem className=' ' value="item-1">
            <AccordionTrigger className='border-b-2 border-dotted border-black '>
              <div className='flex items-center space-x-2'>
                <span><Playbtn /></span>
                <span className='font-bold uppercase text-lg'>Chief Transactions officer (CTO -Solar) Renewable Energy</span>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className='space-y-4 my-4'>
                <div>Our dedicated team is passionate about contributing to the global energy transition. If you share same vision and are excited to join a dynamic startup environment, you’ve found the right place! We are hiring immediately in Mumbai, Delhi, Bangalore/ Hyderabad, and Kolkata.</div>
                <div className='text-primary font-bold'>Apply now as a Chief Transactions officer (CTO -Solar) Renewable Energy</div>
                <div>At Orange Current Technologies, we’re making sustainable investments in renewable energy accessible to everyone. Our platform serves as a <span className='font-bold'>“hub for independent investors and commercial solar plant operators”, providing a “digital home” for their projects and investments</span>.</div>
                <div>To strengthen our team and help communicate our services to customers across India, we are looking for passionate individuals to join us. Apply now!</div>
                <div>
                  <div className='font-bold'>Project Sales & Development:</div>
                  <div className='p-4'>
                    <ul className='list-disc'>
                      <li>Manage a network of project stakeholders like Solar Project developers, Land/Rooftop Owners, contractors, Investors, C&I customers, and utilities to drive the sale of solar projects at various stages.</li>
                      <li>Identify and secure new business opportunities from solar project developers across commercial, industrial, and institutional sectors.</li>
                      <li>Manage the entire project sales cycle from lead generation, negotiations to closing the deals.</li>
                      <li>Conduct due diligence of the project to provide accurate project information to prospective C&I buyers, including regulatory requirements and quality standards.</li>
                      <li>Assist buyers in closing deals by providing technical, financial, and strategic support throughout the proposal and negotiation phases.</li>
                      <li>Resolve any issues or concerns faced by clients during the project lifecycle, ensuring timely and satisfactory solutions.</li>
                      <li>Foster long-term relationships with clients and encourage repeat business and referrals.</li>
                      <li>Work closely with IT platform development teams to customize available solar services that meet the specific needs of clients.</li>
                      <li>Expand the company’s presence in new markets and industries through effective channel partnerships and project sales strategies.</li>
                      <li>Establish and nurture relationships with key stakeholders in the renewable energy industry, including investors, government bodies, and energy utilities.</li>
                      <li>Identify trends, market opportunities, and competitor activities to stay ahead in the renewable energy space.</li>
                      <li>Track sales progress and KPIs related to project sales and channel partner performance.</li>
                      <li>Provide regular updates and sales reports to senior management, ensuring alignment with overall business goals.</li>
                      <li>Analyze sales data to optimize strategies and improve overall sales performance.</li>
                      <li>Ensure a high level of customer satisfaction by delivering on promises, maintaining clear communication, and offering continued support during and after project implementation.</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div className='font-bold'>Qualifications:</div>
                  <div className='p-4'>
                    <ul className='list-disc'>
                      <li>Bachelor’s degree in Business, Engineering, Renewable Energy, or a related field.</li>
                      <li>Proven experience in sales or business development in the renewable energy sector (solar experience preferred).</li>
                      <li>Strong understanding of renewable energy technologies, especially large-scale solar projects, and the project sales cycle.</li>
                      <li>Excellent communication, negotiation, and presentation skills.</li>
                      <li>Ability to build and manage relationships with channel partners, contractors, and clients.</li>
                      <li>Strong project management skills with the ability to oversee complex sales and project implementation processes.</li>
                      <li>Self-driven, goal-oriented, and able to work independently in a fast-paced, results-focused environment.</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div className='font-bold'>What We Offer:</div>
                  <div className='p-4'>
                    <ul className='list-disc'>
                      <li>Work in one of the most exciting and fastest-growing markets around sustainability and follow the daily developments and trends in the Solar industry.</li>
                      <li>Opportunity to implement your own ideas as part of India’s first online platform for solar project transactions.</li>
                      <li>Fast decision-making processes through flat hierarchies and direct reporting to the CEO.</li>
                      <li>In addition to your fixed salary, you will receive an attractive and performance-related bonus.</li>
                      <li>Access to a robust portfolio of renewable energy projects and solutions.</li>
                      <li>A dynamic and supportive startup environment where innovation and growth are encouraged.</li>
                      <li>Competitive commission-based structure with performance incentives.</li>
                      <li>You will experience a good work-life balance with 30 days of holiday per year.</li>
                      <li>Cordial working atmosphere in an exciting environment, committed to a common vision.</li>
                    </ul>
                  </div>
                </div>
                <div>If you are passionate about renewable energy projects and have a track record in sales and project development, apply now to <span className='text-primary'>join Orange Current Technologies as a CTO-Solar  Project Sales</span></div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}

export default CareerPage