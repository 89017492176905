import React, { useEffect, useState } from 'react'
import ProductItemSm from './ProductItemSm'
import { Badge } from 'src/shadcn/components/ui'
import { BADGE_STATUS_VARIANT, catchAsync, checkDecimal, IS_SUCCESS_RESPONSE, ORDER_STATUS_ENUM } from 'src/helpers'
import { fetchOrderDetailsByOrderIDApiRequest, fetchOrdersByUserIDApiRequest } from 'src/services/requests/Order'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import dayjs from 'dayjs'

type Props = {}

const OrderDetail = (orderDetails: any) => {

    return (
        <div className='p-4 space-y-4'>
            <div className='text-primary uppercase font-semibold'>Order Details</div>
            <div className='grid grid-cols-4 gap-4'>
                <div className='col-span-3 space-y-2 p-4 border '>
                    <div className='uppercase'># asds213131231231</div>
                    <div className='space-y-2'>
                        <div className='text-sm text-gray-400'>Delivery Address</div>
                        <div className='text-xs'>
                            {orderDetails?.address1} {orderDetails?.address2}, {orderDetails?.city}, {orderDetails?.state}, {orderDetails?.pincode}
                        </div>
                    </div>
                    <div className='space-y-2'>
                        <div className='text-sm text-gray-400'>Products</div>
                        <div className='grid grid-cols-2 gap-2' >
                            {orderDetails?.products && Array.isArray(orderDetails?.products) && orderDetails?.products.length > 0 ? orderDetails?.products.map((p: any) => {
                                return <ProductItemSm {...p} />
                            }) : null}
                        </div>
                    </div>
                </div>
                <div className='bg-gray-100 flex flex-col space-y-4 py-8 px-4'>
                    <div className='text-lg font-semibold'>
                        <div className='flex justify-between'><span>TOTAL</span> <span>{orderDetails?.totalCost ? checkDecimal(orderDetails?.totalCost, true) : null}</span></div>
                    </div>
                    <div className='space-y-2  flex justify-between items-center text-sm '>
                        <div className=''>Order Date</div>
                        <div>{orderDetails?.createdAt ? dayjs(orderDetails?.createdAt).format("YYYY") : null}</div>
                    </div>
                    <div className='space-y-2  flex justify-between items-center '>
                        <div className='text-sm'>Order Status</div>
                        {orderDetails?.orderStatus ? <Badge className='uppercase' variant={BADGE_STATUS_VARIANT[orderDetails?.orderStatus as keyof object]}>{orderDetails?.orderStatus}</Badge> : null}
                    </div>
                    <div className='space-y-2'>
                        <div className='text-sm text-gray-400'>Payment details</div>
                        <div className='space-y-1 text-sm ' >
                            <div className='flex justify-between'><span>Payment Mode</span> <span>{orderDetails?.paymentMode}</span></div>
                            <div className='flex justify-between' ><span>Card Number</span> <span>{orderDetails?.cardNumber}</span></div>
                            <div className='flex justify-between' ><span>Transaction ID</span> <span>XXXXXXXXXXXXX</span></div>
                            <div className='flex justify-between' ><span>Payment Date</span><span>{orderDetails?.createdAt ? dayjs(orderDetails?.createdAt).format("YYYY") : null}</span></div>
                            <div className='flex justify-between'><span>Payment Status</span> {orderDetails?.paymentStatus ? <Badge className='uppercase' variant={BADGE_STATUS_VARIANT[orderDetails?.paymentStatus as keyof object]}>{orderDetails?.paymentStatus}</Badge> : null}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OrderDetail