import React from 'react'
import { IMG_P2P_ENERGY_HOW_IT_WORKS, IMG_P2P_ENERGY_OUR_PRODUCTS } from 'src/helpers'

type Props = {}

const P2pEnergyHowItWorks = (props: Props) => {
    return (
        <div className='h-[600px] p-8'  >
            <div className='max-w-[80vw] mx-auto'>
                <div className='flex flex-col justify-center items-center space-y-2'>
                    <span className='text-sm  uppercase'>How Our P2P trading Works</span>
                    <span className='h-[2px] bg-primary w-[50px]'></span>
                    <span className='font-bold text-3xl'>How it works?</span>
                </div>
                <div className='flex justify-center items-center mt-12 '>
                    <img src={IMG_P2P_ENERGY_HOW_IT_WORKS} style={{height:"300px"}} />
                </div>
            </div>
        </div>
    )
}

export default P2pEnergyHowItWorks