import Autoplay from "embla-carousel-autoplay";
import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cn } from "src/helpers";
import { useCusrousal } from "src/modules/Welcome/useCusrousal";
import { getTempDataRootState, resetSetBannerIndex, setSelectedBannerIndex } from "src/redux/reducers/tempDataSlice";
import { RootState } from "src/redux/store";
import {
    Carousel,
    CarouselApi,
    CarouselContent
} from "src/shadcn/components/ui";
import Banner1 from "./Banners/Banner1";
import Banner2 from "./Banners/Banner2";
import Banner3 from "./Banners/Banner3";
import Banner5 from "./Banners/Banner5";
import Banner4 from "./Banners/Banner4";


type Props = {}

const BannerCurousal = (props: Props) => {

    const navigation = useNavigate();

    const [setApi, setSetApi] = useState<CarouselApi>();
    const [current, setCurrent] = React.useState(0);
    const dispatch = useDispatch()
    const { selectedIndex, scrollSnaps, onDotButtonClick } = useCusrousal(setApi);
    const { selectBannerIndex, setBannerIndex } = useSelector((state: RootState) => getTempDataRootState(state))
    const plugin = React.useRef(
        Autoplay({ delay: 4000, stopOnInteraction: true })
    );


    useEffect(() => {
        dispatch(setSelectedBannerIndex(selectedIndex))
        return () => { }
    }, [selectedIndex])



    useEffect(() => {
        if (setBannerIndex !== undefined) {
            // plugin.current.stop();
            setApi && setApi?.scrollTo(Number(setBannerIndex));
            plugin.current.reset()
            dispatch(resetSetBannerIndex())
            // plugin.current.play();
        }
        return () => { }
    }, [setApi, plugin, setBannerIndex])



    const handleMouseEnter = () => {
        plugin.current.stop();
    };

    const handleMouseLeave = () => {
        plugin.current.play();
    };

    const handleNext = () => {
        if (setApi) {
            setApi.scrollNext();
        }
    };


    const handlePrev = () => {
        if (setApi) {
            setApi.scrollPrev();
        }
    };

    useEffect(() => {
        if (!setApi) {
            return;
        }
        setCurrent(setApi.selectedScrollSnap() + 1);
        setApi.on("select", () => {
            setCurrent(setApi.selectedScrollSnap() + 1);
        });
    }, [setApi]);


    return (
        <div className="w-full relative">
            <Carousel
                setApi={setSetApi}
                plugins={[plugin.current]}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="w-full"
            >
                <CarouselContent>
                    <Banner2 />
                    <Banner1 />
                    <Banner4 />
                    <Banner3 />
                    <Banner5 />
                </CarouselContent>
                {/* {
                    selectedIndex !== 0 ? <MdOutlineKeyboardArrowLeft size={64}
                        onClick={handlePrev}
                        className={cn(
                            "absolute cursor-pointer rounded-full hover:text-primary",
                            "left-3 top-[40%] -translate-y-[40%]"
                        )} /> : null
                }
                {
                    selectedIndex < 5 ? <MdOutlineKeyboardArrowRight size={64}
                        onClick={handleNext}
                        className={cn(
                            "absolute   cursor-pointer rounded-full hover:text-primary",
                            "right-3 top-[40%] -translate-y-[40%]"
                        )} /> : null
                } */}
            </Carousel>
        </div>
    );
}

export default BannerCurousal