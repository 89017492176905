import { useEffect, useMemo } from 'react'
import { stringifyObjectToRequestQuery } from 'src/helpers'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import FilterMain from './FilterMain'
import ListItemLoader from './ListItemLoader'
import ListItems from './ListItems'

type Props = {
    category: string
}

const Main = ({ category }: Props) => {

    const aopiResp = useQueryResponseData()
    const { refetch } = useQueryResponse()
    const { state, updateState } = useQueryRequest()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => aopiResp, [aopiResp])

    useEffect(() => {
        if (category) {
            updateState({
                filter: {
                    category
                }
            })
        }
        return () => { }
    }, [category])


    return (


        <div className='w-full'>
            {isLoading ? <div className='grid grid-cols-3 gap-6'> {[...new Array(3)].map((s: any) => {
                return <ListItemLoader />
            })}</div> : <ListItems data={data} />}
        </div>


    )
}


const ListMain = ({ category }: Props) => {
    return <QueryRequestProvider>
        <QueryResponseProvider>
            <Main category={category} />
        </QueryResponseProvider>
    </QueryRequestProvider>
}

export default ListMain