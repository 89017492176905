import { useFormik } from 'formik'
import React, { useState } from 'react'
import { EPS_CONTACT_DETAILS_FORM_FIELDS, EPS_CONTACT_DETAILS_FORM_INIT, EPS_CONTACT_DETAILS_FORM_Schema, IEpsDetailContactForm } from './modules'
import { catchAsync, FormField } from 'src/helpers'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { epcContractorEnquiryRequestApiRequest } from 'src/services/requests/EpcContractors'
import { toast } from 'sonner'

type Props = {
    epc_id: any
}

const EmpDetailsContactForm = ({ epc_id }: Props) => {

    const [isLoading, setisLoading] = useState(false)

    const formik = useFormik({
        initialValues: EPS_CONTACT_DETAILS_FORM_INIT,
        validationSchema: EPS_CONTACT_DETAILS_FORM_Schema,
        onSubmit(values, formikHelpers) {
            handleSubmitForm()
        },
    })

    const handleSubmitForm = catchAsync(async (data: IEpsDetailContactForm) => {
        setisLoading(true)
        return await epcContractorEnquiryRequestApiRequest({
            epc_contractor_id:epc_id,
            ...data
        })
    }, (result: any) => {
        if (result.status == 201) {
            setisLoading(false)
            toast.success("Request submited..")
            formik.resetForm()
        }
    }, () => { setisLoading(false) })


    return (
        <div className='col-span-2 space-y-2'>
            <form onSubmit={formik.handleSubmit} className='space-y-4' >
                <div className='grid grid-cols-1 gap-1'>
                    {EPS_CONTACT_DETAILS_FORM_FIELDS.map((filed: FormField) => {
                        return <RenderFormComponents formik={formik} {...filed} value={formik.values[filed.dataKey as keyof object]} onChange={(e: any) => formik.setFieldValue(filed.dataKey, e)} />
                    })}
                </div>
                <div className='flex justify-center'><RoundedButton className='w-[200px] rounded-full ' isLoading={isLoading} >Submit</RoundedButton></div>
            </form>
        </div>
    )
}

export default EmpDetailsContactForm