import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/Carts";


export function fetchCartItemsByUserIDApiRequest(id: string): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_FETCH_CART_ITEMS_BY_USER_ID}/${id}`).then((d: AxiosResponse) => d.data);
}

export function addItemToCartApiRequest(data: any): Promise<AxiosResponse> {
    return axiosInstance.post(`${API.URL_CREATE_CART}`, { ...data }).then((d: AxiosResponse) => d.data);
}


export function removeItemFromCartApiRequest(cart_id: any): Promise<AxiosResponse> {
    return axiosInstance.put(`${API.URL_REMOVE_CART}/${cart_id}`).then((d: AxiosResponse) => d.data);
}


export function updateCartItemsQuantityApiRequest(cart_id: string, data: any): Promise<AxiosResponse> {
    return axiosInstance.put(`${API.URL_UPDATE_CART_QUANTITY}/${cart_id}`, { ...data }).then((d: AxiosResponse) => d.data);
}
