import React, { useEffect, useMemo, useState } from 'react'
import FilterMain from './FilterMain'
import EpcItems from './EpcItems'
import { catchAsync, stringifyObjectToRequestQuery } from 'src/helpers'
import { epcAllContractorsApiRequest } from 'src/services/requests/EpcContractors'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import ListItemLoader from './ListItemLoader'

type Props = {}

const Main = (props: Props) => {

    const aopiResp = useQueryResponseData()
    const { refetch } = useQueryResponse()
    const { state, updateState } = useQueryRequest()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => aopiResp, [aopiResp])


    return (

        <div className='flex space-x-2'>
        <div className='w-1/4 '>
            <div className='p-4 bg-orange-100 rounded-lg'>
                <FilterMain onChange={(e: any) => {
                    if (stringifyObjectToRequestQuery(state.filter) != stringifyObjectToRequestQuery(e)) {
                        updateState({
                            filter: e
                        })
                    }
                    else refetch()
                }} />
            </div>
        </div>
        <div className='w-3/4 '>
            {isLoading ? <div className='grid grid-cols-2 gap-4'> {[...new Array(5)].map((s: any) => {
                return <ListItemLoader />
            })}</div> : <EpcItems data={data} />}
        </div>
    </div>

        // <div className='flex space-x-2'>
        //     <div className='w-1/4 '>
        //         <div className='p-4 bg-orange-100 rounded-lg'>
        //             <FilterMain onChange={(e: any) => { console.log(e) }} />
        //         </div>
        //     </div>
        //     <div className='w-3/4  '>
        //         <EpcItems data={data} />
        //     </div>
        // </div>

    )
}


const EpcMain = () => {
    return <QueryRequestProvider>
        <QueryResponseProvider>
            <Main />
        </QueryResponseProvider>
    </QueryRequestProvider>
}

export default EpcMain