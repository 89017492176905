import React from 'react' 
import RoofTopSolarDetails from 'src/modules/SubServices/RoofTopSolar/RoofTopSolarDetails' 
 
type Props = {} 
 
const RoofTopSolarPage = (props: Props) => { 
    return ( 
        <div> 
            <RoofTopSolarDetails /> 
        </div> 
    ) 
} 
 
export default RoofTopSolarPage 
