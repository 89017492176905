import React, { useEffect } from 'react'

import { Outlet, useLocation } from 'react-router-dom'

import { cn } from 'src/helpers'
import { MAIN_ROUTES_CONST, RESTRICT_LAYOUT_PATH } from 'src/navigations/modules'
import { useAuth } from 'src/modules/Auth/Core'
import HeaderMain_R from './Header/HeaderMain_R'
import FooterMain from '../Default/Footer/FooterMain'
import FooterMain_R from './Footer/FooterMain_R'
import MainRoutes from 'src/navigations'

type Props = {}

const ReStructureLayoutMain = (props: Props) => {

    const { pathname } = useLocation()
    const { currentUser } = useAuth()
    const PATHS = [...RESTRICT_LAYOUT_PATH].map((d: any) => "/" + d)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            <HeaderMain_R />
            <div className={cn('mt-[80px]', { " xl:max-w-[80vw] lg:[90vw]  mx-auto": PATHS.includes(pathname) || PATHS.some((path: any) => pathname.includes(path)), "pb-[100px]": ![MAIN_ROUTES_CONST.welcome, MAIN_ROUTES_CONST.commercial_services, MAIN_ROUTES_CONST.residential_services].map((d: any) => "/" + d).includes(pathname) })}>
                <Outlet />
            </div>
            <FooterMain_R />
            {/* <FooterMain/> */}
        </div>
    )
}

export default ReStructureLayoutMain