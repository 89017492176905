import React from 'react'
import ReferalForm from './ReferalForm'
import { AMBASSADORS, HOW_IT_WORKS, REWARD_BASE_PRICES_DATA, TESTIMONIALS } from './modules'
import { toAbsoluteUrl } from 'src/helpers'

type Props = {}

const ReferAndEarnDetails = (props: Props) => {
    return (
        <div className=' space-y-16'>
            {/* Heading Section */}
            <div className='py-8 max-w-[80vw] mx-auto'>
                <div className='text-center font-bold'>Earn Rewards by Referring Friends and Family!</div>
                <div className='text-center'>Help your loved ones save on electricity bills and earn rewards by referring them to install solar panels with us.</div>
            </div>
            <div className='grid grid-cols-3 gap-4 max-w-[80vw] mx-auto'>

                <div className=''>
                    <ReferalForm />
                </div>

                <div className='col-span-2 flex justify-center items-center'>
                    <div className='space-y-4'>
                        <div className='flex justify-between space-x-2'>
                            <div>
                                <div>Transform Connections into Commissions </div>
                                <div>WITH</div>
                                <div><span className='font-bold'>Orange Current technologies</span> Market Place Program</div>
                            </div>
                            <div>
                                <img src={toAbsoluteUrl("/media/png/refer_and_earn2.png")} style={{ height: "80px" }} />
                            </div>
                        </div>
                        <div>
                            <table className='w-[50vw]'>
                                <thead>
                                    <tr className='bg-gray-200  '>
                                        <th colSpan={2} className='text-center py-2'>Referral Rewards Based on Solar Installation Size</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {REWARD_BASE_PRICES_DATA.map((d: any) => {
                                        return <tr>
                                            <td className='border p-2 text-center'>{d?.size}</td>
                                            <td className='border p-2 text-center'>{d?.reward}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* How it works */}
            <div className='max-w-[80vw] mx-auto'>
                <div className='text-xl font-bold text-center my-4'>How it Works ?</div>
                <div>
                    <div>
                        <div className='w-full flex justify-center pb-8'><img src={toAbsoluteUrl("/media/png/bulb.png")} style={{ height: "64px" }} /></div>
                        <div className='border-b border-dotted border-black'></div>
                        <div className='grid grid-cols-3 gap-24 px-2'>
                            {
                                HOW_IT_WORKS.map(({ header, title, image, description }: any) => {
                                    return <div className='relative'>
                                        <div className='absolute -top-[20px] left-0 right-0 flex justify-center w-full'>
                                            <img src={toAbsoluteUrl("/media/png/sun.png")} style={{ height: "40px" }} />
                                        </div>
                                        <div className=' flex justify-center items-center border border-black rounded-2xl p-8 mt-8'>
                                            <div className='flex flex-col items-center space-y-4'>
                                                <div className='text-xs'>{header}</div>
                                                <div className='font-bold'>{title}</div>
                                                <div><img src={image} style={{ height: "80px" }} /></div>
                                                <div className='text-xs text-center'>{description}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* Why ? */}
            <div>
                <div className='text-xl font-bold text-center my-4'>Why Become a Referral Ambassdor? </div>
                <div className="grid grid-cols-3 gap-32 text-center bg-gray-200 p-8">
                    {/* Feature 1 */}
                    {
                        AMBASSADORS.map(({ header, image, description }: any) => {
                            return <div className="p-4 space-y-1 flex flex-col items-center">
                                <div className='h-[80px] flex justify-center items-center w-full'>
                                    <img src={image} style={{ height: "50px" }} />
                                </div>
                                <h3 className="text-xl font-bold mb-2">{header}</h3>
                                <p className='text-sm'>{description}</p>
                            </div>
                        })
                    }

                </div>
            </div>
            {/* Customer Says */}
            <div className='max-w-[80vw] mx-auto'>
                <div className='text-xl font-bold text-center my-4'>Meet Our Referral Ambassadors</div>
                <div className="grid grid-cols-3 gap-16 text-center py-[50px] ">
                    {/* Feature 1 */}
                    {
                        TESTIMONIALS.map(({ image, name, description, city, state, total_referals, rating }: any) => {
                            return <div className='  relative  border border-black rounded-2xl px-8 py-4'>
                                <div className='w-full absolute flex justify-center left-0 right-0 -top-[50px] '>
                                    <div className='w-[105px] h-[100px] flex justify-center bg-gray-200 rounded-full border-r-8  border-r-gray-400 overflow-hidden  '>
                                        <img src={image} className='w-full h-full' />
                                    </div>
                                </div>
                                <div className='flex justify-center items-center mt-[60px]'>
                                    <div className='flex-col space-y-4'>
                                        <div className='text-sm'>{description}</div>
                                        <div className='text-xs'>
                                            <div className='flex justify-between '>
                                                <div className='uppercase text-primary'>{name}</div>
                                                <div>Rating</div>
                                            </div>
                                            <div className=''>
                                                <div className='text-start'>{city},{state}</div>
                                                <div className='text-start uppercase'>Total Referrals : {total_referals}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default ReferAndEarnDetails