import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const OVERVIEW = { 
    title: "Finance Overview", 
    description: "Unlock the potential of solar energy without upfront financial strain through our flexible financing solutions. We offer tailored financial plans that make solar projects accessible for businesses, developers, and homeowners. Our goal is to provide affordable options that allow you to harness clean energy while preserving your capital for other needs.", 
    video: "", 
    img: "", 
    targetPage: MAIN_ROUTES_CONST.marketplace
  };
  
  export const KEY_FETURES =[ 
    { 
      icon: "", 
      title: "Flexible Financing Options", 
      description: "We offer a range of financing solutions, including loans, leases, and power purchase agreements (PPAS), designed to meet the specific needs of your solar project." 
    }, 
    { 
      icon: "", 
      title: "Low Upfront Costs", 
      description: "Our financing options minimize or eliminate the need for large initial investments, making it easier for you to adopt solar energy without straining your budget." 
    }, 
    { 
      icon: "", 
      title: "Customized Payment Plans", 
      description: "Payment terms are tailored to your financial situation, allowing for manageable monthly payments over a term that works for you." 
    }, 
    { 
      icon: "", 
      title: "Expert Financial Guidance", 
      description: "Our team of financial experts guides you through the financing process, helping you choose the best plan to maximize savings and return on investment." 
    } 
  ];

  export const FAQ = [
    {
      "question": "What financing options are available for solar projects?",
      "answer": "Common financing options include cash purchases, loans, leases, power purchase agreements (PPAS), and government incentives."
    },
    {
      "question": "What is a Power Purchase Agreement (PPA)?",
      "answer": ""
    },
    {
      "question": "Can I finance my solar project with a loan?",
      "answer": ""
    },
    {
      "question": "What are the tax benefits of financing solar projects?",
      "answer": ""
    },
    {
      "question": "How do I qualify for solar financing?",
      "answer": ""
    },
    {
      "question": "Are there incentives for financing solar projects?",
      "answer": ""
    }
  ]