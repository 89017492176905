import { toAbsoluteUrl } from "src/helpers";

export const OUR_TEAM = [
    {
        "image": "image/1.png",
        "name": "Mr. Sanjay",
        "designation": "Director/Technical Head"
    },
    {
        "image": "image/2.png",
        "name": "Mrs. Harshala",
        "designation": "Director/Creative Head"
    },
    {
        "image": "image/3.png",
        "name": "Mr. Roshan",
        "designation": "Sr. Associate"
    },
    {
        "image": "image/4.png",
        "name": "Mr. Shravan",
        "designation": "Sr. Business Analyst"
    },
    {
        "image": "image/5.png",
        "name": "Mr. Vishnu",
        "designation": "Sr. Associate"
    },
    {
        "image": "image/6.png",
        "name": "Mrs. Neelima",
        "designation": "HRBP"
    },
    {
        "image": "image/7.png",
        "name": "Mr. Inzamam",
        "designation": "Associate"
    },
    {
        "image": "image/8.png",
        "name": "Mr. Parag",
        "designation": "Associate"
    },
    {
        "image": "image/9.png",
        "name": "Mr. Tushar",
        "designation": "Associate"
    },
    {
        "image": "image/10.png",
        "name": "Mr. Hrishikesh",
        "designation": "Associate"
    },
    {
        "image": "https://i.imgur.com/bG14X4A.png",
        "name": "Mrs. Pallavi",
        "designation": "Associate"
    },
    {
        "image": "https://i.imgur.com/3N7258U.png",
        "name": "Mrs. Shraddha",
        "designation": "Associate"
    },
    {
        "image": "https://i.imgur.com/F1pY63b.png",
        "name": "Mr. Siddhant",
        "designation": "Associate"
    },
    {
        "image": "https://i.imgur.com/p54g45V.png",
        "name": "Ms. Riya",
        "designation": "Executive"
    },
    {
        "image": "https://i.imgur.com/fJ4G8tW.png",
        "name": "Mrs. Sonal",
        "designation": "Program Analyst"
    },

    {
        "image": "https://i.imgur.com/iZ37e81.png",
        "name": "Mr. Mohammed Arif",
        "designation": "Program Analyst"
    },
    {
        "image": "https://i.imgur.com/iZ37e81.png",
        "name": "Mr. Ajay",
        "designation": "Program Analyst"
    },
    {
        "image": "https://i.imgur.com/5iG57hT.png",
        "name": "Mr. Krishna",
        "designation": "Program Analyst"
    },
    {
        "image": "https://i.imgur.com/0209W9M.png",
        "name": "Mr. Mohammed Arshadull",
        "designation": "Program Analyst"
    },
    {
        "image": "https://i.imgur.com/aZ96M2a.png",
        "name": "Mr. Gurav",
        "designation": "Program Analyst"
    },
    {
        "image": "https://i.imgur.com/aZ96M2a.png",
        "name": "Mr. Umesh",
        "designation": "Program Analyst"
    },
    {
        "image": "https://i.imgur.com/aZ96M2a.png",
        "name": "Mr.Cleophas",
        "designation": "Program Analyst"
    },
]