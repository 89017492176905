import React from 'react'
import ResetPassword from 'src/modules/Auth/ResetPassword'

type Props = {}

const ResetpasswordMain = (props: Props) => {
    return (
        <div className='min-h-[65vh] flex justify-center items-center'>
            <div className='w-[500px] mx-auto p-[48px] shadow-xl my-[50px]'>
                <ResetPassword />
            </div>
        </div>
    )
}

export default ResetpasswordMain