import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/ProjectRights";

export function allProjectRightsRequestApi(query: string): Promise<AxiosResponse<any>> {
    query = query ? "?" + query.replace("page=1&items_per_page=30","") : ""
    return axiosInstance.get(`${API.ALL_PROJECT_RIGHTS}${query}`).then((d: AxiosResponse) => d.data)
}

export async function getProjectRightsDetailByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.PROJECT_RIGHTS_DETAILS_BY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}
