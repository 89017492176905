import { MoveUpRight } from 'lucide-react'
import React from 'react'
import ListMain from 'src/modules/LandRegistration/AllLands/ListMain'
import LandEnquirySenction from 'src/modules/LandRegistration/LandEnquirySenction'
import LandLeaseWhyChooseUs from 'src/modules/LandRegistration/LandLeaseWhyChooseUs'
import LandLeasingBenifitsSection from 'src/modules/LandRegistration/LandLeasingBenifitsSection'
import LandOverviewSection from 'src/modules/LandRegistration/LandOverviewSection'

type Props = {}

const AllLandsPage = (props: Props) => {
    return (
        <div className=' max-w-[90vw] mx-auto'>
            <div className='space-y-2 py-4'>
                <div className='my-8 text-xl uppercase space-x-2 flex '><span><MoveUpRight className='text-primary ' /></span> <span className=' font-semibold' >Land Lease Rent </span></div>
                {/* <ListMain /> */}
                <LandEnquirySenction />
                <LandOverviewSection />
                <LandLeaseWhyChooseUs />
                <LandLeasingBenifitsSection />
            </div>
        </div>
    )
}

export default AllLandsPage