import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { INITIAL_SHIPPING_ORDER_FORM_VALUES, orderDetailsFormSchema, SHIPPING_ORDER_FORM_FIELDS } from './modules'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import { FormField } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {
    initial_values: any,
    onSubmit: (e: any) => void
}

const ShippingAddressForm = ({ initial_values, onSubmit }: Props) => {


    const formik = useFormik({
        initialValues: INITIAL_SHIPPING_ORDER_FORM_VALUES,
        validationSchema: orderDetailsFormSchema,
        onSubmit(values, formikHelpers) {
            onSubmit(values)
        },
    })

    useEffect(() => {
        formik.setValues(initial_values)
        return () => { }
    }, [initial_values])



    return (

        <form onSubmit={formik.handleSubmit}>
            <div className='grid grid-cols-3 gap-4'>
                {
                    Object.keys(INITIAL_SHIPPING_ORDER_FORM_VALUES).map((k: any) => {
                        let formfield: any = SHIPPING_ORDER_FORM_FIELDS.find((filed: FormField) => filed.dataKey == k) || undefined
                        return formfield && <RenderFormComponents key={`create-order-shipping-address-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield?.dataKey, e)} />
                    })
                }
            </div>
            <div className='flex justify-center py-4'>
                <RoundedButton>Save</RoundedButton>
            </div>
        </form >

    )
}

export default ShippingAddressForm