import React from 'react' 
import SalesOfExistingProjectsDetails from 'src/modules/SubServices/SalesOfExistingProjects/SalesOfExistingProjectsDetails' 
 
type Props = {} 
 
const SalesOfExistingProjectsPage = (props: Props) => { 
    return ( 
        <div> 
            <SalesOfExistingProjectsDetails /> 
        </div> 
    ) 
} 
 
export default SalesOfExistingProjectsPage 
