import React from 'react';

const HtmlCodeRender = ({ htmlString }: { htmlString: string }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: htmlString }} // Inject the HTML string safely
    />
  );
};

export default HtmlCodeRender