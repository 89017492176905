import { Separator } from '@radix-ui/react-separator'
import React from 'react'
import { Skeleton } from 'src/shadcn/components/ui/skeleton'

type Props = {}

const ListItemLoader = (props: Props) => {
    return (
       
            <div className="w-100 h-[250px] flex flex-col space-y-3">
                <div className='flex flex-col p-2 border rounded-xl border-gray-400 w-100 '>
                    {/* header */}
                    <div className='flex flex-col py-2'>
                        <div className='flex justify-between  space-y-1' >
                            <Skeleton className="h-[10px] w-[100px] rounded-md" />
                            <Skeleton className="h-[10px] w-[100px] rounded-md" />
                        </div>
                        <div className='flex justify-between space-y-1' >
                            <Skeleton className="h-[10px] w-[100px] rounded-md" />
                            <Skeleton className="h-[10px] w-[100px] rounded-md" />
                        </div>
                    </div>
                    <Separator className='border-gray-400' />
                    <div className='grid grid-cols-4 gap-4' >
                        <div className='flex justify-center items-center'>
                            {/* {data?.companyLogo ? <img src={"data?.companyLogo"} style={{ width: "100%", }} /> : <img src={IMG_EPC_COMPANY_PLACEHOLDER_LOGO} style={{ width: "100%" }} />} */}
                            <Skeleton className="h-[100%] w-[100%] rounded-md" />
                        </div>
                        <div className='col-span-3 p-2 space-y-4' >
                            <div className='flex'>
                                <div className='flex-1 py-4 text-xs flex flex-col items-center'>
                                    <Skeleton className="h-[50px] w-[100px] rounded-md" />

                                </div>
                                <div className=' flex-1 py-4  text-xs flex flex-col items-center px-1 border border-t-0 border-b-0 '>
                                    <Skeleton className="h-[50px] w-[100px] rounded-md" />

                                </div>
                                <div className='flex-1 py-4  text-xs flex flex-col items-center'>
                                    <Skeleton className="h-[50px] w-[100px] rounded-md" />

                                </div>
                            </div>
                            <div className='flex justify-end space-x-2 my-4'>
                                <Skeleton className="h-[40px] w-[250px] rounded-md" />
                                <Skeleton className="h-[40px] w-[250px] rounded-md" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
    )
}

export default ListItemLoader