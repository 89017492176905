import dayjs from 'dayjs'
import { FaPhoneAlt } from "react-icons/fa"
import { Rating } from '@smastrom/react-rating'
import { useNavigate } from 'react-router-dom'
import { ICON_OCT_VERIFIED, IMG_EPC_COMPANY_PLACEHOLDER_LOGO } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Button, Separator } from 'src/shadcn/components/ui'


export interface EpcItemData {
  manufacturer_name: string;
  review: number;
  location: string;
  companyLogo: string;
  products: any;
  product_efficiency: number;
  noOfYearsExperiance: number;
  _id?: string
}


type Props = {
  data: EpcItemData
}

const ListItem = (data: EpcItemData) => {

  const navigation = useNavigate()

  return (
    <div className='flex flex-col p-2 border  rounded-xl border-black w-100 '>
      {/* header */}
      <div className='flex flex-col py-2'>
        <div className='flex justify-between items-baseline' >
          <div className='font-bold'>{data?.manufacturer_name || ""}</div>
          <div className='text-xs'>{data?.review || ""} reviews</div>
        </div>
        <div className='flex justify-between' >
          <div className='text-xs'><span>Location :</span><span>{data?.location || ""}</span></div>
          <span className='text-xs'>
            <Rating value={Number(data?.review) || 0} style={{ height: 15, width: "80px" }} readOnly />
          </span>
        </div>
      </div>
      <Separator className='border-gray-400' />
      <div className='grid grid-cols-4 gap-4' >
        <div className='flex justify-center items-center'>
          {/* {data?.companyLogo ? <img src={"data?.companyLogo"} style={{ width: "100%", }} /> : <img src={IMG_EPC_COMPANY_PLACEHOLDER_LOGO} style={{ width: "100%" }} />} */}
          <img src={data?.companyLogo || ""} style={{ width: "100%" }} />
        </div>
        <div className='col-span-3 p-2 space-y-4' >
          <div className='flex'>
            <div className='flex-1 py-4 text-xs flex flex-col items-center'>
              <div className='font-bold text-lg'>{data?.products && data?.products?.length}+</div>
              <div className='text-center'>Products Manufactured</div>
            </div>
            <div className=' flex-1 py-4  text-xs flex flex-col items-center px-1 border border-t-0 border-b-0 '>
              <div className='font-bold text-lg'>{data?.product_efficiency} %</div>
              <div className='text-center text-nowrap'>Panel Efficiency</div>
            </div>
            <div className='flex-1 py-4  text-xs flex flex-col items-center'>
              <div className='font-bold text-lg'>Since {data?.noOfYearsExperiance ? dayjs().subtract(data?.noOfYearsExperiance as any, "year").format("YYYY") as any : 0}</div>
              <div className='text-center first-line:text-nowrap'>Years Experience</div>
            </div>
          </div>
          <div className='flex justify-end space-x-2 my-4'>
            <span className='bg-gray-200 w-[110px] p-1 rounded-full flex items-center space-x-2'>
              <img src={ICON_OCT_VERIFIED} style={{ width: "28px", height: "24px" }} />
              <span className='text-sm'>Verified</span>
            </span>
            <Button disabled={data?._id ? false : true} onClick={() => navigation("/" + MAIN_ROUTES_CONST.manufacturersDetails + "/" + data?._id)} className='bg-gray-500 hover:bg-primary flex space-x-2 rounded-full' size={"sm"} >
              <FaPhoneAlt size={16} />
              <span>Enquiry Now</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListItem