import { Separator } from '@radix-ui/react-separator'
import React from 'react'
import { Skeleton } from 'src/shadcn/components/ui/skeleton'

type Props = {}

const ListItemLoader = (props: Props) => {
    return (
        <div className=' flex flex-col space-y-2 rounded-lg border border-gray-300 hover:border-none hover:shadow-xl overflow-hidden transition duration-300 '>
            <div className='row-span-4 px-4 pt-4'>
                <Skeleton className='w-full h-[180px]' />
            </div>
            <div className=' my-1 space-y-2 px-4 py-2'>
                <Skeleton className='h-4 w-full' />
                <Skeleton className='h-[50px] w-full' />
            </div>
            <div className='flex space-x-2 p-4 pt-0'>
                <div className='flex-1 flex flex-col space-y-2'>
                    <Skeleton className='h-4 w-full' />
                    <Skeleton className='h-4 w-full' />
                </div>
                <div className='flex-1 flex flex-col space-y-2'>
                    <Skeleton className='h-4 w-full' />
                    <Skeleton className='h-4 w-full' />
                </div>

            </div>
        </div>
    )
}

export default ListItemLoader