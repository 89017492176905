import axios, { AxiosResponse } from "axios";
import { UPLOAD_FILE } from "src/services/consts/common/index";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/common";

export const uploadFileToServer = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(UPLOAD_FILE, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data.fileUrl;
};

export function getAddressDetailsByLatLngRequestApi(query: string): Promise<AxiosResponse> {
    return axios.get(`${API.GET_ADDRESS_DETAILS_BY_LAT_LNG}?${query}`).then((d: AxiosResponse) => d.data);
}
