
import { FormField } from 'src/helpers';
import * as Yup from 'yup'


export interface P2pEnergyEnquiryForm {
    discom: string;
    consumerNumber: string;
    phone: string
}

export const INIT_P2P_ENERGY_ENQUIRY_FORM: P2pEnergyEnquiryForm = {
    discom: '',
    consumerNumber: '',
    phone: ""
};

export const P2P_ENERGY_ENQUIRY_FORM_VALIDATION_SCHEMA = Yup.object().shape({
    discom: Yup.string().required('Discom is required'),
    consumerNumber: Yup.string().required('Consumer number is required'),
    phone: Yup.string().length(10, "Phone Number must be 10 digits only").required('Phone number is required'),
});


export const P2P_ENERGY_ENQUIRY_FORM_FIELDS: FormField[] = [
    {
        label: "Discome",
        dataKey: "discom",
        componentType: "options",
        componentProps: {
            required: true,
            options: [],
        }
    },
    {
        label: "Consumer Number",
        dataKey: "consumerNumber",
        componentType: "input",
        componentProps: {
            required: true,
            type: "number",
            placeholder: "eg:123456789",
        }
    },
    {
        label: "Phone Number",
        dataKey: "phoneNumber",
        componentType: "input",
        componentProps: {
            required: true,
            type: "number",
            maxLength:10,
            placeholder: "eg:91XXXXXXXX",
        }
    }
]