import React from 'react'
import { useNavigate } from 'react-router-dom'
import { cn, HERO_BANNER_2 } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Button, CarouselItem } from 'src/shadcn/components/ui'

type Props = {}

const Banner2 = (props: Props) => {


  const navigate = useNavigate()

  return (
    <CarouselItem>
      <div
        className={cn(`w-full h-[650px] `)}
        style={{
          background: `url(${HERO_BANNER_2})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"

        }}
      >
        <div className=" w-[500px] space-y-3 ml-40">
          <div className='pt-[30vh]'>
            <div className='w-[450px]'>
              <div className='space-y-1'>
                <div className="font-sans font-bold text-4xl uppercase ">India's <span className='text-primary'>First</span> & <span className='text-primary'>Free</span></div>
                <div className="font-sans font-bold text-4xl uppercase ">Project Marketplace</div>
              </div>
              <div className='h-[2px] bg-primary w-[430px] my-4'></div>
            </div>
            <div className='my-8'>Allows buyers to buy the solar project rights, new or ongoing construction projects and existing Solar Projects systems.</div>
            <div >
              Register your company now to connect with hundreds of developers actively engaged in solar projects at various stages. Don’t miss out on this opportunity!.
            </div>
          </div>
          <div className='flex space-x-2'>
            <Button onClick={() => {
              const violation: any = document.getElementById("welcome-services-residential-section");
              window.scrollTo({
                top: violation.offsetTop - 50,
                behavior: "smooth",

              });
            }} className='w-[150px] bg-primary text-white hover:bg-primary hover:text-white'>Learn More</Button>
            <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.contact_us)} variant={'theamBlack'} className='w-[150px]'>Contact Us</Button>
          </div>
        </div>
      </div>
    </CarouselItem>
  )
}

export default Banner2