
import { ADDRESS_REGEX, EMAIL_REGEX, FormField, NAME_REGEX, TYPE_OF_SOLAR_PLANT_OPTIONS, YEARS_RANGE_OPTIONS } from 'src/helpers';
import CustomTextIcon from 'src/shadcn/components/custom/CustomeSymbols/CustomTextIcon';

// Interface for Personal Details
export interface PersonalDetails {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    alternatePhone?: string; // Optional
    address: string;
    city: string;
    state: string;
    pinCode: string;
}

// Interface for Project Information
export interface ProjectInformation {
    projectLocation: string;
    projectCity: string;
    projectState: string;
    projectCountry?: string;
    totalInstalledCapacity: string;
    typeOfSolarPlant: string;
    connectionType: string;
    yearOfInstallation: string;
    plantStatus: string;
    solarTechnologyUsed: string;
    projectOwnershipType: string;
    imgUrl: string
}

// Interface for Financial and Ownership
export interface FinancialAndOwnership {
    totalInvestmentInProject: string;
    expectedSellingPriceForProjectRights: string;
    currentOwnerOfTheProject: string;
    percentageOfOwnershipToBeSold: string;
}

// Interface for Additional Project Information
export interface AdditionalProjectInformation {
    operationalSinceYears: string;
    annualEnergyProduction: string;
    outstandingLiabilities: string;
    powerPurchaseAgreement: string;
    subsidiesReceived: string;
    subsidyAmount: string,
    typeOfSolarModulesUsed: string;
    invertorModel: string;
    invertorBrand: string;
    invertorCapacity: string;
    batteryModel: string;
    batteryBrand: string;
    batteryCapacity: string;
    availabilityMonitoringSystem: string;
    expectedTimeframeForSale: string;
}


export type IProjectRightsForm = PersonalDetails & ProjectInformation & FinancialAndOwnership & AdditionalProjectInformation

// Initial empty constants for each interface
export const initialPersonalDetails: PersonalDetails = {
    firstName: '',
    lastName: "",
    email: '',
    phone: '',
    alternatePhone: '',
    address: '',
    city: '',
    state: '',
    pinCode: ''
};

export const initialProjectInformation: ProjectInformation = {
    projectLocation: '',
    projectCity: '',
    projectState: '',
    projectCountry: 'India',
    totalInstalledCapacity: "",
    typeOfSolarPlant: '',
    connectionType: '',
    yearOfInstallation: "", // Default to current year
    plantStatus: '',
    solarTechnologyUsed: '',
    projectOwnershipType: '',
    imgUrl: ""
};

export const initialFinancialAndOwnership: FinancialAndOwnership = {
    totalInvestmentInProject: "",
    expectedSellingPriceForProjectRights: "",
    currentOwnerOfTheProject: '',
    percentageOfOwnershipToBeSold: ""
};

export const initialAdditionalProjectInformation: AdditionalProjectInformation = {
    operationalSinceYears: "",
    annualEnergyProduction: "",
    outstandingLiabilities: "",
    powerPurchaseAgreement: '',
    subsidiesReceived: "",
    subsidyAmount: "",
    typeOfSolarModulesUsed: '',
    invertorModel: '',
    invertorBrand: '',
    invertorCapacity: "",
    batteryModel: '',
    batteryBrand: '',
    batteryCapacity: "",
    availabilityMonitoringSystem: '',
    expectedTimeframeForSale: ''
};

export const INIT_PROJECT_RIGHTS = {
    "firstName": "",
    "lastName": "",
    "email": "",
    "phone": "",
    "alternatePhone": "",
    "address": "",
    "city": "",
    "state": "",
    "pinCode": "",
    "projectLocation": "",
    "projectCity": "",
    "projectState": "",
    "projectCountry": "",
    "totalInstalledCapacity": "",
    "imgUrl": "",
    "totalInvestmentInProject": "",
    "expectedSellingPriceForProjectRights": "",
    "currentOwnerOfTheProject": "",
    "percentageOfOwnershipToBeSold": "",
    "annualEnergyProduction": "",
    "outstandingLiabilities": "",
    "subsidyAmount": "",
    "invertorModel": "",
    "invertorBrand": "",
    "invertorCapacity": "",
    "batteryModel": "",
    "batteryBrand": "",
    "batteryCapacity": "",
    "powerPurchaseDoc": "",
    "ownershipDoc": "",
    "legalClearanceDoc": "",
    "anyOtherRelevantDoc": [],
    "typeOfSolarPlant": "",
    "connectionType": "",
    "yearOfInstallation": "",
    "plantStatus": "",
    "solarTechnologyUsed": "",
    "projectOwnershipType": "",
    "operationalSinceYears": "",
    "powerPurchaseAgreement": "",
    "subsidiesReceived": "",
    "typeOfSolarModulesUsed": "",
    "availabilityMonitoringSystem": "",
    "expectedTimeframeForSale": "",
}

export const PROJECT_FORM_STRUCTURE = {
    "Personal Details": initialPersonalDetails,
    "Project Details": initialProjectInformation,
    "Financial And Ownership Details": initialFinancialAndOwnership,
    "Additional Project Information": initialAdditionalProjectInformation
}



export const PROJECT_RIGHTS_FORM_FIELDS: FormField[] = [
    {
        label: 'First Name',
        dataKey: 'firstName',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter your full name',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Last Name',
        dataKey: 'lastName',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter your full name',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Email Address',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: "email",
            regex: EMAIL_REGEX,
            placeholder: 'Enter your email address',
            maxLength: 150,
            required: true
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phone',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter your phone number',
            maxLength: 10,
            required: true
        },
    },
    {
        label: 'Alternate Phone Number',
        dataKey: 'alternatePhone',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter alternate phone number (optional)',
            maxLength: 10,
            required: false
        },
    },
    {
        label: 'Address',
        dataKey: 'address',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: ADDRESS_REGEX,
            placeholder: 'Enter your address',
            maxLength: 250,
            required: true
        },
    },
    {
        label: 'City',
        dataKey: 'city',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter your city',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'State',
        dataKey: 'state',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter your state',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Pin Code',
        dataKey: 'pinCode',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter your pin code',
            maxLength: 6,
            required: true
        },
    },
    {
        label: 'Project Location',
        dataKey: 'projectLocation',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter project location',
            maxLength: 100,
            required: true
        },
    },
    {
        label: 'Project City',
        dataKey: 'projectCity',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter project city',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Project State',
        dataKey: 'projectState',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter project state',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Project Country',
        dataKey: 'projectCountry',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter project country (default India)',
            maxLength: 50,
            required: false
        },
    },
    {
        label: 'Total Installed Capacity',
        dataKey: 'totalInstalledCapacity',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter total installed capacity',
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='kW' />,
            required: true
        },
    },
    {
        label: 'Type of Solar Plant',
        dataKey: 'typeOfSolarPlant',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Connection Type',
        dataKey: 'connectionType',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Year of Installation',
        dataKey: 'yearOfInstallation',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Plant Status',
        dataKey: 'plantStatus',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Solar Technology Used',
        dataKey: 'solarTechnologyUsed',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Project Ownership Type',
        dataKey: 'projectOwnershipType',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Total Investment in Project',
        dataKey: 'totalInvestmentInProject',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter total investment',
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='₹' />,
            required: true
        },
    },
    {
        label: 'Expected Selling Price for Project Rights',
        dataKey: 'expectedSellingPriceForProjectRights',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter expected selling price',
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='₹' />,
            required: true
        },
    },
    {
        label: 'Current Owner of the Project',
        dataKey: 'currentOwnerOfTheProject',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: NAME_REGEX,
            placeholder: 'Enter current owner',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Percentage of Ownership to be Sold',
        dataKey: 'percentageOfOwnershipToBeSold',
        componentType: 'input',
        componentProps: {
            type: "number",
            maxLength: 3,
            inputSuffixIcon: <CustomTextIcon value='%' />,
            placeholder: 'Enter percentage of ownership',
            required: true
        },
    },
    {
        label: 'Operational Since Years',
        dataKey: 'operationalSinceYears',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Annual Energy Production',
        dataKey: 'annualEnergyProduction',
        componentType: 'input',
        componentProps: {
            type: "number",
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='MW' />,
            placeholder: 'Enter annual energy production',
            required: true
        },
    },
    {
        label: 'Outstanding Liabilities',
        dataKey: 'outstandingLiabilities',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter outstanding liabilities',
            numberFormatted: true,
            inputPrefixIcon: <CustomTextIcon value='₹' />,
            required: true
        },
    },
    {
        label: 'Power Purchase Agreement',
        dataKey: 'powerPurchaseAgreement',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },

    {
        label: 'Subsidies Received',
        dataKey: 'subsidiesReceived',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    }, {

        label: 'Subsidies Amount',
        dataKey: 'subsidyAmount',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter subsidies received',
            numberFormatted: true,
            inputPrefixIcon: <CustomTextIcon value='₹' />,
            required: true,
            conditionalHide: {
                expectedValue: "Yes",
                formKey: "subsidiesReceived"
            }
        },
    },
    {
        label: 'Type of Solar Modules Used',
        dataKey: 'typeOfSolarModulesUsed',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Invertor Model',
        dataKey: 'invertorModel',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Enter invertor model',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Invertor Brand',
        dataKey: 'invertorBrand',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Enter invertor brand',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Invertor Capacity',
        dataKey: 'invertorCapacity',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter invertor capacity',
            required: true,
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='kW' />
        },
    },
    {
        label: 'Battery Model',
        dataKey: 'batteryModel',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Enter battery model',
            maxLength: 50,
            required: true,
            numberFormatted: true,
            inputSuffixIcon: <CustomTextIcon value='kW' />
        },
    },
    {
        label: 'Battery Brand',
        dataKey: 'batteryBrand',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Enter battery brand',
            maxLength: 50,
            required: true
        },
    },
    {
        label: 'Battery Capacity',
        dataKey: 'batteryCapacity',
        componentType: 'input',
        componentProps: {
            type: "number",
            placeholder: 'Enter battery capacity',
            required: true
        },
    },
    {
        label: 'Availability Monitoring System',
        dataKey: 'availabilityMonitoringSystem',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Expected Timeframe for Sale',
        dataKey: 'expectedTimeframeForSale',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Power Purchase Document',
        dataKey: 'powerPurchaseDoc',
        componentType: 'file_picker',
        componentProps: {
            conditionalHide: {
                expectedValue: "Yes",
                formKey: "powerPurchaseAgreement"
            },
            required: true
        },
    },
    {
        label: 'OwnerShip Document',
        dataKey: 'ownershipDoc',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Legal Clearance Document',
        dataKey: 'legalClearanceDoc',
        componentType: 'file_picker',
        componentProps: {
            required: true
        },
    },
    {
        label: 'Any Other Relevant Document',
        dataKey: 'anyOtherRelevantDoc',
        componentType: 'file_picker',
        componentProps: {
            fileSelectionType: "multi",
            required: true
        },
    },
];



export const FILTERS_DATA = [
    {
        name: 'Project City',
        dataKey: "projectCity",
        type: 'text',
        regex: NAME_REGEX,
    },
    {
        name: 'Project State',
        dataKey: "projectState",
        type: 'text',
        regex: NAME_REGEX,
    },
    {
        name: 'Project Country',
        dataKey: "projectCountry",
        type: 'text', // Optional, but keeping it as text for consistency
        regex: NAME_REGEX,
    },
    {
        name: 'Annual Energy Production',
        dataKey: "annualEnergyProduction",
        type: 'range',
    },
    {
        name: 'Total Installed Capacity',
        dataKey: "totalInstalledCapacity",
        type: 'range',
    },
    {
        name: 'Type of Solar Plant',
        dataKey: "typeOfSolarPlant",
        type: 'dropdown',
        options:TYPE_OF_SOLAR_PLANT_OPTIONS,
    },
    {
        name: 'Year of Installation',
        dataKey: "yearOfInstallation",
        type: 'dropdown',
        options: YEARS_RANGE_OPTIONS,
    },
    {
        name: 'Total Investment in Project',
        dataKey: "totalInvestment",
        type: 'range',
    },
    {
        name: 'Expected Selling Price for Project Rights',
        dataKey: "expectedSellingPrice",
        type: 'range',
    },
];


export const FILTER_STATE = {
    projectCity: "",
    projectState: "",
    projectCountry: "",
    annualEnergyProduction: {
        min: null,
        max: null
    },
    totalInstalledCapacity: {
        min: null,
        max: null
    },
    typeOfSolarPlant: "",
    yearOfInstallation: "",
    totalInvestmentInProject: {
        min: null,
        max: null
    },
    expectedSellingPriceForProjectRights: {
        min: null,
        max: null
    },
}

export interface IFILTER_STATE {
    projectCity: string;
    projectState: string;
    projectCountry: string;
    annualEnergyProduction: {
        min:string,
        max:string
    };
    totalInstalledCapacity: {
        min:string,
        max:string
    };
    typeOfSolarPlant: string;
    yearOfInstallation: string;
    totalInvestmentInProject: {
        min:string,
        max:string
    };
    expectedSellingPriceForProjectRights: {
        min:string,
        max:string
    };
}

