import { Minus, Plus } from 'lucide-react'
import React from 'react'
interface IPlusMinus{
    accordianVal:string,
    value:string
}
const PlusMinus = ({accordianVal,value}:IPlusMinus) => {
  return (
    <div>
    {accordianVal === value ? (
      <Minus className='w-6 h-6' strokeWidth={2}/>
    ) : (
      <Plus className='w-6 h-6' strokeWidth={2}/>
    )}
  </div>
  )
}

export default PlusMinus