import React from 'react'
import { IMG_SOLAR_FARMS, IMG_SOLAR_ROOF_TOP, IMG_SOLAR_WATER_PUMP } from 'src/helpers';
import { Card, CardContent, Carousel, CarouselContent, CarouselItem } from 'src/shadcn/components/ui';

type Props = {
    // imageUrl:string,
    // serviceType:string,
    // serviceDescription:String
    services: any[]
}

const data = [
    {
        image: IMG_SOLAR_FARMS,
        title: "Solar Farm",
        description: "Novus Green provides entire solution for Solar Power Production starting from design, procurement of supplies, and construction to follow-up services"
    },
    {
        image: IMG_SOLAR_ROOF_TOP,
        title: "Solar Rooftops",
        description: "Novus Green offers solutions to create and deliver customized solar energy solutions to the commercial, industrial & public sectors and residential purposes"
    },
    {
        image: IMG_SOLAR_WATER_PUMP,
        title: "Solar Water Pumps",
        description: "Solar Water Pumping Systems are ideal solution for the agricultural needs as water is required for the crops during day time"
    }
]

const EpsServices = ({
    services
}: Props) => {
    return (
        <div>
            <Carousel
                opts={{ align: "start", }}
                className="w-full"
            >
                <CarouselContent>
                    {services.map(({ productImage, productName, productDescription }, index) => {
                        return (
                            <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 p-1">
                                <div className="p-0">
                                    <Card>
                                        <CardContent className="flex aspect-square items-center justify-center p-0">
                                            <div className='grid h-[300px] grid-rows-6 gap-0 auto-rows-max w-full'>
                                                <div className='row-span-3'>
                                                    <img src={productImage} style={{ width: "100%", height: "100%" }} />
                                                </div>
                                                <div className='bg-gray-200 text-sm font-semibold p-4 uppercase'>{productName}</div>
                                                <div className='bg-gray-100 text-xs p-4 row-span-2 w-100 text-ellipsis '>{productDescription}</div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </CarouselItem>
                        )
                    })}
                </CarouselContent>
            </Carousel>
        </div>
    )
}

export default EpsServices