import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { catchAsync, IS_SUCCESS_RESPONSE, successTheme } from 'src/helpers'
import LandRegistrationForm from 'src/modules/LandRegistration/LandRegistrationForm'
import { INITIAL_LAND_ONBOARD_FORM_DATA } from 'src/modules/LandRegistration/modules'
import { createLandRegistrationApiRequest } from 'src/services/requests/LandRegistration'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'

type Props = {}

const LandRegistrationPage = (props: Props) => {

    const [isLoading, setisLoading] = useState(false)
    const [showSuccessDialog, setshowSuccessDialog] = useState<boolean>(false)
    const [formData, setformData] = useState(INITIAL_LAND_ONBOARD_FORM_DATA)
    const navigate = useNavigate()

    const handleEnquiryForm = catchAsync(
        async (data: any) => {
            setisLoading(true)
            return await createLandRegistrationApiRequest(data);
        },
        (result: AxiosResponse) => {
            if (IS_SUCCESS_RESPONSE(result)) {
                toast.success("Request sent successful", { style: successTheme })
                setformData(INITIAL_LAND_ONBOARD_FORM_DATA)
                setshowSuccessDialog(true)
            }
            setisLoading(false)
        },
        () => {
            setisLoading(false)
        }
    );


    return (
        <div className='flex flex-col space-y-2 py-4 '>
            <div className='text-xl text-primary uppercase font-semibold'>Land Registration Request</div>
            <LandRegistrationForm initValues={formData} submittingForm={isLoading} onSubmit={(e: any) => handleEnquiryForm(e)} />
            <CustomeDialog className='md:max-w-md' isOpen={showSuccessDialog} headerTitle="Request Submitted" hideDefaultCancle onSubmit={() => {
                setshowSuccessDialog(false)
                navigate(-1)
            }} >
                <p className='pb-4'>We Submitted your request, we will get back you soon.</p>
            </CustomeDialog>
        </div>
    )
}

export default LandRegistrationPage