
import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/EpcContractors/index";

export function epcAllContractorsApiRequest(query: string): Promise<AxiosResponse> {
    query = query ? "?" + query.replace("page=1&items_per_page=30","") : ""
    return axiosInstance.get(`${API.URL_ALL_EPC_CONTRACTORS}${query}`).then((d: AxiosResponse) => d.data);
}


export function getEpcContractorDetailsApiRequest(id: string): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.URL_EPC_CONTRACTORS_DETAILS}/${id}`).then((d: AxiosResponse) => d.data);
}


export function epcContractorEnquiryRequestApiRequest(data: any): Promise<AxiosResponse> {
    return axiosInstance.post(`${API.URL_EPC_CONTRACTORS_DETAILS}`, { ...data }).then((d: AxiosResponse) => d.data);
}
