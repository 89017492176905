import React from 'react'
import { ANNOTATION_ICON, IMG_BLOCKCHAIN_BANNER, IMG_BLOCKCHAIN_IMG } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const BlockchainService = (props: Props) => {
  return (
    <div id='welcome-blockchain-section' className='h-[500px]' style={{ backgroundImage: 'url(' + IMG_BLOCKCHAIN_BANNER + ')', backgroundSize: 'cover' }}>
      <div className=' '>
        <div className='grid grid-cols-2'>
          <div className='flex items-center p-16'>
            <div className='space-y-8'>
              {/* <div className='text-4xl font-bold'>Blockchain Technology Solutions</div> */}
              <div className='flex space-x-1'> <img src={ANNOTATION_ICON} style={{ width: "20px", height: "20px" }} /> <span className=' font-bold text-3xl'>Blockchain Technology  <br />Solutions</span></div>
              <div className='ml-[25px] space-y-4'>
                <div className='w-[300px]'>Our blockchain technology ensures secure, decentralized, and verifiable transactions for your business</div>
                <RoundedButton>Learn More</RoundedButton>
              </div>
            </div>
          </div>
          {/* <div className='flex justify-center'>
            <img src={IMG_BLOCKCHAIN_IMG} style={{ width: "650px", height: "500px" }} />
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default BlockchainService