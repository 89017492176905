import React from 'react'
import ServicesBanner from './Component/ServicesBanner'
import SolarServices from './Component/SolarServices'
import SolarServicesEnquiryNow from './Component/SolarServicesEnquiryNow/SolarServicesEnquiryNow'


const ServicesMain = () => {
  return (
    <div className='w-full font-sans'>
        <ServicesBanner/>
        <div className='w-full px-20 pb-20 pt-10'>
        <SolarServices/>
        </div>
       <SolarServicesEnquiryNow/>
    </div>
  )
}

export default ServicesMain