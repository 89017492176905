import { MAIN_ROUTES_CONST } from "src/navigations/modules";

export const DATA = [
    {
        "heading": "BUY PROJECT RIGHTS",
        "number": "01",
        "description": "Acquire full or partial project rights as per the need from the utility scale project and solar parks listed.",
        link: MAIN_ROUTES_CONST.project_rights,
    },
    {
        "heading": "BUY EXISTING PROJECTS",
        "number": "02",
        "description": "Discover the perfect solar project for you, whether it's a rooftop installation or a fully commissioned solar park.",
        link: MAIN_ROUTES_CONST.sell_existing_projects,
    },
    {
        "heading": "BUY NEW CONSTRUCTION PROJECTS",
        "number": "03",
        "description": "Explore new construction projects from developers, available as newly built rooftop installations or solar parks.",
        link: MAIN_ROUTES_CONST.new_constructed_projects,
    },
    {
        "heading": "LEASES ROOF AREAS",
        "number": "04",
        "description": "Get rooftop space that fits your project requirements on lease or list your own rooftop to attract interested lessees.",
        link: MAIN_ROUTES_CONST.lands,
    },
    {
        "heading": "OFFER LAND FOR UTILITY SCALE PROJECT",
        "number": "05",
        "description": "Offer your land for lease or discover the perfect open space for a new solar park.",
        link: MAIN_ROUTES_CONST.lands,
    }

]