import { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API_URL from "src/services/consts/ServiceEnquiryRequests"


export function createServiceEnquiryRequestRequestApi(data: any): Promise<any> {
    return axiosInstance.post(`${API_URL.URL_SERVICE_ENQUIRY_REQUEST_CREATE}`, data).then((d: AxiosResponse) => d.data);
}



export function createServiceEnquiryFormRequestRequestApi(data: any): Promise<any> {
    return axiosInstance.post(`${API_URL.URL_SERVICE_ENQUIRY_REQUEST_CREATE_FORM}`, data).then((d: AxiosResponse) => d.data);
}
