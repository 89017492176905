import React from 'react'
import { toAbsoluteUrl } from 'src/helpers'
import a from "public/media/svg/icons/PeopleAnnualElectricity.svg"

const CostomerJorneyOutcome = () => {
    const costomerJourneyOutcome=toAbsoluteUrl('/media/svg/image/Solar-Img-customerJourney.svg')
const litterOfWater=toAbsoluteUrl('/media/svg/icons/LittersOfWater.svg')
const tonesOfCo2=toAbsoluteUrl("/media/svg/icons/TonesOfCo2.svg")
const treeSeeding=toAbsoluteUrl("/media/svg/icons/TreesSeedingIcon.svg")
const lifetimeProduction=toAbsoluteUrl("/media/svg/icons/LifetimeProduction.svg")
const peplesAnnualElectricity=toAbsoluteUrl("/media/svg/icons/PeopleAnnualElectricity.svg")
  return (
    <div className='w-full font-sans mt-12 h-[480px] rounded-[50px] p-10 text-white text-lg font-bold' style={{
        background: `url(${costomerJourneyOutcome})`,
        backgroundSize: "cover",
      }}>
        <div className=' flex flex-col justify-center items-center space-y-2'>
            <div>
                <div className='text-center'>350,196 LTR</div>
                <p className='text-center'>Liters of water saved by avoiding consuption<br/> of coal-fired electricity.</p>
            </div>
            <div>
                <img alt='' src={litterOfWater} className='w-[100px] h-[100px]'/>
            </div>
        </div>

        <div className='grid grid-cols-2 -mt-[4%]'>
        <div className=' flex  justify-start items-center space-x-4'>
            <div>
                <div className='text-center'>1,516.2 T</div>
                <p className='text-center'>Tones of Co2 over Lifetime.</p>
            </div>
            <div>
                <img alt='' src={tonesOfCo2} className='w-[100px] h-[100px]'/>
            </div>
        </div>

        <div className=' flex flex-row-reverse justify-start items-center '>
            <div className='ml-4'>
                <div className='text-center'>38896</div>
                <p className='text-center'>Trees Seeding grown for 10 Years.</p>
            </div>
            <div>
                <img alt='' src={treeSeeding} className='w-[100px] h-[100px]'/>
            </div>
        </div>
        </div>

        <div className='grid grid-cols-2 mt-[5%]'>
        <div className=' flex flex-row-reverse justify-end items-center space-x-4'>
            <div className='ml-4'>
                <div className='text-center'>1,750,000 kWp</div>
                <p className='text-center'>Lifetime Production over 25 years.</p>
            </div>
            <div>
                <img alt='' src={lifetimeProduction} className='w-[100px] h-[100px]'/>
            </div>
        </div>

        <div className=' flex  justify-end items-center space-x-4 '>
            <div className=''>
                <div className='text-center'>1,465.5 KWp</div>
                <p className='text-center'>People’s Annual Electricity</p>
            </div>
            <div>
                <img alt='' src={peplesAnnualElectricity} className='w-[100px] h-[100px]'/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default CostomerJorneyOutcome