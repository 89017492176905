import React from "react";
import { Button, Card } from "src/shadcn/components/ui";
import { ISolarPlanArr } from "./modules";
import { checkDecimal, formatIndianNumber } from "src/helpers";
import { Check } from "lucide-react";
import HtmlCodeRender from "src/shadcn/components/custom/HtmlCodeRender";

const SolarplanCard = ({
  benefits,
  emiPrice,
  name,
  overAllPrice,
  projectPrice,
  unlockPrice,
}: ISolarPlanArr) => {
  return (
    <Card className=" font-sans bg-[#E0995833] border border-t-[#BF875426] border-t-[4px] rounded-[26px] w-[413px] h-[458px] drop-shadow-lg p-9 space-y-4">
      <h1 className="text-2xl font-bold text-primary text-center uppercase">{name}</h1>
      <div className="text-[#231D4F] flex justify-center items-center text-2xl space-x-3 ">
        <div className="font-normal text-nowrap">Project Price :</div>
        <div className="font-bold text-nowrap">
          ₹{""} {checkDecimal(projectPrice?.toString(), true)}
        </div>
      </div>

      <div className="grid grid-cols-2 ">
        <div className="space-y-1">
          <div className="text-sm font-bold text-primary">Overall Price</div>
          <div className="text-[#231D4F99] font-semibold text-nowrap">
            <del>
              ₹ {checkDecimal(Number(overAllPrice?.totalPrice)?.toString(), true)}
            </del>
          </div>
          <div className="text-[#231D4F] font-bold">
            ₹ {checkDecimal((overAllPrice?.discountPrice)?.toString(), true)}
          </div>
        </div>
        <div className="space-y-1">
          <div className="text-sm font-bold text-primary">
            EMI ( 12 Months )
          </div>
          <div className="text-[#231D4F99] font-semibold text-nowrap">
            <del>
              ₹ {checkDecimal(emiPrice?.totalPrice?.toString(), true)}
            </del>
          </div>
          <div className="text-[#231D4F] font-bold">
            ₹ {checkDecimal((emiPrice?.discountPrice)?.toString(), true)}
          </div>
        </div>
      </div>

      <div className="space-y-2">
        {benefits?.map((cur: string, index: number) => (
          <div className="flex items-center space-x-4" key={`${cur}-${index}`}>
            <div className="w-[24px] h-[24px] rounded-full bg-[#FF790026] text-primary flex justify-center items-center">
              <Check className="w-4 h-4" strokeWidth={3}/>
            </div>
            <div className="text-sm font-semibold">
              <HtmlCodeRender htmlString={cur} />
            </div>
          </div>
        ))}
      </div>

      <Button className="rounded-full text-sm font-normal h-[43px]">Unlock <span className="font-bold">₹ {formatIndianNumber(
        unlockPrice.toString(),
        2
      )}</span> in Interest Savings Over Time </Button>
    </Card>
  );
};

export default SolarplanCard;
