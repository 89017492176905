import React from 'react'
import ReferalForm from 'src/modules/ReferAndEarn/ReferalForm'
import ReferAndEarnDetails from 'src/modules/ReferAndEarn/ReferAndEarnDetails'

type Props = {}

const ReferAndEarnPage = (props: Props) => {
    return (

        <ReferAndEarnDetails />


    )
}

export default ReferAndEarnPage