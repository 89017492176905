import { BUILDING_TYPES, DUMMY_OPTIONS, FormField, NUMBER_REGEX, PROJECT_LAND_TYPES, PROJECT_TYPES } from "src/helpers";

import { IndianRupee, Locate } from "lucide-react";
import KWHIcon from "src/shadcn/components/custom/CustomeSymbols/KWHIcon";
import KWIcon from "src/shadcn/components/custom/CustomeSymbols/KWIcon";
import SqMIcon from "src/shadcn/components/custom/CustomeSymbols/SqMIcon";
import YearsIcon from "src/shadcn/components/custom/CustomeSymbols/YearsIcon";
import CustomTextIcon from "src/shadcn/components/custom/CustomeSymbols/CustomTextIcon";


export interface ProjectData {
  projectName: string;
  projectType: string;
  projectSize: string;
  projectDescription: string;
  expectedDateOfInstallation: string,
  dcCommissioning: string;
}

export interface SiteData {
  propertyType: string;
  buildingType: string;
  typeOfSpace: string,
  freeAreaAvailableOnRoofTop: string,
  freeAreaAvailableOnGround: string,
  tilt: string,
  shadingAnalysis: string,
  roofMaterial: string,
  roofAgeAndCondition: string
  temperatureRange: string,
  solarIrradiation: string;
}

export interface PowerConsumptionData {
  unitRequiredPerMonth: string;
  unitConsumptionPerDay: string;
  tariffRate: string;
  monthlyBillAmount: string;
  payBack: string;
  termPlan: string;
  projectOwnershipType: string;
  pricePerKwh: string;
  recentElectricityBill: string,
  averageLoadPerHour: string
  minimumLoadPerHour: string,
  maximumLoadPerMonth: string
}

export interface ContactDetails {
  contactPersonName: string;
  contactPersonNumber: string;
  contactPersonEmail: string,
}

export interface LocationDetails {
  address: string;
  city: string,
  state: string;
  country: string;
  pinCode: string;
  connectionToGrid: string,
  geoGraphicalLocation: string,
}

export interface ProjectFinanceData {
  projectCost: string;
  structureCost:string,
  electicalCost:string,
  otherCost: string;
  interestRate: string;
  interestAmount: string;
  totalProjectCost: string;
  returnPerYearRate: string,
  returnPerYearAmount: string;
  returnPerYearInterest: string;
  downpaymentByOfftaker: string;
  requiredInvestment: string;
  offtakerOwnershipPercentage: string;
  tokenValidity: string;
  totalTokenSupply: string
  tokenIssued: string
}

export interface Attachments {
  imagesAttachments: File[] | string[] | undefined,
  videoAttachments: File[] | string[] | undefined,
  headerImage: File | string | undefined,
  agreement: File | string | undefined,
  aadharFrontDocument: File | string | undefined,
  aadharBackDocument: File | string | undefined,
  panDocument: File | string | undefined,
}


export const initialProjectData: ProjectData = {
  projectName: '',
  projectType: '',
  projectSize: '',
  projectDescription: '',
  expectedDateOfInstallation: '',
  dcCommissioning: '',
};

export const initialSiteData: SiteData = {
  propertyType: '',
  buildingType: '',
  typeOfSpace: '',
  freeAreaAvailableOnRoofTop: '',
  freeAreaAvailableOnGround: '',
  tilt: '',
  shadingAnalysis: '',
  roofMaterial: '',
  roofAgeAndCondition: '',
  temperatureRange: '',
  solarIrradiation: '',
};

export const initialPowerConsumptionData: PowerConsumptionData = {
  unitRequiredPerMonth: '',
  unitConsumptionPerDay: '',
  tariffRate: '',
  monthlyBillAmount: '',
  payBack: '',
  termPlan: '',
  projectOwnershipType: '',
  pricePerKwh: '',
  recentElectricityBill: '',
  averageLoadPerHour: '',
  minimumLoadPerHour: '',
  maximumLoadPerMonth: '',
};

export const initialContactDetails: ContactDetails = {
  contactPersonName: '',
  contactPersonNumber: '',
  contactPersonEmail: '',
};

export const initialLocationDetails: LocationDetails = {
  address: '',
  city: '',
  state: '',
  country: '',
  pinCode: '',
  connectionToGrid: '',
  geoGraphicalLocation: '',
};

export const initialProjectFinanceData: ProjectFinanceData = {
  projectCost: '',
  structureCost:"",
  electicalCost:"",
  otherCost: '',
  interestRate: '',
  interestAmount: '',
  totalProjectCost: '',
  returnPerYearRate: '',
  returnPerYearAmount: '',
  returnPerYearInterest: '',
  downpaymentByOfftaker: '',
  requiredInvestment: '',
  offtakerOwnershipPercentage: '',
  totalTokenSupply: '',
  tokenIssued: '',
  tokenValidity: '',
};

export const initialAttachments: Attachments = {
  imagesAttachments: [],
  videoAttachments: [],
  headerImage: '',
  agreement: '',
  aadharFrontDocument: '',
  aadharBackDocument: '',
  panDocument: '',
};

export const PROJECT_FORM_INITIAL_VALUES = {
  ...initialProjectData,
  ...initialSiteData,
  ...initialPowerConsumptionData,
  ...initialContactDetails,
  ...initialLocationDetails,
  ...initialProjectFinanceData,
  ...initialAttachments,
  country: "India"
}



export const PROJECT_FORM_FIELDS: FormField[] = [
  {
    label: 'Project Name',
    dataKey: 'projectName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Project Name',
      maxLength: 150,
    },
  },
  {
    label: 'Project Type',
    dataKey: 'projectType',
    componentType: 'options',
    componentProps: {
      options: PROJECT_TYPES,
    },
  },
  {
    label: 'Project Size',
    dataKey: 'projectSize',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      inputSuffixIcon: <CustomTextIcon value="kW" />,
      maxLength: 50,
    },
  },
  {
    label: 'Project Description',
    dataKey: 'projectDescription',
    componentType: 'textarea',
    componentProps: {
      placeholder: 'Description',
    },
  },
  {
    label: 'Expected Date of installation',
    dataKey: 'expectedDateOfInstallation',
    componentType: 'date_picker',
    componentProps: {
      placeholder: 'Description',
    },
  },
  {
    label: 'DC Commissioning',
    dataKey: 'dcCommissioning',
    componentType: 'options',
    componentProps: {
      options: []
    },
  },
  {
    label: 'Property Type',
    dataKey: 'propertyType',
    componentType: 'options',
    componentProps: {
      options: [],
    },
  },
  {
    label: 'Building Type',
    dataKey: 'buildingType',
    componentType: 'options',
    componentProps: {
      options: BUILDING_TYPES
    },
  },

  {
    label: 'Type of Space',
    dataKey: 'typeOfSpace',
    componentType: 'options',
    componentProps: {
      options: []
    },
  },
  {
    label: 'Free available space',
    dataKey: 'freeAreaAvailableOnRoofTop',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      // inputSuffixIcon: <SqMIcon />,
      maxLength: 50,
      options: []
    },
  },
  {
    label: 'Free available Ground',
    dataKey: 'freeAreaAvailableOnGround',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      // inputSuffixIcon: <SqMIcon />,
      maxLength: 50,
      options: []
    },
  },
  {
    label: 'Tilt (Degree)',
    dataKey: 'tilt',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      maxLength: 50
    },
  },

  {
    label: 'Shading Analysis',
    dataKey: 'shadingAnalysis',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 50
    },
  },

  {
    label: 'Roof Material',
    dataKey: 'roofMaterial',
    componentType: 'options',
    componentProps: {
      options: []
    },
  },
  {
    label: 'Roof Age and Condition',
    dataKey: 'roofAgeAndCondition',
    componentType: 'options',
    componentProps: {
      options: []
    },
  },
  {
    label: 'Temperature Range (°C)',
    dataKey: 'temperatureRange',
    componentType: 'options',
    componentProps: {
     
    },
  },

  {
    label: 'Solar Irradiation',
    dataKey: 'solarIrradiation',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 50
    },
  },

  {
    label: 'Monthly Consumption',
    dataKey: 'unitRequiredPerMonth',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '0',
      inputSuffixIcon: <CustomTextIcon value="units" />,
      maxLength: 8
    },
  },
  {
    label: 'Daily Consumption',
    dataKey: 'unitConsumptionPerDay',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Units',
      // readonly: true,
      inputSuffixIcon: <CustomTextIcon value="units/day" />,
      maxLength: 8,
    },
  },

  {
    label: 'Tariff Rate (Per Unit)',
    dataKey: 'tariffRate',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Per Unit Rate (₹)',
      inputPrefixIcon: <IndianRupee size={16} />
    },
  },

  {
    label: 'Monthly Bill Amount',
    dataKey: 'monthlyBillAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <IndianRupee size={16} />,
      maxLength: 50,
    },
  },
  {
    label: 'Pay Back Amount',
    dataKey: 'payBack',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Amount',
      numberFormatted: true,
      readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      maxLength: 50,
    },
  },
  {
    label: 'Term Plan',
    dataKey: 'termPlan',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      readonly: true,
      inputSuffixIcon: <CustomTextIcon value="months" />,
      maxLength: 50,
    },
  },
  {
    label: 'Project Ownership Type',
    dataKey: 'projectOwnershipType',
    componentType: 'options',
    componentProps: {
     
    },
  },
  {
    label: 'Price Per kW',
    dataKey: 'pricePerKwh',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      inputPrefixIcon: <IndianRupee size={16} />,
      // inputSuffixIcon: <CustomTextIcon value="/kW" />,
      maxLength: 50,
    },
  },

  {
    label: 'Recent Electricity Bill',
    dataKey: 'recentElectricityBill',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon: <IndianRupee size={16} />,
      maxLength: 50,
    },
  },

  {
    label: 'Average Load',
    dataKey: 'averageLoadPerHour',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      inputSuffixIcon: <CustomTextIcon value="kW" />,
      maxLength: 50,
      numberFormatted:true
    },
  },
  {
    label: 'Minimum Load',
    dataKey: 'minimumLoadPerHour',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      inputSuffixIcon: <CustomTextIcon value="kW" />,
      maxLength: 50,
      numberFormatted:true
    },
  },

  {
    label: 'Maximum Load',
    dataKey: 'maximumLoadPerMonth',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      inputSuffixIcon: <CustomTextIcon value="kW" />,
      maxLength: 50,
      numberFormatted:true
    },
  },


  {
    label: 'Contact Person Name',
    dataKey: 'contactPersonName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Name',
      maxLength: 150,
    },
  },
  {
    label: 'Contact Person Number',
    dataKey: 'contactPersonNumber',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Number',
      regex: NUMBER_REGEX,
      maxLength: 10,
    },
  },

  {
    label: 'Contact Person Email',
    dataKey: 'contactPersonEmail',
    componentType: 'input',
    componentProps: {
      type: "email",
      placeholder: 'Email',
      maxLength: 150,
    },
  },
  {
    label: 'Country',
    dataKey: 'country',
    componentType: 'input',
    value: "India",
    componentProps: {
      type: "text",
      placeholder: 'country',
      readonly: true,
      maxLength: 150,
    },
  },
  {
    label: 'State',
    dataKey: 'state',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'state',
      maxLength: 150,
    },
  },
  {
    label: 'City',
    dataKey: 'city',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'city',
      maxLength: 150,
    },
  },
  {
    label: `Address`,
    dataKey: 'address',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Address',
      maxLength: 250,
    },
  },
  {
    label: 'Pin Code',
    dataKey: 'pinCode',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: NUMBER_REGEX,
      placeholder: 'pin code',
      maxLength: 6,
    },
  },

  {
    label: 'Connection To Grid',
    dataKey: 'connectionToGrid',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 50,
    },
  },

  {
    label: 'GeoGraphical Location (lat/long)',
    dataKey: 'geoGraphicalLocation',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'lat,long',
      inputSuffixIcon: <Locate size={16} />,
      maxLength: 150,
    },
  },

  {
    label: 'Project Cost',
    dataKey: 'projectCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: "",
      readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      maxLength: 50,
      description: "(Price per kW)",
    },
  },
  {
    label: 'Electical Cost',
    dataKey: 'electicalCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon:<IndianRupee size={16} />,
      required:true
    },
  },
  {
    label: 'Structure Cost',
    dataKey: 'structureCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      inputPrefixIcon:<IndianRupee size={16} />,
      required:true
    },
  },
  {
    label: 'Other Cost ',
    dataKey: 'otherCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      inputPrefixIcon: <IndianRupee size={16} />,
      maxLength: 50,
      description: "(inc. Stamp Duty as applicable + 2% processing fee)"
    },
  },
  {
    label: 'Interest (%)',
    dataKey: 'interestRate',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Rate (%)',
      readonly: true,
      maxLength: 50,
      // inputSuffixIcon: <CustomTextIcon value="/year" />,
      description: "(per year)"
    },
  },
  {
    label: 'Interest Amount Per Year',
    dataKey: 'interestAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: 'Amount',
      readonly: true,
      maxLength: 50,
      inputPrefixIcon: <IndianRupee size={16} />,
      // inputSuffixIcon: <CustomTextIcon value="/year" />
    },
  },
  {
    label: 'Total Project Cost',
    dataKey: 'totalProjectCost',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      maxLength: 50,
    },
  },
  {
    label: 'Returns Per Year (%)',
    dataKey: 'returnPerYearRate',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      readonly: true,
      maxLength: 50,
      // inputSuffixIcon: <CustomTextIcon value="/year" />
    },
  },

  {
    label: 'Return Per Year Interest (%)',
    dataKey: 'returnPerYearInterest',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      maxLength: 50,
      // inputSuffixIcon: <CustomTextIcon value="/year" />
    },
  },
  {
    label: 'Return Amount Per Year',
    dataKey: 'returnPerYearAmount',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      // inputSuffixIcon: <CustomTextIcon value="/year" />,
      maxLength: 50,
    },
  },

  {
    label: 'Downpayment By Offtaker',
    dataKey: 'downpaymentByOfftaker',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      inputPrefixIcon: <IndianRupee size={16} />,
      maxLength: 50,
    },
  },
  {
    label: 'Required Investment',
    dataKey: 'requiredInvestment',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      readonly: true,
      inputPrefixIcon: <IndianRupee size={16} />,
      maxLength: 50,
    },
  },
  {
    label: 'Offtaker Ownership (%)',
    dataKey: 'offtakerOwnershipPercentage',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      maxLength: 50,
    },
  },

  {
    label: 'Height Of Premises',
    dataKey: 'heightOfPremises',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      numberFormatted: true,
      placeholder: '',
      maxLength: 50,
      // options: AVAILABLE_SPACE_UNITS_OPTIONS.filter((o: optionTypes) => o.value == "ft")
      inputSuffixIcon: <CustomTextIcon value="ft." />
    },
  },
  {
    label: 'Annual Consumption',
    dataKey: 'annualConsumption',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      placeholder: '0',
      maxLength: 50,
      numberFormatted: true,
      inputSuffixIcon: <CustomTextIcon value="units" />
    },
  },
  {
    label: 'Total Token Supply',
    dataKey: 'totalTokenSupply',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 150,
    },
  },
  {
    label: 'Token Issued',
    dataKey: 'tokenIssued',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: '',
      maxLength: 150,
    },
  },

  {
    label: 'Token Validity',
    dataKey: 'tokenValidity',
    componentType: 'input',
    componentProps: {
      type: "text",
      readonly: true
    },
  },

  {
    label: 'Project Description',
    dataKey: 'projectDescription',
    componentType: 'textarea',
    componentProps: {
      placeholder: 'Description',
    },
  },

  {
    label: 'DC Commissioning',
    dataKey: 'dcCommissioning',
    componentType: 'options',
    componentProps: {
    
    },
  },

  {
    label: 'Project Title Image',
    dataKey: 'headerImage',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select image',
    },
  },
  {
    label: ' Site Images',
    dataKey: 'imagesAttachments',
    componentType: 'file_picker',
    componentProps: {
      fileSelectionType: 'multi',
      placeholder: 'Select images',
    },
  },
  {
    label: 'Site video',
    dataKey: 'videoAttachments',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
    },
  },
  // {
  //   label: 'Upload Agreement',
  //   dataKey: 'agreement',
  //   componentType: 'file_picker',
  //   componentProps: {
  //     placeholder: 'Select FILE',
  //   },
  // },
  {
    label: 'Aadhar Front Document',
    dataKey: 'aadharFrontDocument',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
    },
  },

  {
    label: 'Aadhar Back Document',
    dataKey: 'aadharBackDocument',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
    },
  },

  {
    label: 'Pan Document',
    dataKey: 'panDocument',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
    },
  },
  {
    label: 'Bill Front Image',
    dataKey: 'billFrontImage',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
    },
  },
  {
    label: 'Bill Back Image',
    dataKey: 'billBackImage',
    componentType: 'file_picker',
    componentProps: {
      placeholder: 'Select FILE',
    },
  },
  {
    label: 'Project Category',
    dataKey: 'projectCategory',
    componentType: 'options',
    componentProps: {
     
    },
  },
  {
    label: 'System Type',
    dataKey: 'systemType',
    componentType: 'options',
    componentProps: {
     
    },
  },
  {
    label: 'Connection Type',
    dataKey: 'connectionType',
    componentType: 'options',
    componentProps: {
     
    },
  },
  {
    label: 'Connection Load',
    dataKey: 'connectionLoad',
    componentType: 'input',
    componentProps: {
      type: "text",
      regex: /[^0-9]+/g,
      placeholder: '0',
      maxLength: 50,
      numberFormatted: true,
      inputSuffixIcon: <CustomTextIcon value="units" />
    },
  },
];


export const DATA_SEQUENCE = {
  initialProjectFinanceData: "Project Finance Details",
  initialProjectData: "Project Details",
  initialSiteData: "Site Details",
  initialPowerConsumptionData: "Power Consumption Details",
  // initialContactDetails: "Contact Details",
  // initialLocationDetails: "Location Details",
  // initialAttachments:"Attachments "
}