import { ImageOff } from 'lucide-react'
import React from 'react'
import { checkDecimal } from 'src/helpers'

type Props = {}

const ProductItemSm = (d: any) => {
    return (
        <div className='flex space-x-2 rounded-xl border border-gray-400 p-2 '>
            {d?.productImage ? <img src={d?.productImage} className='rounded-xl' style={{ width: "50px", height: "50px" }} /> : <div className='bg-gray-200 w-[50px] h-[50px] rounded-xl flex justify-center items-center'>
                <ImageOff className='text-gray-400' size={18} />
            </div>}
            <div className='flex-1'>
                <div>{d?.name}</div>
                <div className='flex justify-between' >
                    <div># {d?.quantity ? checkDecimal(d?.quantity,true) : null}</div>
                    <div>₹ {d?.price ? checkDecimal(d?.price,true) : null}/piece</div>
                </div>
            </div>
        </div>

    )
}

export default ProductItemSm