import OrderDetail from 'src/modules/Orders/OrderDetail'
import OrdersList from 'src/modules/Orders/OrdersList/index'

type Props = {}

const OrdersPageMain = (props: Props) => {
  return (
    <div className='py-4 space-y-4' >
      <div className='text-lg uppercase text-primary font-semibold'>Orders</div>
      <div>
          <OrdersList />
          {/* <OrderDetail /> */}
      </div>
    </div>
  )
}

export default OrdersPageMain