import React, { useState } from 'react'
import PulseLoader from 'src/shadcn/components/custom/PulseLoader'

type Props = {
    onChange: (e: any) => void
    value: number,
    isLoading: boolean
}

const QuantityCounter = ({ onChange, value,isLoading }: Props) => {

    // const [counter, setcounter] = useState<number>(1)

    return (
        <>
            {
                !isLoading ? <span className='flex items-center bg-slate-200 border border-slate-600 p-1 rounded-lg'>
                    <button className='flex disabled:bg-slate-400 justify-center items-center rounded-lg w-[20px] h-[20px] bg-slate-600 text-white' disabled={value == 1} onClick={() => onChange(value > 1 ? value - 1 : 1)}><span >-</span></button>
                    <input disabled value={value} onChange={(e: any) => {
                        onChange(e.target.value)
                    }} className='text-center' style={{ width: "50px" }} />
                    <button className='flex justify-center items-center rounded-lg w-[20px] h-[20px] bg-slate-600 text-white' onClick={() => onChange(value + 1)} ><span >+</span></button>
                </span> : <div className='w-[100px] h-[35px] flex justify-center items-center rounded-lg bg-slate-800/50 ' ><PulseLoader /></div>
            }
        </>

    )
}

export default QuantityCounter