import React from 'react' 
import NewConstructedProjectsDetails from 'src/modules/SubServices/NewConstructedProjects/NewConstructedProjectsDetails' 
 
type Props = {} 
 
const NewConstructedProjectsPage = (props: Props) => { 
    return ( 
        <div> 
            <NewConstructedProjectsDetails /> 
        </div> 
    ) 
} 
 
export default NewConstructedProjectsPage 
