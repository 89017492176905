import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/Rooftop";

export function allRooftopsRequest(query: string): Promise<AxiosResponse<any>> {
    query = query ? "?" + query.replace("page=1&items_per_page=30","") : ""
    return axiosInstance.get(`${API.ALL_ROOFTOPS}${query}`).then((d: AxiosResponse) => d.data)
}


export async function getRooftopDetailByIDApiRequest(id: any): Promise<AxiosResponse> {
    return axiosInstance.get(`${API.GET_DETAILS_ROOFTOP_BY_ID}/${id}`).then((d: AxiosResponse) => d.data);
}

// export async function getOrderSummaryApiRequest(query: any): Promise<AxiosResponse> {
//     return axiosInstance.get(`${API.ORDERS_SUMMARY}?${query}`).then((d: AxiosResponse) => d.data);
// }

