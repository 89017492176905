import 'leaflet/dist/leaflet.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AuthProvider } from './modules/Auth/Core';
import MainRoutes from './navigations';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster as SonarToaster } from 'sonner';
import { Toaster } from './shadcn/components/ui/toaster';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import 'react-photo-view/dist/react-photo-view.css';
import '@smastrom/react-rating/style.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider >
        <QueryClientProvider client={queryClient}>
          <MainRoutes />
          <SonarToaster duration={2000} position='top-center' richColors theme='light' />
          <Toaster />
        </QueryClientProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
