import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { USER_ROLES } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import CartItem from 'src/modules/Cart/CartItem'
import ListItemLoader from 'src/modules/Products/ListItemLoader'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { fetchCartItemsAction, getCartReducerRootState } from 'src/redux/reducers/cartReducer'
import { setOrderItems } from 'src/redux/reducers/orderReducer'
import { RootState } from 'src/redux/store'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}

const CartPageMain = (props: Props) => {

  const dispatch = useDispatch()
  const { cartItems, loading } = useSelector((state: RootState) => getCartReducerRootState(state))
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    currentUser?.role == USER_ROLES.OFF_TAKER && dispatch(fetchCartItemsAction())
    return () => { }
  }, [])

  return (
    <div className='py-4 space-y-4 w-100 ' >
      <div className='text-lg uppercase text-primary font-semibold'>Cart</div>
      <div className='grid grid-cols-4 gap-4'>
        {
          !loading ? cartItems && Array.isArray(cartItems) && cartItems.length > 0 ? cartItems.map((cartDetail: any) => <CartItem {...cartDetail} />) : null :
            [...new Array(3)].map((s: any) => {
              return <ListItemLoader />
            })
        }
      </div>
      <div className='w-full flex justify-center py-2  '>
        <RoundedButton onClick={() => {
          dispatch(setOrderItems(cartItems.map((product: any) => ({ ...product.product, quantity: product.quantity }))))
          navigate("/" + MAIN_ROUTES_CONST.create_order)
        }} >Order Now</RoundedButton>
      </div>
    </div>
  )
}

export default CartPageMain