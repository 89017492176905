import React from "react";
import { ISolarPlanArr, SolarPlanArr } from "./Component/modules";
import SolarplanCard from "./Component/SolarplanCard";
import { useSelector } from "react-redux";
import { getTempDataRootState } from "src/redux/reducers/tempDataSlice";

const SolarPlan = () => {

  const { quotationDetails } = useSelector((state: any) => getTempDataRootState(state))

  return (
    <div className="font-sans mt-2">
      <div className="text-[#000000B2] font-bold text-[20px]">
        Select the Solar Plan that Powers your Growth.
      </div>
      <div className="text-[16px] font-normal text-[#191D2380]">
        Read all plans or contact us we will guide you better.
      </div>

      <div className="w-full grid grid-cols-3 mt-8 gap-12">
        {SolarPlanArr.map(
          (
            {
              benefits,
              emiPrice,
              name,
              overAllPrice,
              projectPrice,
              unlockPrice,
            },
            index: number
          ) => {

            projectPrice = quotationDetails?.projectPrice
            overAllPrice = {
              discountPrice: quotationDetails?.totalProjectCost,
              totalPrice: quotationDetails?.totalProjectCost
            }
            emiPrice = {
              discountPrice: quotationDetails?.emiAmount,
              totalPrice: quotationDetails?.emiAmount
            }

            return (
              <SolarplanCard
                benefits={benefits}
                emiPrice={emiPrice}
                name={name}
                overAllPrice={overAllPrice}
                projectPrice={projectPrice}
                unlockPrice={unlockPrice}
                key={name}
              />
            )
          }
        )}
      </div>
    </div>
  );
};

export default SolarPlan;
