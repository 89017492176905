import React from 'react'
import { IMG_P2P_ENERGY_BENEFITS_BG, IMG_P2P_ENERGY_OUR_PRODUCTS } from 'src/helpers'

type Props = {}

const DATA = [
    {
        "title": "Intraday trading",
        "description": "Buy and Sell energy within the same day to balance short term grid fluctuations based on real-time demand and supply."
    },
    {
        "title": "DayAhead Trading",
        "description": "Trade energy one day in advance, allowing secure pricing and better planning for energy needs before delivery."
    },
    {
        "title": "Long Term PPA",
        "description": "A long-term contract between an energy producer and buyer, ensuring stable energy pricing over 10-20 yearse for reduced market risk,"
    }
]

const P2pEnergyOurProducts = (props: Props) => {
    return (
        <div className='h-[500px] p-8' style={{ backgroundImage: "url(" + IMG_P2P_ENERGY_OUR_PRODUCTS + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className='max-w-[80vw] mx-auto'>
                <div className='flex flex-col justify-center items-center space-y-2'>
                    <span className='text-sm uppercase text-white '>Peer-to-Peer Energy Trading for Smart Savings</span>
                    <span className='h-[2px] bg-primary w-[50px]'></span>
                    <span className='font-bold text-3xl text-white'>Our Products</span>
                </div>
                <div className=' grid grid-cols-3 gap-4 mt-16 ' >
                    {DATA.map((d: any) => {
                        return <div className='p-8 bg-white rounded-xl space-y-4 '>
                            <div className='text-primary text-center font-bold'>{d.title}</div>
                            <div className='text-center'>{d.description}</div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default P2pEnergyOurProducts