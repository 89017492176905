import { configureStore } from '@reduxjs/toolkit';
import projectDetailsSlice from './reducers/projectDetailsSlice';
import tempDataSlice from './reducers/tempDataSlice';
import cartReducer from './reducers/cartReducer';
import orderReducer from './reducers/orderReducer';


export const store = configureStore({
  reducer: {
    projectDetailsReducer: projectDetailsSlice,
    cart:cartReducer,
    order: orderReducer,
    tempDataReducer: tempDataSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    thunk: true,
  }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;