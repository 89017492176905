import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { INITIAL_LAND_ONBOARD_FORM_DATA } from 'src/modules/LandRegistration/AllLands/modules'
import LandDetailView from 'src/modules/LandRegistration/LandDetailView'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { getLandRegistrationDetailApiRequest } from 'src/services/requests/LandRegistration'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}


const LAND_IMG_SAMPLE = "https://images.pexels.com/photos/6876537/pexels-photo-6876537.jpeg"

const LandDetailsPage = (props: Props) => {



    const navigate = useNavigate()

    const { id } = useParams()

    const [orderDetails, setorderDetails] = useState<any>(null)
    const [isLoading, setisLoading] = useState<boolean>(false)


    useEffect(() => {
        if (id) {
            handleFetchOrderDeatilsByOrderID()
        }
        else navigate('/'+ MAIN_ROUTES_CONST.lands)
        return () => { }
    }, [id])


    const handleFetchOrderDeatilsByOrderID = catchAsync(async () => {
        setisLoading(true)
        return id && await getLandRegistrationDetailApiRequest(id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setorderDetails(result.data)
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

    return (
        <div className='bg-orange-50 '>
            <div className='h-[400px] bg-gray-400 w-full ' style={{ backgroundImage: 'url(' + LAND_IMG_SAMPLE + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }} >
            </div>
            <div className='max-w-[80vw] mx-auto p-4 -mt-[250px] bg-white rounded-xl p-4'>
                <div className='text-primary font-bold mx-4 my-4 text-xl'>Land Details</div>
                <LandDetailView data={orderDetails || INITIAL_LAND_ONBOARD_FORM_DATA} />
                <div className='flex justify-center py-4'>
                    <RoundedButton className='' onClick={() => navigate(-1)} variant={'outline'}>Back</RoundedButton>
                </div>
            </div>
            <LoadingDialog isOpen={isLoading} message='Fetching Land Details...'  /> 
        </div>

    )
}

export default LandDetailsPage