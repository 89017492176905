import React, { useMemo, useState } from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectSeparator, SelectTrigger, SelectValue } from '../ui/select'
import { Cross, X } from 'lucide-react'
import { Separator } from '@radix-ui/react-select'
import { cn, optionTypes } from 'src/helpers'
import { ClassNameValue } from 'tailwind-merge'


type Props = {
    options?: optionTypes[],
    placeholder?: string,
    value: string,
    onChange?: (e: any) => void,
    clearable?: boolean,
    classNames?: ClassNameValue
}

const Dropdown = ({ options, onChange, value, clearable = false, classNames }: Props) => {

    const label = useMemo(() => {
        if (options && Array.isArray(options) && options.length > 0 && value) {
            return options.find((d: optionTypes) => d.value == value)?.label
        }
        return ""
    }, [options, value])

    return (
        <Select value={value} onValueChange={(e: any) => {
            let value = e == "clear" ? "" : e
            onChange && onChange(value)
        }}  >
            <SelectTrigger clearable={clearable} onClear={() => onChange && onChange("")} className="bg-white text-black  w-[180px] ">
                <div className={cn(' flex justify-between items-center w-full ')}>
                    <div>{(label) || <div className='text-gray-400' >Select</div>} </div>
                </div>
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {options?.map(({ label, value }: optionTypes) => {
                        return <SelectItem value={value}>{label}</SelectItem>
                    })}
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}
export default Dropdown