import React from 'react'
import { IMG_FAQ } from 'src/helpers'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/shadcn/components/ui'

type Props = {}

const DATA = [
    {
      "question": "What is a Peer-to-Peer (P2P) Energy Trading?",
      "answer": "Peer-to-Peer (P2P) energy trading is an innovative approach to energy distribution that allows individuals or entities to buy and sell energy"
    },
    {
      "question": "How does the P2P application work?",
      "answer": ""
    },
    {
      "question": "How do I update the P2P application?",
      "answer": ""
    },
    {
      "question": "What should I do if I forget my password?",
      "answer": ""
    },
    {
      "question": "How can I contact customer support?",
      "answer": ""
    }
  ]

const P2pFaq = (props: Props) => {
    return (
        <div className='p-[100px]'>
            <div className='max-w-[80vw] mx-auto grid grid-cols-4 '>
                <div className='col-span-2 space-y-8 '>
                    <div className='space-y-2'>
                        <div>Frequently Asked Questions</div>
                        <div className='h-[2px] bg-primary w-[80px]'></div>
                    </div>
                    <div className='relative'>
                        <div className='text-3xl font-bold'>Need Help? Check Our <br/> FAQs</div>
                        <img src={IMG_FAQ} style={{ width: "150px" }} className='absolute top-[50px] left-[100px]' />
                    </div>
                </div>
                <div className='col-span-2'>
                    <div className=" mx-auto p-4">
                        {DATA.map((d: any) => {
                            return <Accordion type="single" collapsible>
                                <AccordionItem className='border-black border-b-2 border-dotted border-t-0 border-r-0 border-l-0 ' value="item-1">
                                    <AccordionTrigger><div className='font-bold '>{d.question}</div></AccordionTrigger>
                                    <AccordionContent>
                                        {d.answer}
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default P2pFaq