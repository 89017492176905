
import React, { useMemo } from 'react'
import OrderItems from '../OrderItems'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import OrderListLoader from '../OrderListLoader'

type Props = {}


const Main = () => {

    const apiresp = useQueryResponseData()
    const all_orders = useMemo(() => apiresp, [apiresp])
    const isLoading = useQueryResponseLoading()

    return (<div className='space-y-2'>
        {
            !isLoading ? all_orders.map((d: any) => {
                return <OrderItems {...d} />
            }) : [...new Array(3)].map((_) => <OrderListLoader />)
        }
    </div>)
}

const OrdersList = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default OrdersList