import { Outlet } from 'react-router-dom';
import './App.css';
import { AuthInit } from './modules/Auth/Core';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchGeoLocationAction } from './redux/reducers/tempDataSlice';

function App() {

  const dispatch = useDispatch()

  return (
    <AuthInit>
      <Outlet />
    </AuthInit>
  );
}

export default App;
