import React from "react";
import a from "public/media/svg/image/p2pTradingBackgroungImg.svg";
import { toAbsoluteUrl } from "src/helpers";
import { Button } from "src/shadcn/components/ui";
import { Navigate, useNavigate } from "react-router-dom";
import { MAIN_ROUTES_CONST } from "src/navigations/modules";
const P2pTrading = () => {

  const navigate = useNavigate()

  const backgroundImg = toAbsoluteUrl(
    "/media/jpg/p2p_enenrgy_our_products.png"
  );
  return (
    <div
      id="welcome-p2p-energy-section"
      className="w-full h-[535px] font-sans text-white pl-20 flex flex-col justify-center space-y-12"
      style={{
        background: `url(${backgroundImg})`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex items-center space-x-2">
        <div>
          <div className="w-[15px] h-[15px] bg-primary"></div>
          <div className="w-[15px] h-[15px] bg-primary ml-2 -mt-[10px]"></div>
        </div>
        <h1 className=" font-bold text-4xl">P2P Trading</h1>
      </div>

      <div className="ml-[25px] space-y-4">
        <div className="text-sm font-normal ">
          Platform enables secure peer-to-peer <br /> energy trading between
          prosumers and <br /> consumers. Utilizing blockchain <br /> technology,
          transactions are transparent <br /> and efficient, ensuring trust and{" "}
          <br /> eliminating intermediaries.
        </div>

        <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.p2p_energy_trading_landing)} className="w-[150px] ">Learn More</Button>
      </div>
    </div>
  );
};

export default P2pTrading;
