import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/NewConstructedProjects";



export function getNewConstructedDetailApiRequest(id: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.NEW_CONSTRUCTED_DETAIL_BY_ID}/${id}`).then((d: AxiosResponse) => d.data)
}


export function allNewConstructedRequestsApiRequest(query: string): Promise<AxiosResponse<any>> {
    query = query ? "?" + query.replace("page=1&items_per_page=30","") : ""
    return axiosInstance.get(`${API.ALL_NEW_CONSTRUCTED_REQUESTS}${query}`).then((d: AxiosResponse) => d.data)
}
