import React from 'react'
import { toAbsoluteUrl } from 'src/helpers'

type Props = {}

const Mission = (props: Props) => {
    return (
        <div>
            <div className=' grid grid-cols-4 ' >
                <div className='h-[120px] w-[120px] mx-auto bg-gray-200 flex justify-center items-center rounded-full'>
                    <img src={toAbsoluteUrl("/media/png/mission_icon.png")} style={{  height: "70px" }} />
                </div>
                <div className='col-span-3 flex flex-col space-y-1 '>
                    <div className='font-bold text-xl' >Mission</div>
                    <div>
                    Making Renewable Energy Accessible through Digital Innovation: Our mission is to democratize clean energy by developing user-friendly digital platforms that connect individuals and businesses to sustainable power solutions, promoting fair energy access and empowering communities worldwide.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mission