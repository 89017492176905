import { FormField, NAME_REGEX, TYPE_OF_SOLAR_PLANT_OPTIONS, YEARS_RANGE_OPTIONS } from 'src/helpers';
import CustomTextIcon from 'src/shadcn/components/custom/CustomeSymbols/CustomTextIcon';

// Interface for Personal Details
export interface PersonalDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  alternatePhone?: string; // Optional
  address: string;
  city: string;
  state: string;
  pinCode: string;
}

// Interface for Project Information
export interface ProjectInformation {
  projectLocation: string;
  projectCity: string;
  projectState: string;
  projectCountry?: string; // Default value can be handled in the application logic
  totalInstalledCapacity: string; // in MW
  typeOfSolarPlant: string;
  connectionType: string;
  yearOfInstallation: string; // Assuming it's a year (e.g., 2""23)
  plantStatus: string;
  solarTechnologyUsed: string;
  projectOwnershipType: string;
}

// Interface for Financial and Ownership
export interface FinancialAndOwnership {
  totalInvestment: string;
  expectedSellingPrice: string;
  currentOwner: string;
  percentageOwnershipToBeSold: string; // Assuming this is a percentage
}

// Interface for Additional Project Information
export interface AdditionalProjectInformation {
  operationalSinceYears: string;
  annualEnergyProduction: string; // Assuming in kWh or similar unit
  outstandingLiabilities: string;
  powerPurchaseAgreement: string;
  subsidiesReceived: string;
  subsidyAmount: string,
  typeOfSolarModulesUsed: string;
  inverterModel: string;
  inverterBrand: string;
  inverterCapacity: string; // Assuming in kW
  batteryModel: string;
  batteryBrand: string;
  batteryCapacity: string; // Assuming in kWh
  availabilityMonitoringSystem: string;
  expectedTimeframeForSale: string;
  imgUrl: string
}

export interface Documents {
  powerPurchaseDoc: string,
  ownershipDoc: string,
  legalClearanceDoc: string,
  anyOtherRelevantDoc: string[]
}



export type INewConstructedProjectsForm = PersonalDetails & ProjectInformation & FinancialAndOwnership & AdditionalProjectInformation & Documents

// Main interface combining all sections
export interface INewConstructedProjects {
  personalDetails: PersonalDetails;
  projectInformation: ProjectInformation;
  financialAndOwnership: FinancialAndOwnership;
  additionalProjectInformation: AdditionalProjectInformation;
  documents: Documents
}

// Initial constant for Personal Details
export const initialPersonalDetails: PersonalDetails = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  alternatePhone: "", // Optional
  address: "",
  city: "",
  state: "",
  pinCode: "",
};

// Initial constant for Project Information
export const initialProjectInformation: ProjectInformation = {
  projectLocation: "",
  projectCity: "",
  projectState: "",
  projectCountry: "", // Default value can be handled in the application logic
  totalInstalledCapacity: "", // in MW
  typeOfSolarPlant: "",
  connectionType: "",
  yearOfInstallation: "", // Assuming it's a year (e.g., 2""23)
  plantStatus: "",
  solarTechnologyUsed: "",
  projectOwnershipType: "",
};

// Initial constant for Financial and Ownership
export const initialFinancialAndOwnership: FinancialAndOwnership = {
  totalInvestment: "", // Example value in currency
  expectedSellingPrice: "", // Example value in currency
  currentOwner: "",
  percentageOwnershipToBeSold: "", // Assuming this is a percentage
};

// Initial constant for Additional Project Information
export const initialAdditionalProjectInformation: AdditionalProjectInformation = {
  operationalSinceYears: "",
  annualEnergyProduction: "", // Assuming in kWh or similar unit
  outstandingLiabilities: "",
  powerPurchaseAgreement: "",
  subsidiesReceived: "",
  subsidyAmount: "",
  typeOfSolarModulesUsed: "",
  inverterModel: "",
  inverterBrand: "",
  inverterCapacity: "", // Assuming in kW
  batteryModel: "",
  batteryBrand: "",
  batteryCapacity: "", // Assuming in kWh
  availabilityMonitoringSystem: "",
  expectedTimeframeForSale: "",
  imgUrl: ""
};


export const initialDocuments = {
  powerPurchaseDoc: "",
  ownershipDoc: "",
  legalClearanceDoc: "",
  anyOtherRelevantDoc: [""]
}


export const INIT_NEW_CONSTRUCTED_PROJECTS = {
  ...initialPersonalDetails,
  ...initialProjectInformation,
  ...initialFinancialAndOwnership,
  ...initialAdditionalProjectInformation,
  ...initialDocuments
}


//  export const newConstructedProjectsInit: INewConstructedProjects = {
//     personalDetails: initialPersonalDetails,
//     projectInformation: initialProjectInformation,
//     financialAndOwnership: initialFinancialAndOwnership,
//     additionalProjectInformation: initialAdditionalProjectInformation,
//   };


export const PROJECT_FORM_STRUCTURE = {
  "Personal Details": initialPersonalDetails,
  "Project Details": initialProjectInformation,
  "Financial And Ownership Details": initialFinancialAndOwnership,
  "Additional Project Information": initialAdditionalProjectInformation,
  "Documents": initialDocuments
}

export const NEW_CONSTRUCTED_PROJECTS_FORM_FIELDS: FormField[] = [
  {
    label: 'Full Name',
    dataKey: 'firstName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your first name',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Last Name',
    dataKey: 'lastName',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your last name',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Email Address',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: "email",
      placeholder: 'Enter your email address',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Phone Number',
    dataKey: 'phoneNumber',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your phone number',
      maxLength: 10,
      regex: /^[0-9]*$/,
      required: true,
    },
  },
  {
    label: 'Alternate Phone Number',
    dataKey: 'alternatePhone',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter alternate phone number',
      maxLength: 10,
      required: false,
    },
  },
  {
    label: 'Address',
    dataKey: 'address',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your address',
      maxLength: 250,
      required: true,
    },
  },
  {
    label: 'City',
    dataKey: 'city',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your city',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'State',
    dataKey: 'state',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter your state',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Pin Code',
    dataKey: 'pinCode',
    componentType: 'input',
    componentProps: {
      type: "number",
      placeholder: 'Enter your pin code',
      maxLength: 6,
      required: true,
    },
  },
  {
    label: 'Project Location',
    dataKey: 'projectLocation',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter project location',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Project City',
    dataKey: 'projectCity',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter project city',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Project State',
    dataKey: 'projectState',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter project state',
      maxLength: 150,
      required: true,
    },
  },
  {
    label: 'Project Country',
    dataKey: 'projectCountry',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter project country',
      maxLength: 150,
      required: false,
    },
  },
  {
    label: 'Total Installed Capacity (MW)',
    dataKey: 'totalInstalledCapacity',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: 'Enter total installed capacity',
      required: true,
    },
  },
  {
    label: 'Type of Solar Plant',
    dataKey: 'typeOfSolarPlant',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
    },
  },
  {
    label: 'Connection Type',
    dataKey: 'connectionType',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
    },
  },
  {
    label: 'Year of Installation',
    dataKey: 'yearOfInstallation',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter year of installation',
      required: true,
    },
  },
  {
    label: 'Plant Status',
    dataKey: 'plantStatus',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
    },
  },
  {
    label: 'Solar Technology Used',
    dataKey: 'solarTechnologyUsed',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
    },
  },
  {
    label: 'Project Ownership Type',
    dataKey: 'projectOwnershipType',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
    },
  },
  {
    label: 'Total Investment in Project',
    dataKey: 'totalInvestment',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      inputPrefixIcon: <CustomTextIcon value='₹' />,
      placeholder: 'Enter total investment amount',
      required: true,
    },
  },
  {
    label: 'Expected Selling Price for Project Rights',
    dataKey: 'expectedSellingPrice',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      inputPrefixIcon: <CustomTextIcon value='₹' />,
      placeholder: 'Enter expected selling price',
      required: true,
    },
  },
  {
    label: 'Current Owner of the Project',
    dataKey: 'currentOwner',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter current owner name',
      required: true,
    },
  },
  {
    label: 'Percentage of Ownership to be Sold',
    dataKey: 'percentageOwnershipToBeSold',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      inputSuffixIcon: <CustomTextIcon value='%' />,
      placeholder: 'Enter percentage of ownership',
      required: true,
    },
  },
  {
    label: 'Operational Since (Years)',
    dataKey: 'operationalSinceYears',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter operational years',
      required: true,
    },
  },
  {
    label: 'Annual Energy Production',
    dataKey: 'annualEnergyProduction',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      inputSuffixIcon: <CustomTextIcon value='mW' />,
      placeholder: 'Enter annual energy production',
      required: true,
    },
  },
  {
    label: 'Outstanding Liabilities',
    dataKey: 'outstandingLiabilities',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      inputPrefixIcon: <CustomTextIcon value='₹' />,
      placeholder: 'Amt',
      required: true,
    },
  },
  {
    label: 'Power Purchase Agreement',
    dataKey: 'powerPurchaseAgreement',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
    },
  },
  {
    label: 'Subsidies Received',
    dataKey: 'subsidiesReceived',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
    },
  },
  {
    label: 'Subsidies Amount',
    dataKey: 'subsidyAmount',
    componentType: 'input',
    componentProps: {
      type: 'number',
      numberFormatted: true,
      required: true,
      conditionalHide: {
        expectedValue: "Yes",
        formKey: "subsidiesReceived"
      }
    },
  },
  {
    label: 'Type of Solar Modules Used',
    dataKey: 'typeOfSolarModulesUsed',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
    },
  },
  {
    label: 'Inverter Model',
    dataKey: 'inverterModel',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter inverter model',
      required: true,
    },
  },
  {
    label: 'Inverter Brand',
    dataKey: 'inverterBrand',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter inverter brand',
      required: true,
    },
  },
  {
    label: 'Inverter Capacity (kW)',
    dataKey: 'inverterCapacity',
    componentType: 'input',
    componentProps: {
      type: "number",
      numberFormatted: true,
      placeholder: '',
      inputSuffixIcon: <CustomTextIcon value='kW' />,
      required: true,
    },
  },
  {
    label: 'Battery Model',
    dataKey: 'batteryModel',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter battery model',
      required: true,
    },
  },
  {
    label: 'Battery Brand',
    dataKey: 'batteryBrand',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter battery brand',
      required: true,
    },
  },
  {
    label: 'Battery Capacity (kW)',
    dataKey: 'batteryCapacity',
    componentType: 'input',
    componentProps: {
      type: 'number',
      numberFormatted: true,
      placeholder: '',
      inputSuffixIcon: <CustomTextIcon value='kW' />,
      required: true,
    },
  },
  {
    label: 'Availability Monitoring System',
    dataKey: 'availabilityMonitoringSystem',
    componentType: 'options',
    componentProps: {
      options: [],
      required: true,
    },
  },
  {
    label: 'Expected Timeframe for Sale',
    dataKey: 'expectedTimeframeForSale',
    componentType: 'input',
    componentProps: {
      type: "text",
      placeholder: 'Enter expected timeframe for sale',
      required: true,
    },
  },
  {
    label: 'Additional Documents',
    dataKey: 'documentsUpload',
    componentType: 'file_picker',
    componentProps: {
      fileSelectionType: "multi",
      required: true,
    },
  },
  {
    label: 'Power Purchase Document',
    dataKey: 'powerPurchaseDoc',
    componentType: 'file_picker',
    componentProps: {
      conditionalHide: {
        expectedValue: "Yes",
        formKey: "powerPurchaseAgreement"
      },
      required: true
    },
  },
  {
    label: 'OwnerShip Document',
    dataKey: 'ownershipDoc',
    componentType: 'file_picker',
    componentProps: {
      required: true
    },
  },
  {
    label: 'Legal Clearance Document',
    dataKey: 'legalClearanceDoc',
    componentType: 'file_picker',
    componentProps: {
      required: true
    },
  },
  {
    label: 'Any Other Relevant Document',
    dataKey: 'anyOtherRelevantDoc',
    componentType: 'file_picker',
    componentProps: {
      fileSelectionType: "multi",
      required: true
    },
  },
];

export const FILTERS_DATA = [
  {
    name: 'Project City',
    dataKey: "projectCity",
    type: 'text',
    regex: NAME_REGEX,
  },
  {
    name: 'Project State',
    dataKey: "projectState",
    type: 'text',
    regex: NAME_REGEX,
  },
  {
    name: 'Project Country',
    dataKey: "projectCountry",
    type: 'text', // Optional, but keeping it as text for consistency
    regex: NAME_REGEX,
  },
  {
    name: 'Annual Energy Production',
    dataKey: "annualEnergyProduction",
    type: 'range',
  },
  {
    name: 'Total Installed Capacity',
    dataKey: "totalInstalledCapacity",
    type: 'range',
  },
  {
    name: 'Type of Solar Plant',
    dataKey: "typeOfSolarPlant",
    type: 'dropdown',
    options: TYPE_OF_SOLAR_PLANT_OPTIONS,
  },
  {
    name: 'Year of Installation',
    dataKey: "yearOfInstallation",
    type: 'dropdown',
    options: YEARS_RANGE_OPTIONS,
  },
  {
    name: 'Total Investment in Project',
    dataKey: "totalInvestment",
    type: 'range',
  },
  {
    name: 'Expected Selling Price for Project Rights',
    dataKey: "expectedSellingPrice",
    type: 'range',
  },
];


export const FILTER_STATE = {
  projectCity: "",
  projectState: "",
  projectCountry: "",
  annualEnergyProduction: {
    min: "",
    max: ""
  },
  totalInstalledCapacity: {
    min: "",
    max: ""
  },
  typeOfSolarPlant: "",
  yearOfInstallation: "",
  totalInvestment: {
    min: "",
    max: ""
  },
  expectedSellingPrice: {
    min: "",
    max: ""
  },
}

export interface IFILTER_STATE {
  projectCity: string;
  projectState: string;
  projectCountry: string;
  annualEnergyProduction: {
    min: string,
    max: string
  };
  totalInstalledCapacity: {
    min: string,
    max: string
  };
  typeOfSolarPlant: string;
  yearOfInstallation: string;
  totalInvestment: {
    min: string,
    max: string
  };
  expectedSellingPrice: {
    min: string,
    max: string
  };
}