import React from 'react'
import { IMG_EMPTY_ROUND, IMG_P2P_ENERGY_JOINING_TEXTURE } from 'src/helpers'

type Props = {}

const DATA = [
    {
        value: "100 MW",
        title: "Energy Traded"
    },
    {
        value: "200 KG",
        title: "CO2 Prevented"
    },
    {
        value: "10+",
        title: "Trees Planted"
    }
]

const P2pEnergyJoiningSection = (props: Props) => {
    return (
        <div className='h-[700px] p-8' style={{ backgroundImage: "url(" + IMG_P2P_ENERGY_JOINING_TEXTURE + ")", backgroundRepeat: "no-repeat", backgroundSize: "100% 45%", backgroundPosition: "top" }}>
            <div className='max-w-[80vw] mx-auto'>
                <div className='flex flex-col  space-y-2'>
                    <span className='text-sm uppercase '>Platform Impact </span>
                    <span className='h-[2px] bg-primary w-[50px]'></span>
                    <span className='font-bold text-3xl'>Join Thousands of Users Making <br /> a Difference </span>
                </div>
                <div className='mx-auto grid grid-cols-3 gap-4 mt-16 max-w-[60vw]'>
                    {
                        DATA.map((d: any) => {
                            return <div className='mx-auto w-[200px] h-[200px] flex justify-center items-center' style={{ backgroundImage: "url(" + IMG_EMPTY_ROUND + ")", backgroundRepeat: "no-repeat", backgroundSize: "COVER", backgroundPosition: "top" }}>
                                <div className='flex flex-col space-y-1'>
                                    <div className='text-primary font-bold uppercase text-4xl text-center'>{d.value}</div>
                                    <div className='text-sm font-semibold text-center'>{d.title}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className='mt-16 flex justify-center items-center'>
                    <div className='flex flex-col justify-center items-center space-y-2 max-w-[50vw] mx-auto'>
                        <span className='text-md  font-bold uppercase'>Powered by Blockchain </span>
                        <span className='h-[2px] bg-primary w-[50px]'></span>
                        <span className='text-center'>This platform enables secure peer-to-peer energy trading between prosumers and consumers. Utilizing blockchain technology, transactions are transparent and efficient, ensuring trust and eliminating intermediaries.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default P2pEnergyJoiningSection