import React from 'react'
import EpcItem from './EpcItem'

type Props = {
  data: any[]
}


// const data = [
//   {
//     "companyName": "NovaGreen EPC",
//     "reviews": 50,
//     "location": "Noida",
//     "rating": 4.5,
//     "companyLogo": "https://pngtree.com/freepng/corporate-image-logo-free-logo-design-template_1026060.html",
//     "numberOfProjects": 98,
//     "capacityInstalled": 90,
//     "yearsOfExperience": 17
//   },
//   {
//     "companyName": "SolarMax Energy",
//     "reviews": 25,
//     "location": "Delhi",
//     "rating": 4.2,
//     "companyLogo": "https://pngtree.com/freepng/color-company-logo-design_3564239.html",
//     "numberOfProjects": 50,
//     "capacityInstalled": 50,
//     "yearsOfExperience": 10
//   },
//   {
//     "companyName": "GreenTech Solutions",
//     "reviews": 100,
//     "location": "Mumbai",
//     "rating": 4.8,
//     "companyLogo": "https://pngtree.com/freepng/creative-company-logo_1187566.html",
//     "numberOfProjects": 150,
//     "capacityInstalled": 120,
//     "yearsOfExperience": 20
//   },
//   {
//     "companyName": "EcoFriendly Power",
//     "reviews": 30,
//     "location": "Bangalore",
//     "rating": 4.1,
//     "companyLogo": "https://pngtree.com/freepng/vector-creative-red-logo_701575.html",
//     "numberOfProjects": 70,
//     "capacityInstalled": 60,
//     "yearsOfExperience": 12
//   },
//   {
//     "companyName": "Renewable Energy Corp",
//     "reviews": 80,
//     "location": "Chennai",
//     "rating": 4.6,
//     "companyLogo": "https://pngtree.com/freepng/personalized-color-hands-vector-logo_638866.html",
//     "numberOfProjects": 120,
//     "capacityInstalled": 100,
//     "yearsOfExperience": 18
//   }
// ]


const EpcItems = ({ data }: Props) => {
  return (
    data.length > 0 ?
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-2'>
        {data.map((d: any) => {
          return <EpcItem {...d} />
        })}
      </div>
      : <div className='w-100 h-[400px] flex justify-center items-center'>No Content</div>
  )
}

export default EpcItems