import React from 'react'

import ServicesMain from 'src/modules/ReStructure/Services/ServicesMain'

const Services = () => {
  return (
    <div className='w-full'>
     <ServicesMain/>
    </div>
  )
}

export default Services