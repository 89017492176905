
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
    Card, CardContent
} from "src/shadcn/components/ui"
import Banner1 from "./Banners/Banner1"
import { OUR_TEAM } from "./modules"
import { toAbsoluteUrl } from "src/helpers"

export function OurTeamCarousal() {
    return (
        <div className="space-y-8">

            <div className='grid grid-cols-2 gap-4 max-w-[40vw] mx-auto'>

                {
                    OUR_TEAM.filter((_, indx: number) => indx <= 1).map(({ img, name, designation }: any) => {
                        return <div className='h-full w-full flex flex-col items-center'>
                            <img src={toAbsoluteUrl("/media/jpg/team/" + name + ".png")} className='h-[150px] rounded-xl' />
                            <div className='text-center mt-2 font-bold'>{name}</div>
                            <div className='text-center text-sm'>{designation}</div>
                        </div>
                    })
                }
                
            </div>
            <div className='grid grid-cols-4 gap-4'>
                {
                    OUR_TEAM.filter((_, indx: number) => indx > 1).map(({ img, name, designation }: any) => {
                        return <div className='h-full w-full flex flex-col items-center'>
                            <img src={toAbsoluteUrl("/media/jpg/team/" + name + ".png")} className='h-[150px] rounded-xl' />
                            <div className='text-center mt-2 font-bold '>{name}</div>
                            <div className='text-center text-sm'>{designation}</div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default OurTeamCarousal