import { Eye, Send, Star } from 'lucide-react'
import React, { useState } from 'react'
import { catchAsync, checkDecimal, PROJECT_IMAGE_URL, successTheme } from 'src/helpers'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Button } from 'src/shadcn/components/ui'
import { IProject } from '../Marketplace/modules'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getProjectDetailsRootState } from 'src/redux/reducers/projectDetailsSlice'
import RenderData from './RenderData'
import { useAuth } from '../Auth/Core'
import { useDispatch } from 'react-redux'
import { setShowLoginModule } from 'src/redux/reducers/tempDataSlice'
import { useNavigate } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { addToWishlistRequestApi } from 'src/services/requests/Wishlist'
import { toast } from 'sonner'
import EnquiryFormDialog from '../EnquiryForms/EnquiryFormDialog'

type Props = {

}

const ProjectLeftDetails = ({ }: Props) => {

    const { project_details: projectDetails } = useSelector((state: RootState) => getProjectDetailsRootState(state))
    const { currentUser } = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showEnquiryDailog, setshowEnquiryDailog] = useState<boolean>(false)

    const [isLoading, setisLoading] = useState(false)

    const handleAddToWishlist = catchAsync(async () => {
        setisLoading(true)
        return currentUser?.id && projectDetails?._id && await addToWishlistRequestApi({
            userId: currentUser?.id,
            projectId: projectDetails?._id
        })
    }, (result: any) => {
        setisLoading(false)
        if (result?.status == 201) {
            toast.success("Registration successful,OTP sent on you email.", { style: successTheme })
        }
    }, () => {
        setisLoading(false)
    })

    return (
        <div className='space-y-2'>
            <div className=' uppercase text-gray-400 border-primary border-b-2 py-2 text-sm '>Project ID : <span className='font-bold'># {projectDetails?.project_id}</span></div>
            <div className='grid grid-cols-2 gap-4 py-2'>
                <img src={projectDetails?.imagesAttachments[0]} style={{ width: "100%", height: "150px" }} />
                <div className='flex flex-col space-y-2'>
                    <div className='text-gray-400'>Sell Price</div>
                    <div className='flex space-x-2 text-sm' ><span className='flex mx-2'>₹ <RenderData value={projectDetails?.projectCost ? checkDecimal(projectDetails?.projectCost, true) : 0} /> </span> <span>(Excl. VAT)</span></div>
                </div>
            </div>
            <div className=' uppercase text-gray-400 border-primary border-b-2  py-2 text-sm '>Advertisement activity</div>
            <div className='flex space-x-2'>
                <div className='flex flex-1 space-x-2 items-center border  p-2 text-xs'>
                    <Eye size={16} className='text-gray-400' />
                    <span>View :</span>
                    <span></span>
                </div>
                <div className='flex flex-1 space-x-2 items-center border  p-2 text-xs'>
                    <Star size={16} className='text-gray-400' />
                    <span>On wish list :</span>
                    <span>45</span>
                </div>
            </div>
            <div className='flex flex-col space-y-2'>
                {!currentUser ? <RoundedButton className='flex-1 text-sm mx-4' onClick={() => dispatch(setShowLoginModule(true))} >Display Project Data</RoundedButton> : null}
                {<div className='flex flex-col space-y-2'>
                    <RoundedButton disabled={!currentUser?.id} onClick={handleAddToWishlist} isLoading={isLoading} className='flex-1 space-x-2 text-sm mx-4'> <Star size={16} className='text-white' /><span>Add to wish list</span></RoundedButton>
                    <RoundedButton onClick={() => {  setshowEnquiryDailog(true)}} variant={'outline'} className='flex-1 space-x-2 text-sm mx-4'> <Send size={16} /><span>Send Request</span></RoundedButton>
                </div>}
            </div>
            <EnquiryFormDialog openState={showEnquiryDailog} isOpenState={(e: any) => {  setshowEnquiryDailog(e) }} />
        </div>
    )
}

export default ProjectLeftDetails