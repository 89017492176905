import React from 'react'
import { useNavigate } from 'react-router-dom'
import { cn, HERO_BANNER_3 } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { Button, CarouselItem } from 'src/shadcn/components/ui'

type Props = {}

const Banner3 = (props: Props) => {

  const navigate = useNavigate()

  return (
    <CarouselItem>
      <div
        className={cn(`w-full h-[650px] `)}
        style={{
          background: `url(${HERO_BANNER_3})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"

        }}
      >
        <div className=" w-[500px] space-y-3 ml-40">
          <div className='pt-[25vh]'>
            <div className='w-[400px]'>
              <div className='space-y-1'>
                <div className="font-sans font-bold text-4xl uppercase "><span className='text-primary'>P2P Energy</span> trading </div>
                <div className="font-sans font-bold text-4xl uppercase ">Solution</div>
              </div>
              <div className='h-[2px] bg-primary w-[380px] my-4'></div>
            </div>
            <div >

              <ul className='list-disc ml-4'>
                <li>Consumers save money,</li>
                <li>Producer maximizes income</li>
                <li>Through every additional unit sale</li>
                <li>Benefit from 20 years of performance tracking and record management</li>
              </ul>
            </div>
            <div className='my-8'>
              P2P platform-Electreecity truly helps connecting rural <span className='font-bold'>Bharat</span> as a producer with urban <span className='font-bold'>India's</span> consumers in rooftop adoption.</div>

          </div>
          <div className='flex space-x-2'>
            <Button
              onClick={() => {
                const violation: any = document.getElementById("welcome-p2p-energy-section");
                window.scrollTo({
                  top: violation.offsetTop - 50,
                  behavior: "smooth",

                });
              }}

              className='w-[150px] bg-primary text-white hover:bg-primary hover:text-white'>Learn More</Button>
            <Button onClick={() => navigate("/" + MAIN_ROUTES_CONST.contact_us)} variant={'theamBlack'} className='w-[150px]'>Contact Us</Button>
          </div>
        </div>
      </div>
    </CarouselItem>
  )
}

export default Banner3