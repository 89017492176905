import React from 'react'
import FilterMain from '../../modules/EpcContractors/FilterMain'
import EpcMain from 'src/modules/EpcContractors/EpcMain'
import { ArrowUpRight, MoveUpRight } from 'lucide-react'

type Props = {}

const EpcContractorMain = (props: Props) => {
    return (
        <div className=' max-w-[90vw] mx-auto'>
            <div className='space-y-2 py-4'>
                <div className='my-8 text-xl uppercase space-x-2 flex '><span><MoveUpRight className='text-primary ' /></span> <span className=' font-semibold' >EPC Contractors </span></div>
                <EpcMain />
            </div>
        </div>
    )
}

export default EpcContractorMain