import React, { useEffect, useMemo, useState } from 'react'
import FilterMain from './FilterMain'
import ListItems from './ListItems'
import { catchAsync, stringifyObjectToRequestQuery, stringifyRequestQuery } from 'src/helpers'
import { allManufacturersRequest } from 'src/services/requests/Manufacturers'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'
import { Skeleton } from 'src/shadcn/components/ui/skeleton'
import { Separator } from 'src/shadcn/components/ui'
import ListItemLoader from './ListItemLoader'

type Props = {}

const Main = (props: Props) => {

    const aopiResp = useQueryResponseData()
    const { refetch } = useQueryResponse()
    const { state, updateState } = useQueryRequest()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => aopiResp, [aopiResp])


    return (

        <div className='flex space-x-2'>
            <div className='w-1/4 '>
                <div className='p-4 bg-orange-100 rounded-lg'>
                    <FilterMain onChange={(e: any) => {
                        if (stringifyObjectToRequestQuery(state.filter) != stringifyObjectToRequestQuery(e)) {
                            updateState({
                                filter: e
                            })
                        }
                        else refetch()
                    }} />
                </div>
            </div>
            <div className='w-3/4 '>
                {isLoading ? <div className='grid grid-cols-2 gap-4'> {[...new Array(5)].map((s: any) => {
                    return <ListItemLoader />
                })}</div> : <ListItems data={data} />}
            </div>
        </div>

    )
}


const ListMain = () => {
    return <QueryRequestProvider>
        <QueryResponseProvider>
            <Main />
        </QueryResponseProvider>
    </QueryRequestProvider>
}

export default ListMain