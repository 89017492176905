import { MapPin, MoveLeft } from 'lucide-react'
import React from 'react'
import SolarPowerGenaration from './Component/SolarPowerGenaration'
import WaterSaved from './Component/WaterSaved'
import CostomerJorneyOutcome from './Component/CostomerJorneyOutcome'
import { checkDecimal, toAbsoluteUrl } from 'src/helpers'
import SolarPlan from './Component/Solarplan/SolarPlan'
import SolarPlanAccordian from './Component/CostomerJorneyAccordian.tsx/SolarPlanAccordian'
import { useNavigate } from 'react-router-dom'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { getTempDataRootState } from 'src/redux/reducers/tempDataSlice'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Button } from 'src/shadcn/components/ui'

const CostomerJourneyMain = () => {
  const SolarDeal = toAbsoluteUrl("/media/svg/icons/SolarDeal.svg")
  const navigate = useNavigate()

  const { quotationDetails } = useSelector((state: RootState) => getTempDataRootState(state))


  return (
    <div className='w-full font-sans'>
      <div>
        <div className='flex items-center space-x-4'>
          <MoveLeft className='cursor-pointer ' onClick={() => navigate(`/${MAIN_ROUTES_CONST.welcome}`)} />
          <div className='font-bold text-2xl text-black'>{checkDecimal((quotationDetails?.unitPerMonth / 30).toFixed(2), true)} (kW/day)</div>
        </div>
        <div className='flex items-center space-x-4'>
          <MapPin className='text-primary w-6 h-6' />
          <div className='text-xl font-normal text-black'>Indore</div>
        </div>

      </div>

      <SolarPowerGenaration />
      <CostomerJorneyOutcome />

      <div className='flex justify-center items-center space-x-2 text-primary font-bold text-2xl mt-4'><div>Get the Best Solar Deals with Our Partners</div>
        <img src={SolarDeal} alt='solarDeal' />
      </div>
      <SolarPlan />

      <SolarPlanAccordian />
      {/* < WaterSaved/> */}
      <div className='flex justify-center py-4'><Button variant={'outlinePrimary'} className='rounded-full w-[150px]'>Back</Button></div>
    </div>
  )
}

export default CostomerJourneyMain