
import React from 'react'
import { ClassNameValue } from 'tailwind-merge'

type Props = {
    color?: ClassNameValue
}

const PulseLoader = ({ color = 'bg-white' }: Props) => {
    return (
        <div className="flex space-x-2">
            <div className={`w-2 h-2 ${color} rounded-full animate-pulse`} style={{ animationDelay: "0.1s" }}></div>
            <div className={`w-2 h-2 ${color} rounded-full animate-pulse`} style={{ animationDelay: "0.2s" }}></div>
            <div className={`w-2 h-2 ${color} rounded-full animate-pulse`} style={{ animationDelay: "0.3s" }}></div>
        </div>
    )
}

export default PulseLoader