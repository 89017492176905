import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BADGE_STATUS_VARIANT, catchAsync, checkDecimal, IS_SUCCESS_RESPONSE, ORDER_STATUS_ENUM } from 'src/helpers'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { cancelOrderApiRequest } from 'src/services/requests/Order'
import { Badge, Button } from 'src/shadcn/components/ui'
import { useQueryResponse } from './OrdersList/core/QueryResponseProvider'

type Props = {}

const OrderItems = (orderDetails: any) => {

  const navigate = useNavigate()
  const { refetch } = useQueryResponse()
  const [isCancelingOrder, setisCancelingOrder] = useState<boolean>(false)

  const handleCancelOrder = catchAsync(async () => {
    setisCancelingOrder(true)
    return await cancelOrderApiRequest(orderDetails?._id)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      refetch()
    }
    setisCancelingOrder(false)
  }, () => {  setisCancelingOrder(false) })

  return (
    <div className='p-4 border border-black rounded-xl grid grid-cols-3 gap-4  '>
      <div className='col-span-2 space-y-2 border border-l-0 border-b-0 border-t-0 '>
        <div className='flex items-center space-x-2'>  <span className='uppercase'># jnsajdkadkd213123123</span>     {orderDetails?.orderStatus ? <Badge className='uppercase' variant={BADGE_STATUS_VARIANT[orderDetails?.orderStatus as keyof object]}>{orderDetails?.orderStatus}</Badge> : null} </div>

        <div className='space-y-2'>
          <div className='text-sm text-gray-400'>Delivery Address</div>
          <div className='text-xs'>
            {orderDetails?.address1} {orderDetails?.address2}, {orderDetails?.city}, {orderDetails?.state}, {orderDetails?.pincode}
          </div>
        </div>
      </div>
      <div className='grid grid-cols-2'>
        <div className='grid grid-rows-2 gap-2'>
          <div className=''>
            <div className='text-sm text-gray-400'>Total Products</div>
            <div># {orderDetails?.quantity}</div>
          </div>
          <div className=''>
            <div className='text-sm text-gray-400'>Total Products</div>
            <div>₹ {orderDetails?.totalCost ? checkDecimal(orderDetails?.totalCost, true) : null}</div>
          </div>
        </div>
        <div className='space-y-2 '>
          <div className='flex justify-end space-x-2 text-sm  text-gray-400 w-full'><span className=''>Date</span> <span>{orderDetails?.createdAt ? dayjs(orderDetails?.createdAt).format("YYYY") : null}</span></div>
          <Button isLoading={isCancelingOrder} className='w-full' disabled={orderDetails?.orderStatus == ORDER_STATUS_ENUM.CANCELLED || isCancelingOrder } size={'sm'} variant={'outlineDestructive'} onClick={handleCancelOrder} >Cancel Order</Button>
          <Button className='w-full' onClick={() => navigate("/" + MAIN_ROUTES_CONST.order_details + "/" + orderDetails._id)} size={'sm'} variant={'outlinePrimary'}>View Details</Button>
        </div>
      </div>
    </div>
  )
}

export default OrderItems