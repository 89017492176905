import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { INIT_PROJECT_RIGHTS } from 'src/modules/ProjectRights/modules'
import ProjectRightDetailView from 'src/modules/ProjectRights/ProjectRightDetailView'
import { INIT_SELL_EXISTING_PROJECT } from 'src/modules/SellExistingProjects/modules'
import SellExistingProjectDetailView from 'src/modules/SellExistingProjects/SellExistingProjectDetailView'
import { MAIN_ROUTES_CONST } from 'src/navigations/modules'
import { getRooftopDetailByIDApiRequest } from 'src/services/requests/Rooftop'
import { getSellexistingProjectDetailByIDApiRequest } from 'src/services/requests/SellExistingProjects'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'

type Props = {}


const LAND_IMG_SAMPLE = "https://images.pexels.com/photos/6876537/pexels-photo-6876537.jpeg"

const SellExistingProjectsDetailsPage = (props: Props) => {

    const navigate = useNavigate()

    const { id } = useParams()

    const [orderDetails, setorderDetails] = useState<any>(null)
    const [isLoading, setisLoading] = useState<boolean>(false)


    useEffect(() => {
        if (id) {
            handleFetchByOrderID()
        }
        else navigate('/'+ MAIN_ROUTES_CONST.lands)
        return () => { }
    }, [id])


    const handleFetchByOrderID = catchAsync(async () => {
        setisLoading(true)
        return id && await getSellexistingProjectDetailByIDApiRequest(id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setorderDetails(result.data)
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

    return (
        <div className='bg-orange-50 '>
            <div className='h-[400px] bg-gray-400 w-full ' style={{ backgroundImage: 'url(' + LAND_IMG_SAMPLE + ')', backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }} >
            </div>
            <div className='max-w-[80vw] mx-auto p-4 -mt-[250px] bg-white rounded-xl p-4'>
                <div className='text-primary font-bold mx-4 my-4 text-xl'>Project Details</div>
                <SellExistingProjectDetailView data={orderDetails || INIT_SELL_EXISTING_PROJECT} />
                <div className='flex justify-center py-4'>
                    <RoundedButton className='' onClick={() => navigate(-1)} variant={'outline'}>Back</RoundedButton>
                </div>
            </div>
            <LoadingDialog isOpen={isLoading} message='Fetching Land Details...'  /> 
        </div>

    )
}

export default SellExistingProjectsDetailsPage