import React, { useEffect, useState } from 'react'
import { catchAsync, IMG_EPC_COMPANY_LOGO, IMG_GREEN_ENERGY_BANNER } from 'src/helpers'
import EpsServices from './EpsServices'
import EmpDetailsContactForm from './EmpDetailsContactForm'
import { Separator } from 'src/shadcn/components/ui'
import { useNavigate, useParams } from 'react-router-dom'
import { INIT_EPC_CONTRACTOR_PROFILE } from './modules'
import { getEpcContractorDetailsApiRequest } from 'src/services/requests/EpcContractors'
import dayjs from 'dayjs'

type Props = {
    // epcLogo: string,
    // name: string,
    // description: string,
    // partnerType: string,
    // projectCount: string,
    // installedCapacity: string,
    // noOfYearsExperiance: string,
    // services: {
    //     imageUrl: string,
    //     serviceType: string,
    //     serviceDescription: String
    // }[]
}

const EmpContractorDetails = (props: Props) => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [profileDetail, setprofileDetail] = useState<any>(INIT_EPC_CONTRACTOR_PROFILE)

    useEffect(() => {
        if (id) {
            handleFetchProfileDetails()
        }
        return () => { }
    }, [id])

    const handleFetchProfileDetails = catchAsync(async () => {
        return id && await getEpcContractorDetailsApiRequest(id)
    }, (result: any) => {
        if (result.status == 200) {
            setprofileDetail(result?.data)
        }
    }, () => { })

    return (
        <div>
            <div className='w-full ' >
                <div className=' w-full h-[300px] overflow-hidden ' style={{ backgroundImage: "url(" + IMG_GREEN_ENERGY_BANNER + ")", backgroundSize: "cover" }} >
                    <div className='grid grid-cols-4 gap-1 max-w-[85vw] h-full   mx-auto'>
                        <div className='h-100 flex justify-center items-center bg-gray-50'>
                            <img src={profileDetail?.companyLogo || ""} className='bg-gray-50' style={{ width: "100%" }} />
                        </div>
                        <div className='col-span-3 text-white flex justify-center pt-[48px]  '>
                            <div className='w-[80%] mx-auto space-y-4'>
                                <div className='flex justify-center'>
                                    <div>
                                        <div className='text-xl uppercase font-bold'>About {profileDetail?.contractorName || 0}</div>
                                        <div className='h-0.5 bg-primary w-[100px] mx-auto '></div>
                                    </div>
                                </div>
                                <div className='text-sm text-center'>
                                  {profileDetail?.projectDescription}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='max-w-[85vw] mx-auto grid grid-cols-4 gap-1'>
                    <div className='h-[70px] bg-yellow-500 flex justify-center items-center rounded-bl-3xl'>
                        <span className='font-bold text-lg uppercase text-white'>{profileDetail?.authorization_partner}</span>
                    </div>
                    <div className='h-[70px] bg-orange-50 flex justify-center items-center '>
                        <div className=''>
                            <div className='font-bold text-center' >{profileDetail?.services?.length || 0}+</div>
                            <div className='text-center text-xs' >Projects</div>
                        </div>
                    </div>
                    <div className='h-[70px] bg-orange-50 flex justify-center items-center '>
                        <div className=''>
                            <div className='font-bold text-center' >{profileDetail?.capacityInstalled} kW</div>
                            <div className='text-center text-xs' >Capacity Installed</div>
                        </div>
                    </div>
                    <div className='h-[70px] bg-orange-50 flex justify-center items-center '>
                        <div className=''>
                            <div className='font-bold text-center' >{profileDetail?.noOfYearsExperiance ? dayjs().subtract(profileDetail?.noOfYearsExperiance as any, "year").format("YYYY") as any : 0}</div>
                            <div className='text-center text-xs' >Years Experiance</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-[85vw] mx-auto grid grid-cols-4 gap-4 py-4'>
                <div className='col-span-3 space-y-8 py-4 '>
                    <div>
                        <div className='text-sm font-bold uppercase'>Services</div>
                        <div className='h-0.5 bg-primary w-[100px]'></div>
                    </div>
                    <div className=''>
                        <EpsServices services={profileDetail?.services || []} />
                    </div>
                </div>
                <div className='pace-y-8 p-4 border border-r-0 border-t-0 border-b-0'>
                    <div>
                        <div className='text-sm font-bold uppercase'>GET CONTACT DETAILS</div>
                        <div className='h-0.5 bg-primary w-[100px]'></div>
                    </div>
                    <EmpDetailsContactForm epc_id={profileDetail?.epc_contractor_id} />
                </div>
            </div>
        </div>
    )
}

export default EmpContractorDetails