import { FormField, NAME_REGEX, NUMBER_REGEX, QUERY_TYPE_OPTIONS } from "src/helpers";
import * as Yup from "yup"


export interface IEpsDetailContactForm {
    name: string,
    phoneNumber: string,
    email: string,
    queryType: string,
    description: string,
}

export const MANUFACTURER_CONTACT_DETAILS_FORM_FIELDS: FormField[] = [
    {
        label: 'Name',
        dataKey: 'name',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Enter your name',
            regex:NAME_REGEX
        },
    },
    {
        label: 'Phone Number',
        dataKey: 'phoneNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex:NUMBER_REGEX,
            placeholder: 'Enter your phone number',
            maxLength:10
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: 'email',
            placeholder: 'Enter your email',
        },
    },
   
    {
        label: 'Query Type',
        dataKey: 'queryType',
        componentType: 'options',
        componentProps: {
            options:QUERY_TYPE_OPTIONS
        },
    },
    {
        label: 'Description',
        dataKey: 'description',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Enter your query description',
        },
    },
];


export const MANUFACTURER_CONTACT_DETAILS_FORM_INIT: IEpsDetailContactForm = {
    name: '',
    phoneNumber: '',
    email: '',
    queryType: '',
    description: '',
  };


  export const INIT_MANUFACTURER_PROFILE = {
    manufacturer_name: "",
    phoneNumber: "",
    manufacturerEmail: "",
    location: "",
    address: "",
    city:"",
    pincode: "",
    authorization_partner:"",
    specialization:"",
    is_otc_verified:"",
    noOfYearsExperiance: "",
    review: "",
    product_efficiency:"",
    companyAbouteUs: "",
    companyLogo: "",
    products: [],
}
  



export const MANUFACTURER_CONTACT_DETAILS_FORM_Schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phoneNumber: Yup.string().length(10,"Phone number must be 10 digits").required('Phone number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    queryType: Yup.string().required('Query type is required'),
    description: Yup.string().required('Description is required'),
    
});