import React from 'react'
import { checkDecimal, IMG_EPC_COMPANY_LOGO } from 'src/helpers'

import { IProduct } from './modules'
import { ImageOff } from 'lucide-react'
import { useDispatch } from 'react-redux'
import { updateQuantityOfItem } from 'src/redux/reducers/orderReducer'
import QuantityCounter from 'src/shadcn/components/custom/QuantityCounter'

type Props = {}

const ProductItem = (data: IProduct) => {

    const dispatch = useDispatch()

    return (
        <div className='flex space-x-4 my-1 p-2 bg-white border border-slate-400 rounded-lg'>
            {data.productImage ? <img src={data.productImage} className='border rounded-xl ' style={{ width: "50px", height: "50px" }} /> :
                <div className='bg-gray-200 w-[50px] h-[50px] rounded-xl flex justify-center items-center'>
                    <ImageOff className='text-gray-400' size={18} />
                </div>}
            <div className='flex-1'>
                <div className='flex justify-between items-center'>
                    <div>
                        <div className='font-semibold text-sm'>{data.name}</div>
                        <div className='flex justify-between items-center'>
                            <div className='flex space-x-2 text-xs'>
                                <span>₹ {checkDecimal(data.price as any, true)}</span><span>X</span><span>{data.quantity}</span><span>=</span><span>₹ {checkDecimal((Number(data.price) * Number(data.quantity)).toFixed(2), true)}</span>
                            </div>
                        </div>
                    </div>
                    <QuantityCounter 
                    isLoading={false}
                     value={data.quantity} onChange={(e: any) => {
                        dispatch(updateQuantityOfItem({ product_id: data._id, quantity: e }))
                    }} />
                </div>
            </div>
        </div>
    )
}

export default ProductItem