import React from 'react'

import { IMG_SAMPLE_PANEL_1, IMG_SAMPLE_PANEL_2, IMG_SOLAR_FARMS, IMG_SOLAR_ROOF_TOP, IMG_SOLAR_WATER_PUMP } from 'src/helpers';
import ToolTipComponent from 'src/shadcn/components/custom/ToolTip';
import { Card, CardContent, Carousel, CarouselContent, CarouselItem } from 'src/shadcn/components/ui';

type Props = {
    data: any
}

const data = [
    {
        image: IMG_SAMPLE_PANEL_1,
        title: "Solar Farm",

    },
    {
        image: IMG_SAMPLE_PANEL_2,
        title: "Solar Rooftops",

    },
    {
        image: IMG_SAMPLE_PANEL_2,
        title: "Solar Water Pumps",

    }
]

const ManufacturerServicesNew = ({
    data
}: Props) => {

    function truncateText(text: any) {
        if (text.length > 250) {
            return text.substring(0, 250) + '...';
        }
        return text;
    }


    return (
        <div>
            {/* <Carousel
                opts={{ align: "start", }}
                className="w-full"
            >
                <CarouselContent>
                    {data.map(({ image, title }, index) => {
                        return (
                            <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 p-1">
                                <div className="p-0">
                                    <Card>
                                        <CardContent className="flex aspect-square items-center justify-center p-0">
                                            <div className='grid h-[300px] grid-rows-6 gap-0 auto-rows-max w-full'>
                                                <div className='row-span-5'>
                                                    <img src={image} style={{width:"100%",height:"100%"}} />
                                                </div>
                                                <div className='bg-gray-200 text-sm font-semibold p-4 uppercase'>{title}</div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </CarouselItem>
                        )
                    })}
                </CarouselContent>
            </Carousel> */}
            <div className='grid grid-cols-4 gap-4 '>
                {
                    data && Array.isArray(data) && data?.length > 0 ? data.map((d: any) => {
                        return <div key={`${d?._id}}`} className='h-[400px] grid grid-rows-8 gap-1 rounded-lg border border-black overflow-hidden p-4'>
                            <div className='row-span-4'>
                                <img src={d?.productImage} className='rounded-xl' style={{ width: "100%", height: "100%" }} />
                            </div>
                            <div className='flex justify-center items-center h-100 w-100  text-center text-sm text-primary  font-semibold'>
                                {d?.productName}
                            </div>
                            <div className='row-span-2 flex justify-center items-start h-100 w-100    text-xs text-center '>
                                {d?.productDescription}
                            </div>
                            <div className=' flex items-center justify-between'>
                                <div className='flex flex-col  space-y-2 '>
                                    <span className='text-sm text-center text-gray-400'>Quantity</span>
                                    <span className='text-sm text-center text-primary font-semibold'># 38</span>
                                </div>
                                <div className='flex flex-col  space-y-2'>
                                    <span className='text-sm text-center text-gray-400'>Price </span>
                                    <span className='text-sm text-center text-primary font-semibold'>₹ 102/piece</span>
                                </div>
                            </div>
                        </div>
                    }) : null
                }

            </div>
        </div>
    )
}

export default ManufacturerServicesNew