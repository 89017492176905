import { useFormik } from "formik";
import React, { useState } from "react";
import {
  SERVICES_ENQUIRY_FORM_FIELDS,
  SERVICES_ENQUIRY_INIT,
  Services_EnquiryFormSchema,
} from "./Component/modules";
import RenderFormComponents from "src/shadcn/components/custom/RenderFormComponents";
import RoundedButton from "src/shadcn/components/custom/RoundedButton";
import { ChevronLeft } from "lucide-react";
import { catchAsync, checkAnyFileToBeUpload, IS_SUCCESS_RESPONSE } from "src/helpers";
import { toast } from "sonner";
import { createServiceEnquiryFormRequestRequestApi } from "src/services/requests/ServiceEnquiryRequests";

const SolarServicesEnquiryNow = () => {

  const [isLoading, setisLoading] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: SERVICES_ENQUIRY_INIT,
    validationSchema: Services_EnquiryFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      // Handle form submission here
      // handleRegistrationForm()
      handleCheckIfAnyFileToBeUpload()
    },
  });

  const handleCheckIfAnyFileToBeUpload = catchAsync(async () => {
    return await checkAnyFileToBeUpload(formik.values)
  }, (result: any) => {
    console.log({ result });
    if (result[1]) {
      handleCreateRequest({ ...result[0] })
      console.log({ result: { ...result[0] } });
    }
  }, () => { })

  const handleCreateRequest = catchAsync(async (data: any) => {
    setisLoading(true)
    return createServiceEnquiryFormRequestRequestApi(data)
  }, (result: any) => {
    if (IS_SUCCESS_RESPONSE(result)) {
      setisLoading(false)
      toast.success("Request Send Successful.")
      formik.resetForm()
    }
  }, () => { setisLoading(false) })

  console.log({ value: formik.values, errors: formik.errors });



  return (
    <div className="w-full bg-primary h-full font-sans p-[48px] pb-[150px]">

      <div className="font-normal text-center text-3xl px-10">
        Get Started on Your Solar Savings Journey
      </div>


      <div className=" text-center text-3xl font-bold px-10">
        Enquiry Now !
      </div>

      <div className="max-w-[50vw] mx-auto p-10">
        <form className="space-y-2" onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-2 align-middle">
            {SERVICES_ENQUIRY_FORM_FIELDS.map((field: any) => {
              return (
                <RenderFormComponents
                  key={`login-form-${field.dataKey}`}
                  formik={formik}
                  value={formik.values[field.dataKey as keyof object]}
                  {...field}
                  onChange={(e: any) =>
                    formik.setFieldValue(field.dataKey, e)
                  }
                />
              );
            })}

          </div>
          <div className="w-full flex justify-center items-center">
            <RoundedButton
              className="w-[177px] h-[45px] bg-black text-white hover:bg-white hover:text-black rounded-full mt-4"
            >
              Enquiry Now {">>"}
            </RoundedButton>
          </div>
        </form>
      </div>

    </div>
  );
};

export default SolarServicesEnquiryNow;
