
import { convertArrayToOptionType, FormField, NAME_REGEX, PHONE_NUMBER_REGEX } from 'src/helpers';
import * as yup from 'yup';
import { FaCreditCard } from "react-icons/fa";

export type OrderContextState = {
    orderItems: any[],
    orderDetails: any,
}

export const INIT_STATE = {
    orderItems: [],
    orderDetails: {},
}

export type OrderContextProps = {
    state: OrderContextState,
    addOrderItem: (e: any) => void,
    setOrderItems: (e: any) => void,
    setOrderDetails: (e: any) => void
    // resetOrderDetails: () => void
    // resetOrderItems: () => void
    // resetOrderState: () => void
}

export const initialProps: OrderContextProps = {
    state: INIT_STATE,
    setOrderItems: () => { },
    addOrderItem: () => { },
    setOrderDetails: () => { },
    // resetOrderDetails: () => {},
    // resetOrderItems: () => {},
    // resetOrderState: () => {},
}

export const ACTION_TYPES = {
    INIT: "INIT",
    ADD_ORDER_ITEM: "ADD_ORDER_ITEM",
    SET_ORDER_ITEMS: "SET_ORDER_ITEMS",
    SET_ORDER_DETAILS: "SET_ORDER_DETAILS",
    // RESET_ORDER_ITEMS: "RESET_ORDER_ITEMS",
    // RESET_ORDER_DETAILS: "RESET_ORDER_DETAILS",
    // RESET_ORDER_STATE: "RESET_ORDER_STATE",

}

export const Reducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case ACTION_TYPES.INIT:
            return INIT_STATE
            break;
        case ACTION_TYPES.ADD_ORDER_ITEM:
            return { ...state, orderItems: [...state.orderItems, action.payload] }
            break;
        case ACTION_TYPES.SET_ORDER_ITEMS:
            return { ...state, orderItems: action.payload }
            break;
        case ACTION_TYPES.SET_ORDER_DETAILS:
            return { ...state, orderDetails: action.payload }
            break;
        default:
            break;
    }
}


export interface IOrderDetails {
    contactPersoneName: string;
    contactPersoneNumber: string;
    orderDate: Date;
    totalCost: number;
    paymentMode: number;
    paymentStatus: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    pincode: string;
}


export interface IPaymentDetails {
    cardNumber: string;
    cvv: string;
    expiry: Date;
    cardName: string
}



export const orderDetailsFormSchema = yup.object().shape({
    contactPersoneName: yup.string().required("Contact person Name is Required"),
    contactPersoneNumber: yup.string().required("Contact number is required"),
    state: yup.string().required("State required"),
    city: yup.string().required("City required"),
    pincode: yup.string().length(6, "Must be 6 digits only").required("pincode required"),
    address1: yup.string().required("Address required"),
    address2: yup.string(),
    paymentMode: yup.string().required("Select Payment Mode"),
});

const PAYMENT_MODES = ["DEBIT/CREDIT CARD"]
const PAYMENT_MODES_OPTIONS = convertArrayToOptionType(PAYMENT_MODES)


export const SHIPPING_ORDER_FORM_FIELDS: FormField[] = [
    {
        label: 'Contact Person Name',
        dataKey: 'contactPersoneName',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Contact Person Name',
            regex: NAME_REGEX,
            required: true,
        },
    },
    {
        label: 'Contact Number',
        dataKey: 'contactPersoneNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: PHONE_NUMBER_REGEX,
            maxLength: 10,
            placeholder: 'Contact Person Number',
            required: true,
        },
    },
    {
        label: 'Payment Mode',
        dataKey: 'paymentMode',
        componentType: 'options',
        componentProps: {
            options: PAYMENT_MODES_OPTIONS,
            required: true,
        },
    },
    {
        label: 'Address 1',
        dataKey: 'address1',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Area / Room No / Landmark',
            required: true,
            maxLength:250,
            showCharacterLength:true,
        },
    },
    {
        label: 'Address 2',
        dataKey: 'address2',
        componentType: 'input',
        componentProps: {
            type: 'text',
            maxLength:250,
            showCharacterLength:true,
            placeholder: 'Area / Room No / Landmark',
        },
    },
    {
        label: 'City',
        dataKey: 'city',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: NAME_REGEX,
            placeholder: 'City',
            required: true,
        },
    },
    {
        label: 'State',
        dataKey: 'state',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: NAME_REGEX,
            placeholder: 'State',
            required: true,
        },
    },
    {
        label: 'Pincode',
        dataKey: 'pincode',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: PHONE_NUMBER_REGEX,
            placeholder: 'Pincode',
            maxLength: 6,
            required: true,
        },
    },
];

export const PAYMENT_DETAILS_FORM_FIELDS: FormField[] = [
    {
        label: 'Card Number',
        dataKey: 'cardNumber',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'Card Number',
            required: true,
            numberFormatted: true,
            numberPattern: "#### #### #### #### ###",

        },
    },
    {
        label: 'CVV',
        dataKey: 'cvv',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'CVV',
            required: true,
            numberFormatted: true,
            numberPattern: "###",
            inputPrefixIcon: <FaCreditCard size={20} />
        },
    },
    {
        label: 'Expiry Date',
        dataKey: 'expiry',
        componentType: 'input',
        componentProps: {
            type: 'text',
            placeholder: 'MM/YY',
            required: true,
            numberFormatted: true,
            numberPattern: "##/##",
            inputPrefixIcon: <FaCreditCard size={20} />
        },
    },
    {
        label: 'Card Name',
        dataKey: 'cardName',
        componentType: 'input',
        componentProps: {
            type: 'text',
            regex: NAME_REGEX,
            placeholder: 'Card Name',
            required: true,
        },
    },
];


export const INITIAL_PAYMENT_DETAILS_VALUES = {
    cardNumber: '',
    cvv: '',
    expiry: "",
    cardName: '',
};


export const paymentDetailsSchema = yup.object().shape({
    cardNumber: yup
        .string()
        .required('Card number is required')
        .min(12, "At least 12 digits only"),
    cvv: yup
        .string()
        .length(3, "Must be 3 digits only")
        .required('CVV is required'),
    expiry: yup.string().required('Expiry date is required'),
    cardName: yup.string().required('Card name is required'),
});

export const INITIAL_SHIPPING_ORDER_FORM_VALUES = {
    contactPersoneName: '',
    contactPersoneNumber: '',
    state: '',
    city: '',
    pincode: '',
    address1: '',
    address2: '',
    paymentMode: "",
};

export interface IProduct {
    _id: string;
    productImage:string,
    name: string;
    price: number;
    quantity: number;
}