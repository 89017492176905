import React from 'react'
import { IMG_ROUNDED_1, IMG_ROUNDED_2, IMG_ROUNDED_3 } from 'src/helpers'

type Props = {}

const DATA = [
    {
        image: IMG_ROUNDED_1,
        heading: "Hassle-Free Process",
        description: "We manage all aspects of the project, including permits, installation, and maintenance, so you can enjoy a hands-off experience."
    },
    {
        image: IMG_ROUNDED_2,
        heading: "Experienced Team",
        description: "With years of expertise in solar development, we guarantee a seamless and professional land lease process."
    },
    {
        image: IMG_ROUNDED_3,
        heading: "Steady, Long-Term Income",
        description: "Our long-term lease agreements ensure you receive consistent, predictable payments over the life of the solar project."
    }
]

const LandLeaseWhyChooseUs = (props: Props) => {
    return (
        <div className='space-y-4'>
            <div className='text-xl font-bold text-center'>Why Choose Us</div>
            <div className='text-center text-sm'>
                we make the process of leasing your land for solar projects simple and profitable.
            </div>
            <div className='flex space-x-4'>
                {
                    DATA.map(({ image, heading, description }) => {
                        return <div className='w-1/3 grid grid-cols-4 gap-2 border rounded-xl p-4'>
                            <div>
                                <img src={image} style={{ width: "80px", height: "75px" }} />
                            </div>
                            <div className='col-span-3 space-y-2'>
                                <div className='font-bold'>{heading}</div>
                                <div className='text-xs'>{description}</div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default LandLeaseWhyChooseUs